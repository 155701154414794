import humanizeDuration from 'humanize-duration'
import i18n, { t } from 'i18next'
import { DeviceStatusRead } from '../store/clientApi'

export const deviceMessage = (device?: DeviceStatusRead) => {
  const paused_by_user = device?.status?.paused_by_user ?? true
  const state = (device?.status?.state ?? '').toLowerCase()
  const remaining = device?.status?.remaining ?? 0
  const connected = device?.status?.connected ?? false

  if (!connected) {
    return t('devices:state.offline')
  }

  if (['cancelling', 'canceled', 'pausing', 'paused'].includes(state)) {
    /*
      t('devices:state.cancelling')
      t('devices:state.canceled')
      t('devices:state.pausing')
      t('devices:state.paused')
      t('devices:state.cancelling_error')
      t('devices:state.canceled_error')
      t('devices:state.pausing_error')
      t('devices:state.paused_error')
      */
    return t('devices:state.' + state, {
      context: !paused_by_user && 'error',
    })
  }

  if (state === 'printing') {
    return t('devices:state.printing', {
      duration: humanizeDuration(remaining * 1000, {
        units: ['h', 'm'],
        round: true,
        language: i18n.language,
        fallbacks: [...i18n.languages],
      }),
    })
  }

  /*
    t('devices:state.idle')
    t('devices:state.staring')
    t('devices:state.finished')
    t('devices:state.userbusy')
    */
  return t(['devices:state.' + state, 'common:loading.unavailable'])
}
