import { Autocomplete, FormControl, TextField } from '@mui/material'
import locale, { ILocale } from 'locale-codes'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const getFullName = (lang: ILocale) => {
  if (lang.location) return `${lang.name} (${lang.location})`
  else return `${lang.name} (${lang.tag})`
}

const LanguageSelector = ({ size }: { size?: 'small' | 'medium' }) => {
  const { t, i18n } = useTranslation('common')
  const [lang, setLang] = useState(locale.where('tag', i18n.language))
  const [open, setOpen] = useState(false)

  return (
    <>
      <FormControl
        variant="standard"
        fullWidth
        sx={{ minWidth: 120 }}
        onClick={(e) => e.stopPropagation()}
      >
        <Autocomplete
          id="locale-select"
          sx={{ width: 300 }}
          value={lang}
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          size={size}
          isOptionEqualToValue={(option, value) => option.tag === value.tag}
          getOptionLabel={(option) => getFullName(option)}
          getOptionKey={(option) => option.tag}
          options={locale.all}
          onChange={async (e, newValue) => {
            if (newValue) {
              setLang(newValue)
              await i18n.changeLanguage(newValue.tag.toLowerCase())
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('label.locale')}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </FormControl>
    </>
  )
}

export { LanguageSelector }
