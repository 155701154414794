import {
  createAction,
  createSlice,
  Dispatch,
  Middleware,
  PayloadAction,
  UnknownAction,
} from '@reduxjs/toolkit'
import Ajv from 'ajv'
import { clientApi } from '../clientApi'
import { RootState } from '../store'
const ajv = new Ajv()

const MAX_RECONNECTION_BACKOFF = 30000
const BASE_RECONNECTION_DELAY = 250

type ValidCacheTags = 'parts' | 'devices' | 'jobs'
const CACHE_TAGS_TO_CHECK: ValidCacheTags[] = ['parts', 'devices', 'jobs']

type IncomingMessage = {
  action: string
  data: {
    type: string
    tags: { type: string; id: string }[]
  }
}

type SubscribeMessage = {
  action: 'subscribe'
  data: {
    type: string
    tags: string[]
    accessToken?: string
  }
}

type UnsubscribeMessage = {
  action: 'unsubscribe'
  data: {
    type: string
    tags: string[]
  }
}

const responseMessageSchema = {
  type: 'object',
  properties: {
    action: {
      type: 'string',
    },
    data: {
      type: 'object',
      properties: {
        type: {
          type: 'string',
        },
        tags: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                type: {
                  type: 'string',
                },
                id: {
                  type: 'string',
                },
              },
              required: ['type', 'id'],
            },
          ],
        },
      },
      required: ['type', 'tags'],
    },
  },
  required: ['action', 'data'],
}
const validateIncomingMessage = ajv.compile(responseMessageSchema)

class SubscriptionHandler {
  _websocket?: WebSocket
  _setupPayload?: SetupPayload
  _subscriptions: {
    [id: string]: ValidCacheTags[]
  } = {
    cache: [],
  }
  _eventHandlers: {
    [id: string]: {
      [id: string]: (
        message: IncomingMessage,
        dispatch: Dispatch<UnknownAction>,
      ) => void
    }
  } = {
    event: {
      cache: this._handleCacheMessage,
    },
  }
  _connectionAttempt = 0

  setup(setup: SetupPayload) {
    this._setupPayload = setup
  }

  ready(): boolean {
    return (
      this._websocket !== undefined &&
      this._websocket.readyState === WebSocket.OPEN
    )
  }

  connect(dispatch: Dispatch<UnknownAction>) {
    if (this._websocket !== undefined) return
    if (this._setupPayload === undefined) return

    try {
      dispatch(
        wsEventSlice.actions.changeState({ state: WebsocketState.CONNECTING }),
      )
      this._websocket = new WebSocket(this._setupPayload.ws, [
        this._setupPayload.subprotocol,
        this._setupPayload.accessToken,
      ])
    } catch (e) {
      console.error(e)
      this._websocket = undefined
      return
    }

    this._websocket.addEventListener('message', (ev) => {
      try {
        const data = JSON.parse(ev.data)
        if (!validateIncomingMessage(data)) return

        const message = data as IncomingMessage
        const handler =
          this._eventHandlers?.[message.action]?.[message.data.type]

        if (handler !== undefined) {
          handler(message, dispatch)
        }
      } catch (e) {
        console.error('Websocket message parsing error', e)
      }
      return
    })

    this._websocket.addEventListener('close', async (ev) => {
      let delay = BASE_RECONNECTION_DELAY * 2 ** this._connectionAttempt
      if (delay > MAX_RECONNECTION_BACKOFF) delay = MAX_RECONNECTION_BACKOFF
      this._connectionAttempt++

      dispatch(
        wsEventSlice.actions.changeState({
          state: WebsocketState.CLOSED,
          reconnectDelay: delay,
        }),
      )
      await new Promise((resolve) => setTimeout(resolve, delay))

      if (this._websocket === undefined) return
      this._websocket = undefined
      this.connect(dispatch)
    })

    this._websocket.addEventListener('error', (ev) => {
      this._websocket?.close()
    })

    this._websocket.addEventListener('open', () => {
      this._connectionAttempt = 0
      this.resendSubscriptions()
      dispatch(
        wsEventSlice.actions.changeState({
          state: WebsocketState.OPEN,
          reconnectDelay: BASE_RECONNECTION_DELAY,
        }),
      )
    })
  }

  resendSubscriptions() {
    if (!this.ready()) return
    for (const type of Object.keys(this._subscriptions)) {
      this.sendSubscribe({
        action: 'subscribe',
        data: {
          type: type,
          tags: this._subscriptions[type],
        },
      })
    }
  }

  sendSubscribe(message: SubscribeMessage) {
    if (!this.ready()) return
    if (message.data.accessToken === undefined)
      message.data.accessToken = this._setupPayload?.accessToken
    this._websocket?.send(JSON.stringify(message))
  }

  sendUnsubscribe(message: UnsubscribeMessage) {
    if (!this.ready()) return
    this._websocket?.send(JSON.stringify(message))
  }

  _handleCacheMessage(
    message: IncomingMessage,
    dispatch: Dispatch<UnknownAction>,
  ) {
    dispatch(clientApi.util.invalidateTags(message.data.tags as any))
  }

  updateSubscriptions(type: string, tags: ValidCacheTags[]) {
    if (!this.ready()) {
      this._subscriptions[type] = tags
      return
    }

    if (this._subscriptions[type] === undefined) this._subscriptions[type] = []

    const updates = []
    const removals = []
    for (const tag of tags) {
      if (!this._subscriptions[type].includes(tag)) {
        this._subscriptions[type].push(tag)
        updates.push(tag)
      }
    }

    for (const tag of this._subscriptions[type]) {
      if (!tags.includes(tag)) {
        removals.push(tag)
      }
    }

    this._subscriptions[type] = tags

    if (updates.length > 0) {
      this.sendSubscribe({
        action: 'subscribe',
        data: {
          type: type,
          tags: updates,
        },
      })
    }

    if (removals.length > 0) {
      this.sendUnsubscribe({
        action: 'unsubscribe',
        data: {
          type: type,
          tags: removals,
        },
      })
    }
  }
}

export type SetupPayload = {
  subprotocol: string
  accessToken: string
  ws: string
}
export const SetupAction = createAction<SetupPayload>('wsEvents/setup')

export const WebsocketEventMiddleware = () => {
  const subscriptions = new SubscriptionHandler()

  const handler: Middleware<
    {}, // eslint-disable-line
    RootState
  > = (params) => (next) => (action) => {
    const { dispatch, getState } = params

    const result = next(action)
    const state = getState()

    const subscribedTags: ValidCacheTags[] = []

    for (const type of CACHE_TAGS_TO_CHECK) {
      if (
        Object.values(state.api.provided?.[type] ?? []).some(
          (tag) => tag.length > 0,
        )
      ) {
        subscribedTags.push(type)
      }
    }

    subscriptions.updateSubscriptions('cache', subscribedTags)

    if (
      typeof action === 'object' &&
      action != null &&
      'type' in action &&
      'payload' in action &&
      action?.type != null
    ) {
      switch (action?.type) {
        case SetupAction.type: {
          subscriptions.setup(action.payload as SetupPayload)
          subscriptions.connect(dispatch)
          break
        }
      }
    }
    return result
  }

  return handler
}

export enum WebsocketState {
  INIT = -1,
  CONNECTING = WebSocket.CONNECTING,
  OPEN = WebSocket.OPEN,
  CLOSED = WebSocket.CLOSED,
}

export type WSEventState = {
  state: WebsocketState
  metadata: {
    changedAt: number
    reconnectDelay: number
  }
}

export const initialState: WSEventState = {
  state: WebsocketState.INIT,
  metadata: {
    changedAt: 0,
    reconnectDelay: BASE_RECONNECTION_DELAY,
  },
} satisfies WSEventState

export const wsEventSlice = createSlice({
  name: 'wsEvents',
  initialState,
  reducers: {
    changeState(
      state,
      action: PayloadAction<{ state: WebsocketState; reconnectDelay?: number }>,
    ) {
      state.state = action.payload.state
      state.metadata.changedAt = Date.now()
      if (action.payload.reconnectDelay)
        state.metadata.reconnectDelay = action.payload.reconnectDelay
    },
  },
  selectors: {
    selectState: (state) => state.state,
    selectMetadata: (state) => state.metadata,
  },
})

export const { selectState, selectMetadata } = wsEventSlice.selectors
export const reducer = wsEventSlice.reducer
