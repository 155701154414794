import { MenuItem } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { deviceStateToColor } from '../../helpers'
import { clientApi } from '../../store/clientApi'
import { setDrawerOpen } from '../../store/slices/rightDrawerSlice'
import {
  selectRtkData,
  selectTableChecked,
  setChecked,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { deviceMessage } from '../../utils/getDeviceMessage'
import { ProgressCard } from '../Cards/Cards'
import {
  DEVICE_DETAILS_STATE,
  DEVICES_TABLE_STATE_NAME,
} from '../DevicesList/DevicesList'
import { NotConnectedSvg } from '../Icon/Icon'

export const DeviceCard = ({
  thingName,
  setConfirmRemove,
}: {
  thingName: string
  setConfirmRemove: Dispatch<SetStateAction<string | undefined>>
}) => {
  const { enableRemoveDeviceButton } = useFlags()
  const { t } = useTranslation('devices')
  const UNAVAILABLE = t('common:loading.unavailable')

  const checked = useSelector((state: RootState) =>
    selectTableChecked(state, DEVICES_TABLE_STATE_NAME),
  )
  const selectedDevice = Object.keys(checked).find(
    (thingName) => checked[thingName],
  )
    ? {
        thingName: Object.keys(checked).find((thingName) => checked[thingName]),
      }
    : undefined

  const handleDeviceSelect = (thingName: string, value: boolean) => {
    const currentSelection = selectedDevice?.thingName
    if (currentSelection !== undefined) {
      // deselect current
      store.dispatch(
        setChecked({
          name: DEVICES_TABLE_STATE_NAME,
          checked: currentSelection,
          value: false,
        }),
      )
    }
    // set selected thing
    store.dispatch(
      setChecked({
        name: DEVICES_TABLE_STATE_NAME,
        checked: thingName,
        value,
      }),
    )
  }

  const currDevice = useSelector((state: RootState) =>
    selectRtkData(
      state,
      DEVICES_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )?.data?.content?.find((device) => device.thing_name === thingName),
  )

  const name = currDevice?.status?.name ?? UNAVAILABLE
  const model = currDevice?.status?.model ?? UNAVAILABLE

  const elapsed = currDevice?.status?.elapsed ?? 0
  const remaining = currDevice?.status?.remaining ?? 0
  const printTime = elapsed + remaining
  const progress =
    printTime === 0 || currDevice?.status?.connected === false
      ? 0
      : (elapsed / printTime) * 100

  return (
    <ProgressCard
      title={name}
      subtitle={model}
      value={progress}
      color={deviceStateToColor(currDevice)}
      icon={
        !currDevice?.status?.connected ? (
          <NotConnectedSvg color="inherit" width="16px" height="16px" />
        ) : undefined
      }
      message={deviceMessage(currDevice)}
      selected={
        selectedDevice && selectedDevice.thingName === currDevice?.thing_name
      }
      onClick={() => {
        if (currDevice?.thing_name) {
          store.dispatch(
            setDrawerOpen({ name: DEVICE_DETAILS_STATE, value: true }),
          )
          handleDeviceSelect(currDevice.thing_name, true)
        }
      }}
    >
      <MenuItem
        disabled={!enableRemoveDeviceButton}
        onClick={() => setConfirmRemove(currDevice?.thing_name)}
      >
        {t('common:button.remove')}
      </MenuItem>
    </ProgressCard>
  )
}
