import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

interface ProgressBarProps extends LinearProgressProps {
  rounded?: boolean
  height?: number
}

const ProgressBar = (props: ProgressBarProps) => {
  const Bar = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    height: props.height ?? 10,
    ...((props.rounded ?? true) && { borderRadius: 5 }),
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300],
    },
    [`& .${linearProgressClasses.bar}`]: {
      ...((props.rounded ?? true) && {
        borderRadius: 5,
      }),
      backgroundColor:
        props.color !== 'inherit'
          ? theme.palette[props.color ?? 'primary'].main
          : 'inherit',
      boxShadow: theme.progress.shadows.fill,
    },
    sx: {
      boxShadow: theme.progress.shadows.empty,
    },
  }))

  return <Bar {...{ ...props, color: undefined, rounded: undefined }} />
}

export { ProgressBar }
