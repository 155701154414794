import { Box, LinearProgress } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { clientApi } from '../../store/clientApi'
import {
  selectIsChecked,
  selectQuerySearchValue,
  selectRtkData,
  setSearchValue,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { PartActionBar } from '../PartActionBar/PartActionBar'
import { PartButtonGroupType } from '../PartActionBar/buttons'
import { PART_TABLE_STATE_NAMES, PartsTable } from '../PartsTable/PartsTable'
import { SearchField } from '../SearchField/SearchField'

interface InboxBodyProps {
  table: PART_TABLE_STATE_NAMES
  actionBarType: PartButtonGroupType
}

const InboxSearch = ({ table }: { table: string }) => {
  const { t } = useTranslation('inbox')
  const searchValue = useSelector((state: RootState) =>
    selectQuerySearchValue(state, table),
  )
  const handleOnSeachChange = (searchInput: string) => {
    store.dispatch(setSearchValue({ name: table, value: searchInput }))
  }
  return (
    <SearchField
      placeholder={t('label.search')}
      value={searchValue}
      onChange={(searchInput) => handleOnSeachChange(searchInput)}
    />
  )
}

const InboxBodyHeader = ({
  table,
  actionBarType,
}: {
  table: PART_TABLE_STATE_NAMES
  actionBarType: PartButtonGroupType
}) => {
  const isSelected = useSelector((state: RootState) =>
    selectIsChecked(state, table),
  )
  return (
    <Box component="div">
      {isSelected ? (
        <PartActionBar
          table={table}
          isInspectorPanel={false}
          type={actionBarType}
        />
      ) : (
        <Box component="div" sx={{ margin: 'normal' }}>
          <InboxSearch table={table} />
        </Box>
      )}
    </Box>
  )
}

const DataLoading = ({ table }: { table: string }) => {
  const contentLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      table,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )
    return query?.data?.content === undefined && query?.isLoading
  })
  return (
    <LinearProgress sx={{ display: contentLoading ? undefined : 'none' }} />
  )
}

export const InboxBody: FC<InboxBodyProps> = ({ table, actionBarType }) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      {/* Action bar */}
      <InboxBodyHeader table={table} actionBarType={actionBarType} />
      {/* Main Table */}
      <PartsTable table={table} />
      <DataLoading table={table} />
    </Box>
  )
}
