import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface AppsState {
  searchApps: string
}

const initialState = {
  searchApps: '',
} satisfies AppsState
const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setSearchApps(state, action: PayloadAction<string>) {
      state.searchApps = action.payload
    },
  },
})

export const { setSearchApps } = appsSlice.actions
export default appsSlice.reducer
