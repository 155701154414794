import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { Color } from '../../helpers'
import {
  CloseSvg,
  CompleteSvg,
  ErrorOutlineSvg,
  WarningSvg,
} from '../Icon/Icon'
import { ProgressBar } from '../ProgressBar/ProgressBar'

interface StatusBannerProps {
  variant: Color
  message: string
  action?: {
    name: string
    onClick: () => void
  }
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  open: boolean
  closable?: boolean
  value?: number
  inprogress?: boolean
  sx?: SxProps<Theme>
  icon?: React.ReactNode
}

const StatusBanner = ({
  variant,
  message,
  action,
  onClose,
  open,
  closable,
  value,
  inprogress,
  sx,
  icon,
}: StatusBannerProps) => {
  const theme = useTheme()
  const Icon = () => <>{icon}</>
  return (
    <Collapse in={open} style={{ minHeight: 'unset' }}>
      <Box
        component="div"
        sx={{
          width: '100%',
          backgroundColor: theme.palette[variant].light,
        }}
      >
        {value !== undefined && (
          <ProgressBar
            variant="determinate"
            rounded={false}
            color={variant}
            value={value}
            height={5}
          />
        )}
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottomStyle: 'solid',
            borderBottomWidth: '2px',
            borderBottomColor: theme.palette[variant].main,
            minHeight: '2.5em',
            ...(sx && sx),
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '0.5em',
            }}
          >
            {inprogress ? (
              <CircularProgress
                color={variant}
                size="1.15em"
                sx={{ marginLeft: '3px' }}
              />
            ) : icon ? (
              <Icon />
            ) : variant === 'info' ? (
              <ErrorOutlineSvg color={theme.palette[variant].main} />
            ) : variant === 'success' ? (
              <CompleteSvg color={theme.palette[variant].main} />
            ) : variant === 'warning' ? (
              <ErrorOutlineSvg color={theme.palette[variant].main} />
            ) : variant === 'error' ? (
              <WarningSvg color={theme.palette[variant].main} />
            ) : null}
            <Typography fontSize={'0.9em'}>{message}</Typography>
            {action && (
              <Link
                fontSize={'0.9em'}
                component="button"
                color="inherit"
                onClick={action.onClick}
              >
                {action.name}
              </Link>
            )}
          </Box>
          {(closable || closable === undefined) && (
            <IconButton
              onClick={(e) => {
                if (onClose) onClose(e)
              }}
            >
              <CloseSvg color="inherit" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Collapse>
  )
}

export { StatusBanner }
