import { PartStatus } from '../store/baseClientApi'

export const partStatusToTranslatedKey = (status: PartStatus) => {
  switch (status) {
    case 0:
    case 1:
    case 2:
      return 'table.partStatus.processing'
    case 3:
      return 'table.partStatus.toConfirm'
    case 4:
      return 'table.partStatus.toAccept'
    case 5:
    case 7:
    case 8:
    case 9:
      return 'table.partStatus.printReady'
    case 6:
      return 'table.partStatus.rejected'
    case 10:
    case 11:
      return 'table.partStatus.addedToJob'
    case 12:
      return 'table.partStatus.printed'
    case 13:
      return 'table.partStatus.failed'
    case 14:
      return 'table.partStatus.reworkedOffline'
    default:
      return 'table.partStatus.processing'
  }
}
