import { partStatus } from '../constants/partStatus'

const DB_NAME = 'partsCacheDB'
const DB_VERSION = 1
const STORE_NAME = 'parts'
const INVALIDATION_TIMEOUT = 5 * 1000 * 60 * 60 * 24 // 5 days in milliseconds

const openDatabase = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME)
      }
    }

    request.onsuccess = (event) => {
      resolve((event.target as IDBOpenDBRequest).result)
    }

    request.onerror = (event) => {
      reject((event.target as IDBOpenDBRequest).error)
    }
  })
}

const partRequiresInvalidation = (
  currentStatus: number,
  cachedStatus: number,
): boolean => {
  // Check if the part has changed - i.e., has be re-oreinted
  // where the status has changed from uploadPending (0), uploaded (1), or processing (2)
  if (
    (cachedStatus === partStatus['uploadPending'] ||
      cachedStatus === partStatus['uploaded'] ||
      cachedStatus === partStatus['processing']) &&
    currentStatus !== partStatus['uploadPending'] &&
    currentStatus !== partStatus['uploaded'] &&
    currentStatus !== partStatus['processing']
  ) {
    return true
  }
  return false
}

export interface partObjectIndexedDB {
  partStatus: number // PartReadPublic part status
  data: ArrayBuffer // mesh data
  timestamp: number // invalidation timestamp
}

export const savePartToIndexedDB = async (
  partId: string,
  part: partObjectIndexedDB,
) => {
  const db = await openDatabase()
  const transaction = db.transaction(STORE_NAME, 'readwrite')
  const store = transaction.objectStore(STORE_NAME)
  store.put(part, partId)
  return new Promise<void>((resolve, reject) => {
    transaction.oncomplete = () => resolve()
    transaction.onerror = (event) =>
      reject((event.target as IDBTransaction).error)
  })
}

export const getPartFromIndexedDB = async (
  partId: string,
  partStatus: number,
) => {
  const db = await openDatabase()
  const transaction = db.transaction(STORE_NAME, 'readwrite')
  const store = transaction.objectStore(STORE_NAME)
  const request = store.get(partId)
  return new Promise<ArrayBuffer | null>((resolve, reject) => {
    request.onsuccess = (event) => {
      // Check if timestamp is beyond timeout for invalidation
      const data = request.result as partObjectIndexedDB
      if (data && data.data && data.timestamp) {
        if (Math.abs(Date.now() - data.timestamp) > INVALIDATION_TIMEOUT) {
          // Invalidate this object and delete cache store
          store.delete(partId)
          resolve(null)
        } else {
          // check if part has changed at all
          if (partRequiresInvalidation(partStatus, data.partStatus)) {
            resolve(null)
          } else {
            resolve(data.data)
          }
        }
      } else {
        resolve(null)
      }
    }
    request.onerror = (event) => reject(request.error)
  })
}
