import { useTranslation } from 'react-i18next'
import { DashboardLink } from './components/Dashboard/Dashboard'
import {
  DevicesSvg,
  InboxSvg,
  ManageSvg,
  PartsSvg,
  PrintJobsSvg,
  TimelineSvg,
} from './components/Icon/Icon'

const LoadLinks = () => {
  const { t } = useTranslation()
  const PrimaryLinks: DashboardLink[] = [
    {
      name: t('common:links.inbox'),
      link: '/inbox',
      icon: <InboxSvg color="inherit" />,
    },
    {
      name: t('common:links.parts'),
      link: '/parts',
      icon: <PartsSvg color="inherit" />,
    },
    {
      name: t('common:links.printJobs'),
      link: '/jobs',
      icon: <PrintJobsSvg color="inherit" />,
    },
    {
      name: t('common:links.devices'),
      link: '/devices',
      icon: <DevicesSvg color="inherit" />,
    },
    {
      name: t('common:links.timeline'),
      link: '/timeline',
      icon: <TimelineSvg color="inherit" />,
    },
  ]

  const FooterLinks: DashboardLink[] = [
    {
      name: t('common:links.manage'),
      link: '/manage',
      icon: <ManageSvg color="inherit" />,
    },
  ]

  return {
    PrimaryLinks,
    FooterLinks,
  }
}

export { LoadLinks }
