import { Icon, InputAdornment, TextField } from '@mui/material'
import React, { FC } from 'react'
import { SearchSvg } from '../Icon/Icon'

interface SearchFieldProps {
  value: string
  onChange: (str: string) => void
  placeholder: string
  id?: string
}

export const SearchField: FC<SearchFieldProps> = ({
  value,
  onChange,
  placeholder,
  id,
}) => {
  return (
    <TextField
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      placeholder={placeholder}
      size="small"
      InputProps={{
        style: { borderRadius: '40px', height: '40px', margin: '6px 0px' },
        startAdornment: (
          <InputAdornment position="start">
            <Icon>
              <SearchSvg color="inherit" />
            </Icon>
          </InputAdornment>
        ),
      }}
    />
  )
}
