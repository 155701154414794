import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { perPageAmounts } from '../../helpers'
import { RootState } from '../../store/store'

const BasePerPage = ({
  label,
  values = perPageAmounts,
  perPage,
  setPerPage,
}: {
  label?: string
  values?: number[]
  perPage: number
  setPerPage: (perPage: number) => void
}) => {
  const { t } = useTranslation()
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FormControl sx={{ minWidth: label ? '80px' : '120px' }}>
        <InputLabel
          id="per-page-select-label"
          htmlFor="per-page-select"
          sx={{
            color: 'primary.main',
          }}
        >
          {label ? label : t('common:table.pageAmount')}
        </InputLabel>
        <Select
          labelId="per-page-select-label"
          id="per-page"
          value={perPage}
          label={t('common:table.perPage')}
          inputProps={{
            id: 'per-page-select',
          }}
          onChange={(e) =>
            setPerPage(
              typeof e.target.value === 'number' ? e.target.value : values[0],
            )
          }
          sx={{
            height: '2.2em',
            color: 'primary.main',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
            },
            '.MuiSvgIcon-root ': {
              fill: 'primary.main !important',
            },
          }}
        >
          {values
            .sort((a, b) => {
              return a - b
            })
            .map((amount, index) => {
              return (
                <MenuItem key={`per-page-${index}`} value={amount}>
                  {amount}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    </Box>
  )
}

export const ReduxPerPage = ({
  selector,
  dispatch,
}: {
  selector: (state: RootState) => number | undefined
  dispatch: (perPage: number) => void
}) => {
  const perPage = useSelector(selector)

  return (
    <BasePerPage perPage={perPage ?? perPageAmounts[0]} setPerPage={dispatch} />
  )
}

export { BasePerPage as StatePerPage }
