import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  JobStatus,
  useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery,
  useGetMaterialApiV1MaterialsMaterialIdGetQuery,
  useGetPartsApiV1PartsGetQuery,
} from '../../store/clientApi'
import {
  selectFirstCheckedId,
  selectIsOneSelected,
  selectNumChecked,
  selectRtkData,
} from '../../store/slices/tableSlice'
import { RootState } from '../../store/store'
import {
  DetailsGrid,
  ExtendedDetails,
} from '../ExtendedDetails/ExtendedDetails'
import { Edit } from '../JobEdit/JobEdit'
import { JOB_TABLE_STATE_NAME } from '../JobsList/JobsList'
import { ViewportContainer } from '../Viewport/Viewport'

interface JobDetailsProps {
  onClose?: () => void
}

const Title = () => {
  const { t } = useTranslation('jobs')
  const numSelected = useSelector((state: RootState) =>
    selectNumChecked(state, JOB_TABLE_STATE_NAME),
  )
  return (
    <>
      {t('common:label.itemSelected', {
        count: numSelected,
      })}
    </>
  )
}

const JobDetails = ({ onClose }: JobDetailsProps) => {
  const { t } = useTranslation('jobs')
  const UNAVAILABLE = t('common:loading.unavailable')
  const { enableJobReview } = useFlags()

  const selectedJob = useSelector((state: RootState) =>
    selectFirstCheckedId(state, JOB_TABLE_STATE_NAME),
  )

  const isTheOnlySelected = useSelector((state: RootState) =>
    selectIsOneSelected(state, JOB_TABLE_STATE_NAME),
  )

  const error = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.error,
  )
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      JOB_TABLE_STATE_NAME,
      clientApi.endpoints.getJobsApiV1JobsGet.select,
    )

    return query?.isLoading && query?.isUninitialized
  })
  const job = useSelector((state: RootState) =>
    selectRtkData(
      state,
      JOB_TABLE_STATE_NAME,
      clientApi.endpoints.getJobsApiV1JobsGet.select,
    )?.data?.content?.find((job) => job.id.toString() === selectedJob),
  )

  const {
    data: partsData,
    isLoading: partsLoading,
    error: partsError,
  } = useGetPartsApiV1PartsGetQuery(
    {
      query: `job_id:${selectedJob ?? ''}`,
    },
    { skip: selectedJob === undefined },
  )
  const { data: materialData } = useGetMaterialApiV1MaterialsMaterialIdGetQuery(
    {
      materialId: job?.material_id ?? 0,
    },
    { skip: job === undefined },
  )
  const { data: deviceData } =
    useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery(
      {
        serialNumber: job?.printer_serial ?? '',
      },
      { skip: job === undefined },
    )

  const jobInformation = [
    {
      name: t('label.estimatedPrintTime'),
      value: UNAVAILABLE,
    },
    {
      name: t('label.estimatedMaterial'),
      value: UNAVAILABLE,
    },
    {
      name: t('label.targetDevice'),
      value: deviceData?.status?.name ?? UNAVAILABLE,
    },
  ]

  const Parts = () => {
    return partsError ? (
      <>{null}</>
    ) : isLoading || partsLoading ? (
      <CircularProgress />
    ) : partsData?.content ? (
      <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('label.partName')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partsData.content.map((part) => (
            <TableRow key={`${part.id}-${part.name}`}>
              <TableCell>{part.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <>{null}</>
    )
  }

  const title =
    job && materialData?.name ? `#${job?.id} ${materialData.name}` : UNAVAILABLE

  return !isTheOnlySelected || error ? (
    <ExtendedDetails
      loading={false}
      close={onClose}
      title={<Title />}
      subtitle={error && localizeError(t, ensureError(error))}
      banner={
        <>
          <ViewportContainer sx={{ height: '100%' }}>
            <img alt="" src="/images/noBuildSelected.svg" />
          </ViewportContainer>
        </>
      }
    ></ExtendedDetails>
  ) : (
    <ExtendedDetails
      loading={isLoading}
      close={onClose}
      title={title}
      banner={
        <>
          <ViewportContainer>
            <img alt="" src="/images/noBuildSelected.svg" />
          </ViewportContainer>
          <Divider />
        </>
      }
      entries={{
        [t('details.jobInformation')]: {
          startOpen: true,
          content: <DetailsGrid details={jobInformation} />,
        },
        ...((partsLoading ||
          (partsData?.content && partsData?.content?.length > 0) ||
          isLoading) && {
          [t('details.parts')]: {
            startOpen: true,
            content: <Parts />,
          },
        }),
      }}
      footer={
        <Box
          component="div"
          sx={{ display: 'flex', justifyContent: 'flex-end', gap: '6px' }}
        >
          {job && job.status === JobStatus.TO_ACCEPT && enableJobReview && (
            <Edit inDrawer />
          )}
        </Box>
      }
    ></ExtendedDetails>
  )
}

export { JobDetails }
