import { Box, BoxProps, useTheme } from '@mui/material'
import { forwardRef } from 'react'

const CardGrid = forwardRef((props: BoxProps, ref) => {
  const theme = useTheme()

  return (
    <Box
      {...props}
      sx={{
        display: 'grid',
        [theme.breakpoints.only('xs')]: {
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr));',
        },
        [theme.breakpoints.only('sm')]: {
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr));',
        },
        [theme.breakpoints.only('md')]: {
          gridTemplateColumns: 'repeat(auto-fill, minmax(305px, 1fr));',
        },
        [theme.breakpoints.only('lg')]: {
          gridTemplateColumns: 'repeat(auto-fill, minmax(305px, 1fr));',
        },
        [theme.breakpoints.up('xl')]: {
          gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr));',
        },

        gap: '1em',
        overflow: 'auto',
        padding: '0.5em',
        ...props.sx,
      }}
      component="div"
      ref={ref}
    />
  )
})

export { CardGrid }
