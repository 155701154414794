import {
  Alert,
  alpha,
  Box,
  CircularProgress,
  Collapse,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import humanizeDuration from 'humanize-duration'
import { useEffect, useState } from 'react'
import { toast, Toast, useToaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TransitionGroup } from 'react-transition-group'

import {
  selectMetadata,
  selectState,
  WebsocketState,
} from '../../store/slices/wsEventSlice'
import { DoneSvg, ErrorOutlineSvg } from '../Icon/Icon'

const WSReconnectClock = () => {
  const metadata = useSelector(selectMetadata)
  const [time, setTime] = useState(metadata.changedAt)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(Date.now())
    }, 100)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      {t('message.noConnection', {
        time: humanizeDuration(
          metadata.reconnectDelay - (time - metadata.changedAt),
          {
            units: ['h', 'm', 's'],
            round: true,
            largest: 2,
            language: i18n.language,
            fallbacks: [...i18n.languages],
          },
        ),
      })}
    </>
  )
}

const WSEventNotifier = () => {
  const state = useSelector(selectState)
  const metadata = useSelector(selectMetadata)
  const toastID = 'wsEventNotification'
  const { t } = useTranslation()

  useEffect(() => {
    if (metadata.reconnectDelay < 2000) {
      toast.remove(toastID)
      return
    }

    switch (state) {
      case WebsocketState.INIT:
      case WebsocketState.OPEN:
        toast.remove(toastID)
        break
      case WebsocketState.CONNECTING:
        toast.loading(t('message.reconnecting'), {
          id: toastID,
          duration: Infinity,
        })
        break
      case WebsocketState.CLOSED:
        toast.loading(
          <>
            <WSReconnectClock />
          </>,
          { id: toastID, duration: Infinity },
        )
        break
    }
  }, [t, state, metadata.reconnectDelay])

  return <>{null}</>
}

export const Snackbar = () => {
  const { toasts, handlers } = useToaster()
  const { startPause, endPause } = handlers
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const iconSize = lgUp ? '24px' : '16px'

  const icon = (toast: Toast) => {
    switch (toast.type) {
      case 'blank':
        return false
      case 'custom':
        return <>{toast.icon}</>
      case 'error':
        return (
          <ErrorOutlineSvg
            width={iconSize}
            height={iconSize}
            color={theme.palette.error.main}
          />
        )
      case 'loading':
        return (
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size={iconSize} color="secondary" />
          </Box>
        )
      case 'success':
        return (
          <DoneSvg
            width={iconSize}
            height={iconSize}
            color={theme.palette.success.main}
          />
        )
    }
  }

  return (
    <Box
      component="div"
      sx={{
        position: 'fixed',
        bottom: '1em',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      <WSEventNotifier />
      <TransitionGroup>
        {toasts.map((toast) => (
          <Collapse key={toast.id} in={toast.visible} unmountOnExit>
            <Alert
              icon={icon(toast)}
              variant="filled"
              sx={{
                backgroundColor: (theme) => alpha(theme.surface.on, 0.75),
                color: (theme) => theme.palette.primary.contrastText,
                marginTop: '0.5em',
                padding: {
                  xs: '8px',
                  sm: '8px',
                  md: '12px',
                  lg: '16px',
                },
                minWidth: '240px',
                ...(toast.type === 'blank' && {
                  display: 'flex',
                  justifyContent: 'center',
                }),
                boxShadow: (theme) => theme.shadows[1],
                '& .MuiAlert-icon': {
                  marginRight: {
                    sx: '4px',
                    sm: '4px',
                    md: '6px',
                    lg: '8px',
                  },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
              {...toast.ariaProps}
            >
              {toast.message as any}
            </Alert>
          </Collapse>
        ))}
      </TransitionGroup>
    </Box>
  )
}
