import React from 'react'

interface SpacerProps {
  height?: string
  width?: string
}

const Spacer: React.FC<SpacerProps> = ({ height = '0', width = '0' }) => {
  return <div style={{ height, width }} />
}

export default Spacer
