import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import Bowser from 'bowser'
import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSignContextApiV1SignContextPatchMutation } from './store/baseClientApi'
import { SetupAction } from './store/slices/wsEventSlice'
import { store } from './store/store'

export const Identity = () => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0()
  const ldClient = useLDClient()
  const { i18n } = useTranslation()
  const [signContext] = useSignContextApiV1SignContextPatchMutation()

  const device = useMemo(() => {
    const browser = Bowser.getParser(window.navigator.userAgent)
    return {
      key: window.navigator.userAgent,
      name: browser.getBrowserName(),
      version: browser.getBrowserVersion(),
      os: browser.getOSName(),
      platform: browser.getPlatformType(),
      locales: [...i18n.languages],
    }
  }, [i18n.languages])

  useEffect(() => {
    const identify = async () => {
      const claims = await getIdTokenClaims()
      try {
        const response = await signContext({
          contextRequest: {
            device: device,
            id_token: claims?.__raw ?? '',
          },
        }).unwrap()
        if (ldClient) {
          ldClient.identify(response.context as LDContext, response.hash)
          Sentry.setUser({
            email: response.context.user.email,
            id: response.context.user.key,
          })
        }
      } catch (err) {
        return
      }
    }

    const setupWebsocket = async () => {
      const token = await getAccessTokenSilently()
      store.dispatch(
        SetupAction({
          ws: import.meta.env.VITE_WS_API_URL,
          subprotocol: import.meta.env.VITE_WS_SUBPROTOCOL,
          accessToken: token,
        }),
      )
    }
    setupWebsocket()
    identify()
  }, [device, signContext, ldClient, getIdTokenClaims, getAccessTokenSilently])

  return <>{null}</>
}

export const AuthIdentity = withAuthenticationRequired(Identity)
