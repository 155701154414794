import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  LinearProgress,
  OutlinedInput,
  Typography,
  useFormControl,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { ensureError, localizeError } from '../../helpers'
import { useOrg } from '../../hooks/org'
import { useUser } from '../../hooks/user'
import {
  useDeleteUserApiV1UsersUserIdDeleteMutation,
  useResetUserApiV1UsersUserIdResetPutMutation,
  useUpdateUserApiV1UsersUserIdPatchMutation,
} from '../../store/clientApi'
import { Button } from '../Button/Button'
import { DialogBox } from '../DialogBox'

interface UserDetailsProps {
  close?: () => void
}

const UserDetails: FC<UserDetailsProps> = (props) => {
  const { t } = useTranslation('users')

  const { logout } = useAuth0()

  const [name, setName] = useState<string | undefined>()
  const [resetDisabled, setResetDisabled] = useState(false)
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false)

  const { userId, picture, data: userData, error, isLoading } = useUser()
  if (userData?.name && name === undefined) setName(userData?.name)

  const { org } = useOrg()

  const [updateUser] = useUpdateUserApiV1UsersUserIdPatchMutation()
  const [deleteSelf, { isSuccess: deletedSelf }] =
    useDeleteUserApiV1UsersUserIdDeleteMutation({})
  const [reset] = useResetUserApiV1UsersUserIdResetPutMutation()

  if (deletedSelf) {
    logout({
      logoutParams: { returnTo: window.location.origin },
    })
  }

  const NameUpdater = () => {
    const { focused } = useFormControl() || {}
    const [lastState, setLastState] = useState(false)

    useEffect(() => {
      const updateName = async () => {
        if (!userId || name === userData?.name || name === '') return
        try {
          await updateUser({
            userId: encodeURI(userId),
            updateUserRequest: { name: name },
          }).unwrap()
        } catch (err) {
          const error = ensureError(err)
          toast.error(localizeError(t, error))
        }
      }
      if (lastState !== focused) {
        if (focused === false) {
          updateName()
        }
        setLastState(focused ?? false)
      }
    }, [focused, setLastState, lastState])

    return <>{null}</>
  }

  const resetPassword = async () => {
    if (!userId) return

    setResetDisabled(true)

    try {
      await reset({
        userId: encodeURI(userId),
      }).unwrap()
    } catch (err) {
      const error = ensureError(err)
      toast.error(localizeError(t, error))

      setResetDisabled(false)

      return
    }

    setTimeout(() => setResetDisabled(false), 1000)
  }

  return (
    <>
      {error ? (
        localizeError(t, ensureError(error))
      ) : isLoading ? (
        <LinearProgress />
      ) : userData ? (
        <>
          <Box
            component="div"
            sx={{
              '& div': { marginBottom: '0.5em' },
              '& hr': { mb: '0.5em', marginTop: '1em' },
              '& div .MuiTypography-h2': { marginBottom: '0.7em' },
              '& div .actions': {
                marginTop: '0.5em',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '0.5em',
              },
            }}
          >
            <Box component="div">
              <Typography variant="h2">{t('common:title.photo')}</Typography>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Avatar
                  src={picture}
                  sx={{
                    width: 72,
                    height: 72,
                    marginRight: '0.8em',
                  }}
                ></Avatar>
                <Typography>{t('message.profilePicture')}</Typography>
              </Box>
              <Box className="actions" component="div">
                <Button variant="contained" disabled>
                  {t('button.uploadPhoto')}
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box component="div">
              <Typography variant="h2">{t('common:title.name')}</Typography>
              <FormControl sx={{ width: '25ch' }}>
                <OutlinedInput
                  id="display-name"
                  size="small"
                  value={name}
                  error={name === ''}
                  onChange={(e) => setName(e.target.value)}
                />
                <NameUpdater />
              </FormControl>
            </Box>
            <Divider />
            <Box component="div">
              <Typography variant="h2">{t('title.changePassword')}</Typography>
              <Typography>
                {t(
                  'message.changePassword',
                  userData?.email
                    ? {
                        context: 'email',
                        email: userData?.email,
                      }
                    : undefined,
                )}
              </Typography>
              <Box className="actions" component="div">
                <Button
                  id="change-password"
                  variant="contained"
                  onClick={() => {
                    if (userId) resetPassword()
                  }}
                  disabled={resetDisabled}
                >
                  {resetDisabled
                    ? t('message.linkSent')
                    : t('button.changePassword')}
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box component="div">
              <Typography variant="h2">{t('title.deleteProfile')}</Typography>
              <Typography>
                {t(
                  'message.deleteProfile',
                  org?.name
                    ? {
                        context: 'org',
                        org: org?.name,
                      }
                    : undefined,
                )}
              </Typography>
              <Box className="actions" component="div">
                <Button
                  id="delete-profile"
                  variant="contained"
                  color="error"
                  onClick={() => setOpenDeleteWarning(true)}
                >
                  {t('button.deleteProfile')}
                </Button>
              </Box>
            </Box>
          </Box>
          <DialogBox
            title={t('title.confirmDeleteProfile')}
            message={t(
              'message.deleteProfile',
              org?.name
                ? {
                    context: 'org',
                    org: org?.name,
                  }
                : undefined,
            )}
            open={openDeleteWarning}
            setOpen={setOpenDeleteWarning}
            args={undefined}
            onClose={(approve) => {
              if (approve && userId) {
                const remove = async () => {
                  try {
                    await deleteSelf({
                      userId: encodeURI(userId),
                    }).unwrap()
                    if (props.close) props.close()
                  } catch (err) {
                    const error = ensureError(err)
                    toast.error(localizeError(t, error))
                  }
                }
                remove()
              }
            }}
            confirmColor="error"
            confirmText={t('button.deleteProfile')}
            declineText={t('common:button.cancel')}
          />
        </>
      ) : null}
    </>
  )
}

export { UserDetails }
