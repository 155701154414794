import {
  alpha,
  darken,
  lighten,
  Button as MuiButton,
  styled,
  useTheme,
} from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import { Color } from '../../helpers'

interface ButtonOptions {
  short?: boolean
}

export const Button = <C extends React.ElementType>({
  short,
  startIcon,
  endIcon,
  size,
  ...props
}: ButtonProps<C, { component?: C }> & ButtonOptions) => {
  const theme = useTheme()
  const color = props.color ?? 'primary'
  const variant = props.variant ?? 'contained'
  const textColor =
    props.variant === 'text' || props.variant === 'outlined'
      ? theme.palette[color as Color].main
      : theme.palette[color as Color].contrastText
  const Button = styled(MuiButton)(({ theme }) => ({
    borderRadius: '1em',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    lineHeight: '24px',
    '&:hover': {
      ...(variant === 'contained' && {
        backgroundColor: lighten(theme.palette[color as Color].main, 0.08), // on-hover (+8% lighten)
      }),
      ...(variant === 'contained' &&
        color === 'secondary' && {
          backgroundColor: darken(theme.palette.secondary.main, 0.08), // on-hover (+8% darken)
        }),
    },
    '&:active': {
      ...(variant === 'contained' && {
        backgroundColor: lighten(theme.palette[color as Color].main, 0.1), // on-hover (+10% lighten)
      }),
      ...(variant === 'contained' &&
        color === 'secondary' && {
          backgroundColor: darken(theme.palette.secondary.main, 0.1), // (+10% darken)
        }),
    },
    ...(props.disabled
      ? {
          color:
            props.color === 'primary' && variant === 'contained'
              ? theme.palette.secondary.contrastText + '!important'
              : textColor + '!important',
          backgroundColor:
            variant === 'contained'
              ? alpha(theme.palette[color as Color].main, 0.4) + '!important'
              : undefined,
          borderColor:
            alpha(theme.palette[color as Color].main, 0.4) + '!important',
        }
      : {}),
  }))

  return (
    <Button
      disableElevation
      {...props}
      startIcon={short ? undefined : startIcon}
      endIcon={short ? undefined : endIcon}
      variant={variant}
      size={size ?? 'small'}
    />
  )
}
