import {
  Box,
  CircularProgress,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { Viewport } from '../'
import { partClassification } from '../../constants/partClassification'
import { ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  PaginatedPartReadPublic,
  PartClassification,
  PartReadPublic,
  PartStatus,
} from '../../store/clientApi'
import { useDownloadPartApiV1PartsPartIdUploadedGetMutation } from '../../store/clientApi'
import { setDrawerOpen } from '../../store/slices/rightDrawerSlice'
import {
  selectFirstCheckedId,
  selectIsChecked,
  selectIsOneSelected,
  selectNumChecked,
  selectRtkData,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import {
  getPartFromIndexedDB,
  partObjectIndexedDB,
  savePartToIndexedDB,
} from '../../utils/indexeddb'
import { partStatusToTranslatedKey } from '../../utils/partStatusToTranslatedKey'
import {
  DetailsGrid,
  ExtendedDetails,
} from '../ExtendedDetails/ExtendedDetails'
import { Model } from '../Model/Model'
import { PartButtonGroup, PartButtonGroupType } from '../PartActionBar/buttons'
import { PART_TABLE_STATE_NAMES } from '../PartsTable/PartsTable'
import { RightDrawer } from '../RightDrawer/RightDrawer'
import { ViewportContainer } from '../Viewport/Viewport'

interface InboxInspectorProps {
  table: PART_TABLE_STATE_NAMES
  drawer: PART_INSPECTOR_STATES
  currentTab: string | false
}

const PartViewport = ({
  table,
  actionBarButtons,
}: {
  table: PART_TABLE_STATE_NAMES
  actionBarButtons: ReactNode
}) => {
  const { t } = useTranslation('inbox')
  const [downloadPartAPI] = useDownloadPartApiV1PartsPartIdUploadedGetMutation()
  const [modelFile, setModelFile] = useState<ArrayBuffer | undefined>()
  const [modelDownloadProgress, setModelDownloadProgress] = useState<
    number | undefined
  >(undefined)
  const selectedPartId = useSelector((state: RootState) =>
    selectFirstCheckedId(state, table),
  )
  const isTheOnlySelected = useSelector((state: RootState) =>
    selectIsOneSelected(state, table),
  )
  const isSelected = useSelector((state: RootState) =>
    selectIsChecked(state, table),
  )

  const selectPartStatus = useMemo(() => {
    const emptyArray: PartReadPublic[] = []

    return createSelector(
      [(res?: PaginatedPartReadPublic) => res?.content ?? emptyArray],
      (content) =>
        content.find((part) => part.id.toString() === selectedPartId)?.status,
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [selectedPartId])

  const selectedPartStatus = useSelector((state: RootState) =>
    selectPartStatus(
      selectRtkData(
        state,
        table,
        clientApi.endpoints.getPartsApiV1PartsGet.select,
      )?.data,
    ),
  )

  const selectPartName = useMemo(() => {
    const emptyArray: PartReadPublic[] = []

    return createSelector(
      [(res?: PaginatedPartReadPublic) => res?.content ?? emptyArray],
      (content) =>
        content.find((part) => part.id.toString() === selectedPartId)?.name,
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [selectedPartId])

  const selectedPartName = useSelector((state: RootState) =>
    selectPartName(
      selectRtkData(
        state,
        table,
        clientApi.endpoints.getPartsApiV1PartsGet.select,
      )?.data,
    ),
  )

  const [modelViewportError, setModelViewportError] = useState<
    string | undefined
  >()

  useEffect(() => {
    const getViewportModel = async () => {
      setModelFile(undefined)
      setModelViewportError(undefined)
      setModelDownloadProgress(undefined)

      if (!isTheOnlySelected) return
      if (selectedPartId === undefined) return
      if (selectedPartStatus === undefined) return

      // Check IndexedDB first
      const cachedModel = await getPartFromIndexedDB(
        selectedPartId,
        selectedPartStatus,
      )
      if (cachedModel !== null) {
        setModelFile(cachedModel)
        return
      }
      try {
        // Fetch from API if not in IndexedDB
        const data = await downloadPartAPI({
          partId: parseInt(selectedPartId),
        }).unwrap()

        const presignedUrl = (data as { presigned_url: string }).presigned_url
        const downloadData = await axios.get(presignedUrl, {
          responseType: 'arraybuffer', // Ensure binary data is received
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.total !== undefined) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              )
              if (
                Math.abs(
                  modelDownloadProgress ? modelDownloadProgress : 0 - progress,
                ) > 5
              ) {
                // 5% step
                setModelDownloadProgress(progress)
              }
            }
          },
        })

        // Save to IndexedDB and update state
        await savePartToIndexedDB(selectedPartId, {
          partStatus: selectedPartStatus,
          data: downloadData.data,
          timestamp: Date.now(),
        } as partObjectIndexedDB)
        setModelFile(downloadData.data)
      } catch (err) {
        const error = ensureError(err)
        setModelViewportError(localizeError(t, error))
      }
    }

    getViewportModel()
  }, [isTheOnlySelected, selectedPartId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isTheOnlySelected) {
    if (modelFile !== undefined) {
      return (
        <Sentry.ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag('location', 'part inspector model viewer')
          }}
          fallback={
            <>
              <ViewportContainer>
                <img
                  alt=""
                  src="/images/inspectorNoParts.svg"
                  style={{
                    padding: '1em',
                    boxSizing: 'border-box',
                    height: '100%',
                  }}
                />
              </ViewportContainer>
            </>
          }
        >
          <Viewport
            modelName={selectedPartName}
            maximisedFooter={
              isTheOnlySelected && (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '6px',
                  }}
                >
                  {actionBarButtons}
                </Box>
              )
            }
          >
            <Model file={modelFile} />
          </Viewport>
        </Sentry.ErrorBoundary>
      )
    } else if (!modelViewportError) {
      return (
        <ViewportContainer>
          {modelDownloadProgress !== undefined ? (
            <CircularProgress
              variant="determinate"
              value={modelDownloadProgress}
              size={18}
            />
          ) : (
            <CircularProgress size={18} />
          )}
        </ViewportContainer>
      )
    } else {
      return (
        <ViewportContainer>
          <img
            alt=""
            src="/images/inspectorNoParts.svg"
            style={{ padding: '1em', boxSizing: 'border-box', height: '100%' }}
          />
        </ViewportContainer>
      )
    }
  } else if (!isSelected) {
    return (
      <ViewportContainer>
        <img
          id="inspector-no-parts-img"
          alt=""
          src="/images/inspectorNoParts.svg"
          style={{ padding: '1em', boxSizing: 'border-box', height: '100%' }}
        />
      </ViewportContainer>
    )
  } else {
    return (
      <ViewportContainer>
        <img
          id="inspector-multiple-parts-img"
          alt=""
          src="/images/inspectorMultipleSelected.svg"
          style={{ padding: '1em', boxSizing: 'border-box', height: '100%' }}
        />
      </ViewportContainer>
    )
  }
}

const PartDetails = ({ table }: { table: string }) => {
  const { t, i18n } = useTranslation('inbox')
  const UNAVAILABLE = t('common:loading.unavailable')

  const selectedPartId = useSelector((state: RootState) =>
    selectFirstCheckedId(state, table),
  )

  const selectedPart = useSelector((state: RootState) =>
    selectRtkData(
      state,
      table,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )?.data?.content?.find((part) => part.id.toString() === selectedPartId),
  )

  const getPartType = (partType: PartClassification) => {
    const classification = partClassification[partType]
    return t(
      /*
        t('other')
        t('hollowModel')
        t('solidModel')
        t('crownOrToothOrVeneer')
        t('screwInTooth')
        t('die')
        t('flatGingiva')
        t('pillowGingiva')
        t('teethRow')
        t('dentureBase')
        t('denturePartial')
        t('tryInDenture')
        t('occlusalSplint')
        t('surgicalGuide')
        t('bridge')
        t('partialFramework')
        */
      `table.partClassification.${classification}`,
    )
  }

  const getPartStatus = (status: PartStatus) => {
    return t(
      /*
      t('processing')
      t('toConfirm')
      t('toAccept')
      t('printReady')
      t('rejected')
      t('addedToJob')
      t('offline')
      t('printed')
      */
      partStatusToTranslatedKey(status),
    )
  }

  const partInformation = [
    {
      name: t('partInspector.partType'),
      value: selectedPart?.classification
        ? getPartType(selectedPart?.classification)
        : UNAVAILABLE,
    },
    {
      name: t('partInspector.status'),
      value: selectedPart?.status
        ? getPartStatus(selectedPart?.status)
        : UNAVAILABLE,
    },
    {
      name: t('partInspector.received'),
      value: selectedPart?.created_at
        ? new Date(selectedPart?.created_at).toLocaleString(
            [...i18n.languages],
            {
              day: '2-digit',
              month: 'long',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            },
          )
        : '-',
    },
    {
      name: t('partInspector.source'),
      value: selectedPart?.source ?? UNAVAILABLE,
    },
  ]

  return <DetailsGrid details={partInformation} />
}

const Title = ({ table }: { table: string }) => {
  const { t } = useTranslation('inbox')
  const UNAVAILABLE = t('common:loading.unavailable')

  const numSelected = useSelector((state: RootState) =>
    selectNumChecked(state, table),
  )
  const selectedPartId = useSelector((state: RootState) =>
    selectFirstCheckedId(state, table),
  )

  const selectedPart = useSelector((state: RootState) =>
    selectRtkData(
      state,
      table,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )?.data?.content?.find((part) => part.id.toString() === selectedPartId),
  )

  return (
    <>
      {numSelected !== 1
        ? t('partInspector.title', {
            count: numSelected,
          })
        : selectedPart?.name ?? UNAVAILABLE}
    </>
  )
}

export const InboxInspector: FC<InboxInspectorProps> = ({
  table,
  drawer,
  currentTab,
}) => {
  const { t } = useTranslation('inbox')
  const theme = useTheme()

  const isTheOnlySelected = useSelector((state: RootState) =>
    selectIsOneSelected(state, table),
  )

  const getActionBarButtons = () => {
    switch (currentTab) {
      case 'Confirm':
      case 'Review':
      case 'Accepted':
      case 'Rejected':
        return (
          <PartButtonGroup
            table={table}
            isInspectorPanel={true}
            type={currentTab.toLowerCase() as PartButtonGroupType}
          />
        )
      // Parts Page Tabs
      case 'AllParts':
      case 'PrintReady':
      case 'Sent':
        return (
          <PartButtonGroup
            table={table}
            isInspectorPanel={true}
            type={'parts'}
          />
        )
      default:
        return <></>
    }
  }
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <>
      <RightDrawer
        stateName={drawer}
        width={lgDown ? '100%' : '30vw'}
        shift
        sx={{
          [theme.breakpoints.up('lg')]: {
            maxWidth: '460px',
          },
          flexGrow: '1',
          flexShrink: '0',
        }}
        padding="0px"
      >
        <ExtendedDetails
          loading={false}
          title={<Title table={table} />}
          close={() =>
            store.dispatch(setDrawerOpen({ name: drawer, value: false }))
          }
          banner={
            <>
              <PartViewport
                table={table}
                actionBarButtons={getActionBarButtons()}
              />
              <Divider />
            </>
          }
          entries={{
            ...(isTheOnlySelected && {
              [t('partInspector.partInformation')]: {
                startOpen: true,
                content: <PartDetails table={table} />,
              },
            }),
          }}
          footer={
            isTheOnlySelected && (
              <Box
                component="div"
                sx={{ display: 'flex', justifyContent: 'flex-end', gap: '6px' }}
              >
                {getActionBarButtons()}
              </Box>
            )
          }
        />
      </RightDrawer>
    </>
  )
}

export const INBOX_INSPECTOR_STATE = 'inboxInspectorState'
export const PART_INSPECTOR_STATE = 'partInspectorState'
export type PART_INSPECTOR_STATES = 'inboxInspectorState' | 'partInspectorState'
