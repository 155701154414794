import { Avatar, Box, Typography } from '@mui/material'

interface ProfileProps {
  picture: string
  info?: string
  name: string
}

const Profile = ({ picture, info, name }: ProfileProps) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Avatar src={picture} sx={{ marginRight: '10px' }}></Avatar>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Typography>{name}</Typography>
        {info && <Typography>{info}</Typography>}
      </Box>
    </Box>
  )
}

export { Profile }
