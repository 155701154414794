import {
  Box,
  IconButton,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ensureError, localizeError } from '../../helpers'
import { useRemoveDeviceApiV1DevicesSerialNumberDeleteMutation } from '../../store/clientApi'
import { selectTableChecked } from '../../store/slices/tableSlice'
import { RootState } from '../../store/store'
import { Button } from '../Button/Button'
import { CardGrid } from '../CardGrid/CardGrid'
import { DeviceCard } from '../DeviceCard/DeviceCard'
import { DeviceViewer } from '../DeviceViewer/DeviceViewer'
import { DialogBox } from '../DialogBox'
import { EmptyState } from '../EmptyState/EmptyState'
import { AddSvg, MachinesSvg, WarningSvg } from '../Icon/Icon'
import { RightDrawer } from '../RightDrawer/RightDrawer'
import { Tab, Tabs } from '../Tabs/Tabs'

const enrolDeviceLink = `https://${import.meta.env.VITE_AUTH0_DOMAIN}/activate`

interface DevicesListProps {
  tab: string | false
  setTab: (newValue: string | false) => void
  deviceThingNames: string[]
  error: FetchBaseQueryError | SerializedError | undefined
  isLoading: boolean | undefined
}

const DevicesList: FC<DevicesListProps> = ({
  tab,
  setTab,
  deviceThingNames,
  error,
  isLoading,
}) => {
  const theme = useTheme()
  const { t } = useTranslation('devices')

  const checked = useSelector((state: RootState) =>
    selectTableChecked(state, DEVICES_TABLE_STATE_NAME),
  )

  // Determine the selected device
  const selectedDevice = Object.keys(checked).find(
    (thingName) => checked[thingName],
  )
    ? {
        thingName: Object.keys(checked).find((thingName) => checked[thingName]),
      }
    : undefined

  const [confirmRemove, setConfirmRemove] = useState<string | undefined>(
    undefined,
  )
  const [height, setHeight] = useState<number>(0)

  const [removeDevice] = useRemoveDeviceApiV1DevicesSerialNumberDeleteMutation()

  const { allowDevicesUi } = useFlags()
  const [isallowDevicesUiTimedOut, setIsallowDevicesUiTimedOut] =
    useState<boolean>(false)

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if allowDevicesUi is undefined
    if (!allowDevicesUi) {
      timeoutId = setTimeout(() => {
        setIsallowDevicesUiTimedOut(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if allowDevicesUi becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [allowDevicesUi])

  const isDeviceSelect = selectedDevice != null
  const mediaQueryBreakpoint = isDeviceSelect ? 'lg' : 'sm'
  const iconOnly = useMediaQuery(theme.breakpoints.up(mediaQueryBreakpoint))

  const getHeader = () => {
    return (
      <>
        <Typography variant="h1">{t('title.devices')}</Typography>
        <Tabs
          tab={tab}
          setTab={setTab}
          defaultTab="all"
          aria-label={t('label.devicesTab')}
          buttons={
            iconOnly ? (
              <Button
                startIcon={<AddSvg color="inherit" />}
                color="secondary"
                href={enrolDeviceLink}
                target="_blank"
              >
                {t('button.add')}
              </Button>
            ) : (
              <IconButton href={enrolDeviceLink} target="_blank">
                <AddSvg color="inherit" />
              </IconButton>
            )
          }
        >
          <Tab
            icon={<MachinesSvg color="inherit" />}
            label={t('label.devices')}
            value="all"
          />
          <Tab
            icon={<WarningSvg color={theme.palette.warning.main} />}
            label={t('label.requiresAttention')}
            value="attention"
          />
        </Tabs>
      </>
    )
  }

  const DevicesGrid = ({ devices }: { devices: string[] }) => {
    return devices.length > 0 ? (
      <CardGrid>
        {devices.map((thingName) => {
          return (
            <Box
              component="div"
              sx={{
                height: 'fit-content',
              }}
              key={`build-${thingName}`}
            >
              <DeviceCard
                thingName={thingName}
                setConfirmRemove={setConfirmRemove}
              />
            </Box>
          )
        })}
      </CardGrid>
    ) : tab === 'attention' ? (
      <EmptyState
        image={'/images/no-attention-required.svg'}
        title={t('devices:message.allGood')}
        message={t('devices:message.noDevicesNeedAttention')}
      />
    ) : (
      <EmptyState
        image={'/images/no-devices.svg'}
        title={t('devices:message.devicesPageInfo')}
        message={t('devices:message.addDevices')}
        button={
          <Button
            color="primary"
            href={enrolDeviceLink}
            target="_blank"
            sx={{
              mt: '1.5em',
            }}
          >
            {t('button.add')}
          </Button>
        }
      />
    )
  }

  const RemoveDevice = () => {
    return (
      <DialogBox
        title={t('title.removeDevice')}
        message={t('message.warnRemoveDevice')}
        open={confirmRemove !== undefined}
        setOpen={(open) => {
          if (!open) setConfirmRemove(undefined)
        }}
        args={undefined}
        onClose={(approve) => {
          if (confirmRemove && approve) {
            try {
              removeDevice({
                serialNumber: confirmRemove,
              }).unwrap()
            } catch (err) {
              const error = ensureError(err)
              toast.error(localizeError(t, error))
            }
          }
        }}
      ></DialogBox>
    )
  }

  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignContent: 'stretch',
          justifyContent: 'space-evenly',
          gap: '1em',
          width: '100%',
          height: '100%',
        }}
        ref={(ref: HTMLDivElement) => {
          if (ref) {
            setHeight(ref.clientHeight ?? 0)
            window.addEventListener('resize', () =>
              setHeight(ref.clientHeight ?? 0),
            )
          }
        }}
      >
        {error ? (
          <>{localizeError(t, ensureError(error))}</>
        ) : isLoading ||
          (allowDevicesUi === undefined && !isallowDevicesUiTimedOut) ? (
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              bgcolor: (theme: Theme) => theme.surface.low,
              padding: '1em',
              borderRadius: '1em',
              overflow: 'auto',
            }}
          >
            {getHeader()}
            <LinearProgress />
          </Box>
        ) : deviceThingNames ? (
          <>
            <Box
              component="div"
              sx={{
                display: mdDown
                  ? selectedDevice === undefined
                    ? 'flex'
                    : 'none'
                  : 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                bgcolor: (theme: Theme) => theme.surface.low,
                padding: '1em',
                borderRadius: '1em',
                overflow: 'auto',
              }}
            >
              {getHeader()}
              <DevicesGrid devices={deviceThingNames} />
            </Box>
            <RightDrawer
              stateName={DEVICE_DETAILS_STATE}
              width={mdDown ? '100%' : '40vw'}
              shift
              sx={{
                [theme.breakpoints.up('md')]: {
                  maxWidth: '460px',
                },
                height: `${height}px`,
                flexGrow: '1',
                flexShrink: '0',
              }}
              padding="0px"
            >
              {selectedDevice?.thingName !== undefined && <DeviceViewer />}
            </RightDrawer>
          </>
        ) : null}
      </Box>
      <RemoveDevice />
    </>
  )
}

export const DEVICE_DETAILS_STATE = 'deviceDetailsState'
export const DEVICES_TABLE_STATE_NAME = 'devicesTableState'

export { DevicesList }
