/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { emptyApi as api } from './emptyApi'
export const addTagTypes = [
  'addresses',
  'devices',
  'enrol',
  'integrations',
  'jobs',
  'materials',
  'organisations',
  'parts',
  '3rd-party',
  'misc',
  'templates',
  'users',
  'application',
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createAddressApiV1AddressesPost: build.mutation<
        CreateAddressApiV1AddressesPostApiResponse,
        CreateAddressApiV1AddressesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/addresses`,
          method: 'POST',
          body: queryArg.addressCreateDto,
        }),
        invalidatesTags: ['addresses'],
      }),
      deleteAddressApiV1AddressesAddressIdDelete: build.mutation<
        DeleteAddressApiV1AddressesAddressIdDeleteApiResponse,
        DeleteAddressApiV1AddressesAddressIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/addresses/${queryArg.addressId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['addresses'],
      }),
      getAddressApiV1AddressesAddressIdGet: build.query<
        GetAddressApiV1AddressesAddressIdGetApiResponse,
        GetAddressApiV1AddressesAddressIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/addresses/${queryArg.addressId}`,
        }),
        providesTags: ['addresses'],
      }),
      updateAddressApiV1AddressesAddressIdPut: build.mutation<
        UpdateAddressApiV1AddressesAddressIdPutApiResponse,
        UpdateAddressApiV1AddressesAddressIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/addresses/${queryArg.addressId}`,
          method: 'PUT',
          body: queryArg.addressUpdateDto,
        }),
        invalidatesTags: ['addresses'],
      }),
      getDevicesApiV1DevicesGet: build.query<
        GetDevicesApiV1DevicesGetApiResponse,
        GetDevicesApiV1DevicesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/devices`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['devices'],
      }),
      enrolDeviceApiV1DevicesEnrolPost: build.mutation<
        EnrolDeviceApiV1DevicesEnrolPostApiResponse,
        EnrolDeviceApiV1DevicesEnrolPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/devices/enrol`,
          method: 'POST',
          body: queryArg.deviceEnrolmentRequest,
        }),
        invalidatesTags: ['devices', 'devices', 'enrol'],
      }),
      getDevicesWithStatusApiV1DevicesStatusGet: build.query<
        GetDevicesWithStatusApiV1DevicesStatusGetApiResponse,
        GetDevicesWithStatusApiV1DevicesStatusGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/devices/status`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['devices'],
      }),
      removeDeviceApiV1DevicesSerialNumberDelete: build.mutation<
        RemoveDeviceApiV1DevicesSerialNumberDeleteApiResponse,
        RemoveDeviceApiV1DevicesSerialNumberDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/devices/${queryArg.serialNumber}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['devices'],
      }),
      getDeviceApiV1DevicesSerialNumberGet: build.query<
        GetDeviceApiV1DevicesSerialNumberGetApiResponse,
        GetDeviceApiV1DevicesSerialNumberGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/devices/${queryArg.serialNumber}`,
        }),
        providesTags: ['devices'],
      }),
      getDeviceStatusApiV1DevicesSerialNumberStatusGet: build.query<
        GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiResponse,
        GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/devices/${queryArg.serialNumber}/status`,
        }),
        providesTags: ['devices'],
      }),
      getIntegrationsApiV1IntegrationsGet: build.query<
        GetIntegrationsApiV1IntegrationsGetApiResponse,
        GetIntegrationsApiV1IntegrationsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/integrations`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['integrations'],
      }),
      getAllIntegrationLinksApiV1IntegrationsLinksGet: build.query<
        GetAllIntegrationLinksApiV1IntegrationsLinksGetApiResponse,
        GetAllIntegrationLinksApiV1IntegrationsLinksGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/integrations/links`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['integrations'],
      }),
      getIntegrationApiV1IntegrationsIntegrationIdGet: build.query<
        GetIntegrationApiV1IntegrationsIntegrationIdGetApiResponse,
        GetIntegrationApiV1IntegrationsIntegrationIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/integrations/${queryArg.integrationId}`,
        }),
        providesTags: ['integrations'],
      }),
      disconnectIntegrationApiV1IntegrationsIntegrationIdLinkDelete:
        build.mutation<
          DisconnectIntegrationApiV1IntegrationsIntegrationIdLinkDeleteApiResponse,
          DisconnectIntegrationApiV1IntegrationsIntegrationIdLinkDeleteApiArg
        >({
          query: (queryArg) => ({
            url: `/api/v1/integrations/${queryArg.integrationId}/link`,
            method: 'DELETE',
          }),
          invalidatesTags: ['integrations'],
        }),
      getIntegrationLinkApiV1IntegrationsIntegrationIdLinkGet: build.query<
        GetIntegrationLinkApiV1IntegrationsIntegrationIdLinkGetApiResponse,
        GetIntegrationLinkApiV1IntegrationsIntegrationIdLinkGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/integrations/${queryArg.integrationId}/link`,
        }),
        providesTags: ['integrations'],
      }),
      connectIntegrationApiV1IntegrationsIntegrationIdLinkPut: build.mutation<
        ConnectIntegrationApiV1IntegrationsIntegrationIdLinkPutApiResponse,
        ConnectIntegrationApiV1IntegrationsIntegrationIdLinkPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/integrations/${queryArg.integrationId}/link`,
          method: 'PUT',
        }),
        invalidatesTags: ['integrations'],
      }),
      getJobsApiV1JobsGet: build.query<
        GetJobsApiV1JobsGetApiResponse,
        GetJobsApiV1JobsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/jobs`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['jobs'],
      }),
      getJobApiV1JobsJobIdGet: build.query<
        GetJobApiV1JobsJobIdGetApiResponse,
        GetJobApiV1JobsJobIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/jobs/${queryArg.jobId}` }),
        providesTags: ['jobs'],
      }),
      acceptJobApiV1JobsJobIdAcceptPut: build.mutation<
        AcceptJobApiV1JobsJobIdAcceptPutApiResponse,
        AcceptJobApiV1JobsJobIdAcceptPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/jobs/${queryArg.jobId}/accept`,
          method: 'PUT',
        }),
        invalidatesTags: ['jobs'],
      }),
      moveJobOfflineApiV1JobsJobIdOfflinePut: build.mutation<
        MoveJobOfflineApiV1JobsJobIdOfflinePutApiResponse,
        MoveJobOfflineApiV1JobsJobIdOfflinePutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/jobs/${queryArg.jobId}/offline`,
          method: 'PUT',
        }),
        invalidatesTags: ['jobs'],
      }),
      downloadJobApiV1JobsJobIdUploadGet: build.query<
        DownloadJobApiV1JobsJobIdUploadGetApiResponse,
        DownloadJobApiV1JobsJobIdUploadGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/jobs/${queryArg.jobId}/upload` }),
        providesTags: ['jobs'],
      }),
      getMaterialsApiV1MaterialsGet: build.query<
        GetMaterialsApiV1MaterialsGetApiResponse,
        GetMaterialsApiV1MaterialsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/materials`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['materials'],
      }),
      getMaterialApiV1MaterialsMaterialIdGet: build.query<
        GetMaterialApiV1MaterialsMaterialIdGetApiResponse,
        GetMaterialApiV1MaterialsMaterialIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/materials/${queryArg.materialId}`,
        }),
        providesTags: ['materials'],
      }),
      getMaterialHistoryApiV1MaterialsMaterialIdHistoryGet: build.query<
        GetMaterialHistoryApiV1MaterialsMaterialIdHistoryGetApiResponse,
        GetMaterialHistoryApiV1MaterialsMaterialIdHistoryGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/materials/${queryArg.materialId}/history`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['materials'],
      }),
      getMaterialIniApiV1MaterialsMaterialIdIniGet: build.query<
        GetMaterialIniApiV1MaterialsMaterialIdIniGetApiResponse,
        GetMaterialIniApiV1MaterialsMaterialIdIniGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/materials/${queryArg.materialId}/ini`,
        }),
        providesTags: ['materials'],
      }),
      getOrganisationsApiV1OrganisationsGet: build.query<
        GetOrganisationsApiV1OrganisationsGetApiResponse,
        GetOrganisationsApiV1OrganisationsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organisations`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['organisations'],
      }),
      createOrganisationApiV1OrganisationsPost: build.mutation<
        CreateOrganisationApiV1OrganisationsPostApiResponse,
        CreateOrganisationApiV1OrganisationsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organisations`,
          method: 'POST',
          body: queryArg.organisationCreateRequestDto,
        }),
        invalidatesTags: ['organisations'],
      }),
      getOrganisationApiV1OrganisationsOrgIdGet: build.query<
        GetOrganisationApiV1OrganisationsOrgIdGetApiResponse,
        GetOrganisationApiV1OrganisationsOrgIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organisations/${queryArg.orgId}`,
        }),
        providesTags: ['organisations'],
      }),
      updateOrganisationApiV1OrganisationsOrgIdPut: build.mutation<
        UpdateOrganisationApiV1OrganisationsOrgIdPutApiResponse,
        UpdateOrganisationApiV1OrganisationsOrgIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organisations/${queryArg.orgId}`,
          method: 'PUT',
          body: queryArg.organisationUpdateDto,
        }),
        invalidatesTags: ['organisations'],
      }),
      getOrganisationUsersApiV1OrganisationsOrgIdUsersGet: build.query<
        GetOrganisationUsersApiV1OrganisationsOrgIdUsersGetApiResponse,
        GetOrganisationUsersApiV1OrganisationsOrgIdUsersGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organisations/${queryArg.orgId}/users`,
          params: { page: queryArg.page, per_page: queryArg.perPage },
        }),
        providesTags: ['organisations'],
      }),
      getPartsApiV1PartsGet: build.query<
        GetPartsApiV1PartsGetApiResponse,
        GetPartsApiV1PartsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['parts'],
      }),
      createPartApiV1PartsPost: build.mutation<
        CreatePartApiV1PartsPostApiResponse,
        CreatePartApiV1PartsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts`,
          method: 'POST',
          body: queryArg.partCreateRequest,
        }),
        invalidatesTags: ['parts', '3rd-party'],
      }),
      getPartApiV1PartsPartIdGet: build.query<
        GetPartApiV1PartsPartIdGetApiResponse,
        GetPartApiV1PartsPartIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/parts/${queryArg.partId}` }),
        providesTags: ['parts'],
      }),
      updatePartApiV1PartsPartIdPut: build.mutation<
        UpdatePartApiV1PartsPartIdPutApiResponse,
        UpdatePartApiV1PartsPartIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}`,
          method: 'PUT',
          body: queryArg.partUpdateRequest,
        }),
        invalidatesTags: ['parts'],
      }),
      undoAcceptPartApiV1PartsPartIdAcceptDelete: build.mutation<
        UndoAcceptPartApiV1PartsPartIdAcceptDeleteApiResponse,
        UndoAcceptPartApiV1PartsPartIdAcceptDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/accept`,
          method: 'DELETE',
        }),
        invalidatesTags: ['parts'],
      }),
      acceptPartApiV1PartsPartIdAcceptPut: build.mutation<
        AcceptPartApiV1PartsPartIdAcceptPutApiResponse,
        AcceptPartApiV1PartsPartIdAcceptPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/accept`,
          method: 'PUT',
        }),
        invalidatesTags: ['parts'],
      }),
      confirmPartApiV1PartsPartIdConfirmPut: build.mutation<
        ConfirmPartApiV1PartsPartIdConfirmPutApiResponse,
        ConfirmPartApiV1PartsPartIdConfirmPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/confirm`,
          method: 'PUT',
        }),
        invalidatesTags: ['parts'],
      }),
      undoRejectPartApiV1PartsPartIdRejectDelete: build.mutation<
        UndoRejectPartApiV1PartsPartIdRejectDeleteApiResponse,
        UndoRejectPartApiV1PartsPartIdRejectDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/reject`,
          method: 'DELETE',
        }),
        invalidatesTags: ['parts'],
      }),
      rejectPartApiV1PartsPartIdRejectPut: build.mutation<
        RejectPartApiV1PartsPartIdRejectPutApiResponse,
        RejectPartApiV1PartsPartIdRejectPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/reject`,
          method: 'PUT',
        }),
        invalidatesTags: ['parts'],
      }),
      retryUploadPartApiV1PartsPartIdUploadPut: build.mutation<
        RetryUploadPartApiV1PartsPartIdUploadPutApiResponse,
        RetryUploadPartApiV1PartsPartIdUploadPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/upload`,
          method: 'PUT',
        }),
        invalidatesTags: ['parts'],
      }),
      cancelPartApiV1PartsPartIdUploadedDelete: build.mutation<
        CancelPartApiV1PartsPartIdUploadedDeleteApiResponse,
        CancelPartApiV1PartsPartIdUploadedDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/uploaded`,
          method: 'DELETE',
        }),
        invalidatesTags: ['parts'],
      }),
      downloadPartApiV1PartsPartIdUploadedGet: build.query<
        DownloadPartApiV1PartsPartIdUploadedGetApiResponse,
        DownloadPartApiV1PartsPartIdUploadedGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/uploaded`,
        }),
        providesTags: ['parts'],
      }),
      markPartUploadedApiV1PartsPartIdUploadedPut: build.mutation<
        MarkPartUploadedApiV1PartsPartIdUploadedPutApiResponse,
        MarkPartUploadedApiV1PartsPartIdUploadedPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/uploaded`,
          method: 'PUT',
        }),
        invalidatesTags: ['parts', '3rd-party'],
      }),
      signContextApiV1SignContextPatch: build.mutation<
        SignContextApiV1SignContextPatchApiResponse,
        SignContextApiV1SignContextPatchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/sign/context`,
          method: 'PATCH',
          body: queryArg.contextRequest,
        }),
        invalidatesTags: ['misc'],
      }),
      getTemplatesApiV1TemplatesGet: build.query<
        GetTemplatesApiV1TemplatesGetApiResponse,
        GetTemplatesApiV1TemplatesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/templates`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['templates'],
      }),
      createTemplateApiV1TemplatesPost: build.mutation<
        CreateTemplateApiV1TemplatesPostApiResponse,
        CreateTemplateApiV1TemplatesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/templates`,
          method: 'POST',
          body: queryArg.templateCreatePublic,
        }),
        invalidatesTags: ['templates'],
      }),
      deleteTemplateApiV1TemplatesTemplateIdDelete: build.mutation<
        DeleteTemplateApiV1TemplatesTemplateIdDeleteApiResponse,
        DeleteTemplateApiV1TemplatesTemplateIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/templates/${queryArg.templateId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['templates'],
      }),
      getTemplateApiV1TemplatesTemplateIdGet: build.query<
        GetTemplateApiV1TemplatesTemplateIdGetApiResponse,
        GetTemplateApiV1TemplatesTemplateIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/templates/${queryArg.templateId}`,
        }),
        providesTags: ['templates'],
      }),
      updateTemplateApiV1TemplatesTemplateIdPost: build.mutation<
        UpdateTemplateApiV1TemplatesTemplateIdPostApiResponse,
        UpdateTemplateApiV1TemplatesTemplateIdPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/templates/${queryArg.templateId}`,
          method: 'POST',
          body: queryArg.templateUpdatePublic,
        }),
        invalidatesTags: ['templates'],
      }),
      getTemplateHistoryApiV1TemplatesTemplateIdHistoryGet: build.query<
        GetTemplateHistoryApiV1TemplatesTemplateIdHistoryGetApiResponse,
        GetTemplateHistoryApiV1TemplatesTemplateIdHistoryGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/templates/${queryArg.templateId}/history`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['templates'],
      }),
      createUsersApiV1UsersPost: build.mutation<
        CreateUsersApiV1UsersPostApiResponse,
        CreateUsersApiV1UsersPostApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users`,
          method: 'POST',
          body: queryArg.createUserRequest,
        }),
        invalidatesTags: ['users'],
      }),
      getRolesApiV1UsersRolesGet: build.query<
        GetRolesApiV1UsersRolesGetApiResponse,
        GetRolesApiV1UsersRolesGetApiArg
      >({
        query: () => ({ url: `/api/v1/users/roles` }),
        providesTags: ['users'],
      }),
      searchUsersApiV1UsersSearchGet: build.query<
        SearchUsersApiV1UsersSearchGetApiResponse,
        SearchUsersApiV1UsersSearchGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/search`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        providesTags: ['users'],
      }),
      deleteUserApiV1UsersUserIdDelete: build.mutation<
        DeleteUserApiV1UsersUserIdDeleteApiResponse,
        DeleteUserApiV1UsersUserIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['users'],
      }),
      searchUserApiV1UsersUserIdGet: build.query<
        SearchUserApiV1UsersUserIdGetApiResponse,
        SearchUserApiV1UsersUserIdGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/users/${queryArg.userId}` }),
        providesTags: ['users'],
      }),
      updateUserApiV1UsersUserIdPatch: build.mutation<
        UpdateUserApiV1UsersUserIdPatchApiResponse,
        UpdateUserApiV1UsersUserIdPatchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}`,
          method: 'PATCH',
          body: queryArg.updateUserRequest,
        }),
        invalidatesTags: ['users'],
      }),
      getBlockUserApiV1UsersUserIdBlockGet: build.query<
        GetBlockUserApiV1UsersUserIdBlockGetApiResponse,
        GetBlockUserApiV1UsersUserIdBlockGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}/block`,
        }),
        providesTags: ['users'],
      }),
      updateBlockUserApiV1UsersUserIdBlockPut: build.mutation<
        UpdateBlockUserApiV1UsersUserIdBlockPutApiResponse,
        UpdateBlockUserApiV1UsersUserIdBlockPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}/block`,
          method: 'PUT',
          body: queryArg.blockUserModel,
        }),
        invalidatesTags: ['users'],
      }),
      removeBfpUserApiV1UsersUserIdBlockBfpDelete: build.mutation<
        RemoveBfpUserApiV1UsersUserIdBlockBfpDeleteApiResponse,
        RemoveBfpUserApiV1UsersUserIdBlockBfpDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}/block/bfp`,
          method: 'DELETE',
        }),
        invalidatesTags: ['users'],
      }),
      getBfpUserApiV1UsersUserIdBlockBfpGet: build.query<
        GetBfpUserApiV1UsersUserIdBlockBfpGetApiResponse,
        GetBfpUserApiV1UsersUserIdBlockBfpGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}/block/bfp`,
        }),
        providesTags: ['users'],
      }),
      resetUserApiV1UsersUserIdResetPut: build.mutation<
        ResetUserApiV1UsersUserIdResetPutApiResponse,
        ResetUserApiV1UsersUserIdResetPutApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}/reset`,
          method: 'PUT',
        }),
        invalidatesTags: ['users'],
      }),
      getUserRolesApiV1UsersUserIdRolesGet: build.query<
        GetUserRolesApiV1UsersUserIdRolesGetApiResponse,
        GetUserRolesApiV1UsersUserIdRolesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}/roles`,
        }),
        providesTags: ['users'],
      }),
      removeRoleApiV1UsersUserIdRolesRoleDelete: build.mutation<
        RemoveRoleApiV1UsersUserIdRolesRoleDeleteApiResponse,
        RemoveRoleApiV1UsersUserIdRolesRoleDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}/roles/${queryArg.role}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['users'],
      }),
      addRoleApiV1UsersUserIdRolesRolePatch: build.mutation<
        AddRoleApiV1UsersUserIdRolesRolePatchApiResponse,
        AddRoleApiV1UsersUserIdRolesRolePatchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/users/${queryArg.userId}/roles/${queryArg.role}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['users'],
      }),
      versionApiV1VersionGet: build.query<
        VersionApiV1VersionGetApiResponse,
        VersionApiV1VersionGetApiArg
      >({
        query: () => ({ url: `/api/v1/version` }),
        providesTags: ['misc'],
      }),
      catchAllApiV1FullPathOptions: build.mutation<
        CatchAllApiV1FullPathOptionsApiResponse,
        CatchAllApiV1FullPathOptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/${queryArg.fullPath}`,
          method: 'OPTIONS',
        }),
        invalidatesTags: ['application'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as baseClientApi }
export type CreateAddressApiV1AddressesPostApiResponse =
  /** status 201 Successful Response */ AddressReadDto
export type CreateAddressApiV1AddressesPostApiArg = {
  addressCreateDto: AddressCreateDto
}
export type DeleteAddressApiV1AddressesAddressIdDeleteApiResponse =
  /** status 204 Successful Response */ void
export type DeleteAddressApiV1AddressesAddressIdDeleteApiArg = {
  /** The ID of the address to get */
  addressId: number
}
export type GetAddressApiV1AddressesAddressIdGetApiResponse =
  /** status 200 Successful Response */ AddressReadDto
export type GetAddressApiV1AddressesAddressIdGetApiArg = {
  /** The ID of the address to get */
  addressId: number
}
export type UpdateAddressApiV1AddressesAddressIdPutApiResponse =
  /** status 200 Successful Response */ AddressReadDto
export type UpdateAddressApiV1AddressesAddressIdPutApiArg = {
  /** The ID of the address to get */
  addressId: number
  addressUpdateDto: AddressUpdateDto
}
export type GetDevicesApiV1DevicesGetApiResponse =
  /** status 200 Successful Response */ DevicesPaginatedListRead
export type GetDevicesApiV1DevicesGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: attributes.active, attributes.available_in_funnel, thing_name. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: attributes.active, attributes.available_in_funnel, thing_name. */
  sorting?: string | null
}
export type EnrolDeviceApiV1DevicesEnrolPostApiResponse =
  /** status 201 Successful Response */ DeviceEnrolmentResponse
export type EnrolDeviceApiV1DevicesEnrolPostApiArg = {
  deviceEnrolmentRequest: DeviceEnrolmentRequest
}
export type GetDevicesWithStatusApiV1DevicesStatusGetApiResponse =
  /** status 200 Successful Response */ DeviceStatusPaginatedListRead
export type GetDevicesWithStatusApiV1DevicesStatusGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: status.layer_count, status.current_layer, thing_name, attributes.available_in_funnel, status.elapsed, status.connected, status.current_layer_height, status.state, status.serial_number, status.model, status.firmware_version, status.next_build_name, status.current_build_name, status.queued_builds_count, status.paused_by_user, status.name, status.remaining, status.manufacturer, attributes.active. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: status.layer_count, status.current_layer, thing_name, attributes.available_in_funnel, status.elapsed, status.connected, status.current_layer_height, status.state, status.serial_number, status.model, status.firmware_version, status.next_build_name, status.current_build_name, status.queued_builds_count, status.paused_by_user, status.name, status.remaining, status.manufacturer, attributes.active. */
  sorting?: string | null
}
export type RemoveDeviceApiV1DevicesSerialNumberDeleteApiResponse =
  /** status 204 Successful Response */ void
export type RemoveDeviceApiV1DevicesSerialNumberDeleteApiArg = {
  /** The serial number of the device. */
  serialNumber: string
}
export type GetDeviceApiV1DevicesSerialNumberGetApiResponse =
  /** status 200 Successful Response */ DeviceRead
export type GetDeviceApiV1DevicesSerialNumberGetApiArg = {
  /** The serial number of the device. */
  serialNumber: string
}
export type GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiResponse =
  /** status 200 Successful Response */ DeviceStatusRead
export type GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiArg = {
  /** The serial number of the device. */
  serialNumber: string
}
export type GetIntegrationsApiV1IntegrationsGetApiResponse =
  /** status 200 Successful Response */ PaginatedIntegrationReadPublic
export type GetIntegrationsApiV1IntegrationsGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: name, description, client_id. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: name, client_id. */
  sorting?: string | null
}
export type GetAllIntegrationLinksApiV1IntegrationsLinksGetApiResponse =
  /** status 200 Successful Response */ PaginatedIntegrationLinkReadPublic
export type GetAllIntegrationLinksApiV1IntegrationsLinksGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: name, description, client_id. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: name, client_id. */
  sorting?: string | null
}
export type GetIntegrationApiV1IntegrationsIntegrationIdGetApiResponse =
  /** status 200 Successful Response */ IntegrationReadPublic
export type GetIntegrationApiV1IntegrationsIntegrationIdGetApiArg = {
  /** The ID of the integration to get */
  integrationId: string
}
export type DisconnectIntegrationApiV1IntegrationsIntegrationIdLinkDeleteApiResponse =
  /** status 204 Successful Response */ void
export type DisconnectIntegrationApiV1IntegrationsIntegrationIdLinkDeleteApiArg =
  {
    /** The ID of the integration to get */
    integrationId: string
  }
export type GetIntegrationLinkApiV1IntegrationsIntegrationIdLinkGetApiResponse =
  /** status 200 Successful Response */ LinkReadPublic
export type GetIntegrationLinkApiV1IntegrationsIntegrationIdLinkGetApiArg = {
  /** The ID of the integration to get */
  integrationId: string
}
export type ConnectIntegrationApiV1IntegrationsIntegrationIdLinkPutApiResponse =
  /** status 200 Successful Response */ ConnectIntegrationResponse
export type ConnectIntegrationApiV1IntegrationsIntegrationIdLinkPutApiArg = {
  /** The ID of the integration to get */
  integrationId: string
}
export type GetJobsApiV1JobsGetApiResponse =
  /** status 200 Successful Response */ PaginatedJobReadPublic
export type GetJobsApiV1JobsGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: status, execution_log_id, organisation_id, id, sliced_build_object_key, material.name, printer_serial. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: id, status, material.name, printer_serial. */
  sorting?: string | null
}
export type GetJobApiV1JobsJobIdGetApiResponse =
  /** status 200 Successful Response */ JobReadPublic
export type GetJobApiV1JobsJobIdGetApiArg = {
  /** The ID of the job to get */
  jobId: number
}
export type AcceptJobApiV1JobsJobIdAcceptPutApiResponse =
  /** status 204 Successful Response */ void
export type AcceptJobApiV1JobsJobIdAcceptPutApiArg = {
  /** The ID of the job to get */
  jobId: number
}
export type MoveJobOfflineApiV1JobsJobIdOfflinePutApiResponse =
  /** status 200 Successful Response */ JobDownloadResponse
export type MoveJobOfflineApiV1JobsJobIdOfflinePutApiArg = {
  /** The ID of the job to get */
  jobId: number
}
export type DownloadJobApiV1JobsJobIdUploadGetApiResponse =
  /** status 200 Successful Response */ JobDownloadResponse
export type DownloadJobApiV1JobsJobIdUploadGetApiArg = {
  /** The ID of the job to get */
  jobId: number
}
export type GetMaterialsApiV1MaterialsGetApiResponse =
  /** status 200 Successful Response */ PaginatedMaterialReadPublic
export type GetMaterialsApiV1MaterialsGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: next_revision_id, organisation_id, name, deleted_at, id, base_id, visibility. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: organisation_id, name, deleted_at, id, revision_number, visibility. */
  sorting?: string | null
}
export type GetMaterialApiV1MaterialsMaterialIdGetApiResponse =
  /** status 200 Successful Response */ MaterialReadPublic
export type GetMaterialApiV1MaterialsMaterialIdGetApiArg = {
  /** The ID of the material to get */
  materialId: number
}
export type GetMaterialHistoryApiV1MaterialsMaterialIdHistoryGetApiResponse =
  /** status 200 Successful Response */ PaginatedMaterialReadPublicHistory
export type GetMaterialHistoryApiV1MaterialsMaterialIdHistoryGetApiArg = {
  /** The ID of the material to get */
  materialId: number
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: next_revision_id, organisation_id, name, deleted_at, id, base_id, visibility. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: organisation_id, name, deleted_at, id, revision_number, visibility. */
  sorting?: string | null
}
export type GetMaterialIniApiV1MaterialsMaterialIdIniGetApiResponse =
  /** status 200 Successful Response */ MaterialIni
export type GetMaterialIniApiV1MaterialsMaterialIdIniGetApiArg = {
  /** The ID of the material to get */
  materialId: number
}
export type GetOrganisationsApiV1OrganisationsGetApiResponse =
  /** status 200 Successful Response */ OrganisationPaginatedListRead
export type GetOrganisationsApiV1OrganisationsGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: id, name, addresses.country. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: id, created_at, name. */
  sorting?: string | null
}
export type CreateOrganisationApiV1OrganisationsPostApiResponse =
  /** status 201 Successful Response */ OrganisationReadDto
export type CreateOrganisationApiV1OrganisationsPostApiArg = {
  organisationCreateRequestDto: OrganisationCreateRequestDto
}
export type GetOrganisationApiV1OrganisationsOrgIdGetApiResponse =
  /** status 200 Successful Response */ OrganisationReadDto
export type GetOrganisationApiV1OrganisationsOrgIdGetApiArg = {
  /** The ID of the organisation to get */
  orgId: number
}
export type UpdateOrganisationApiV1OrganisationsOrgIdPutApiResponse =
  /** status 200 Successful Response */ OrganisationReadDto
export type UpdateOrganisationApiV1OrganisationsOrgIdPutApiArg = {
  /** The ID of the organisation to update */
  orgId: number
  organisationUpdateDto: OrganisationUpdateDto
}
export type GetOrganisationUsersApiV1OrganisationsOrgIdUsersGetApiResponse =
  /** status 200 Successful Response */ UsersPaginatedListRead
export type GetOrganisationUsersApiV1OrganisationsOrgIdUsersGetApiArg = {
  /** The ID of the organisation to get users for */
  orgId: number
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
}
export type GetPartsApiV1PartsGetApiResponse =
  /** status 200 Successful Response */ PaginatedPartReadPublic
export type GetPartsApiV1PartsGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: status, execution_log_id, template.material_id, name, template_id, classification, organisation_id, placement_visibility_timeout_end, job_id, id. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: id, template_id, name. */
  sorting?: string | null
}
export type CreatePartApiV1PartsPostApiResponse =
  /** status 200 Successful Response */ PartCreatePublicResponse
export type CreatePartApiV1PartsPostApiArg = {
  partCreateRequest: PartCreateRequest
}
export type GetPartApiV1PartsPartIdGetApiResponse =
  /** status 200 Successful Response */ PartReadPublic
export type GetPartApiV1PartsPartIdGetApiArg = {
  /** The ID of the part to get */
  partId: number
}
export type UpdatePartApiV1PartsPartIdPutApiResponse =
  /** status 200 Successful Response */ PartReadPublic
export type UpdatePartApiV1PartsPartIdPutApiArg = {
  /** The ID of the part to update */
  partId: number
  partUpdateRequest: PartUpdateRequest
}
export type UndoAcceptPartApiV1PartsPartIdAcceptDeleteApiResponse =
  /** status 204 Successful Response */ void
export type UndoAcceptPartApiV1PartsPartIdAcceptDeleteApiArg = {
  /** The ID of the part to update */
  partId: number
}
export type AcceptPartApiV1PartsPartIdAcceptPutApiResponse =
  /** status 200 Successful Response */ PartReadPublic
export type AcceptPartApiV1PartsPartIdAcceptPutApiArg = {
  /** The ID of the part to update */
  partId: number
}
export type ConfirmPartApiV1PartsPartIdConfirmPutApiResponse =
  /** status 200 Successful Response */ PartReadPublic
export type ConfirmPartApiV1PartsPartIdConfirmPutApiArg = {
  /** The ID of the part to update */
  partId: number
}
export type UndoRejectPartApiV1PartsPartIdRejectDeleteApiResponse =
  /** status 204 Successful Response */ void
export type UndoRejectPartApiV1PartsPartIdRejectDeleteApiArg = {
  /** The ID of the part to update */
  partId: number
}
export type RejectPartApiV1PartsPartIdRejectPutApiResponse =
  /** status 200 Successful Response */ PartReadPublic
export type RejectPartApiV1PartsPartIdRejectPutApiArg = {
  /** The ID of the part to update */
  partId: number
}
export type RetryUploadPartApiV1PartsPartIdUploadPutApiResponse =
  /** status 200 Successful Response */ PartRetryUpload
export type RetryUploadPartApiV1PartsPartIdUploadPutApiArg = {
  /** The ID of the part to retry uploading */
  partId: number
}
export type CancelPartApiV1PartsPartIdUploadedDeleteApiResponse =
  /** status 204 Successful Response */ void
export type CancelPartApiV1PartsPartIdUploadedDeleteApiArg = {
  /** The ID of the part to cancel */
  partId: number
}
export type DownloadPartApiV1PartsPartIdUploadedGetApiResponse =
  /** status 200 Successful Response */ PartDownload
export type DownloadPartApiV1PartsPartIdUploadedGetApiArg = {
  /** The ID of the part to download */
  partId: number
}
export type MarkPartUploadedApiV1PartsPartIdUploadedPutApiResponse =
  /** status 200 Successful Response */ PartReadPublic
export type MarkPartUploadedApiV1PartsPartIdUploadedPutApiArg = {
  /** The ID of the part to update */
  partId: number
}
export type SignContextApiV1SignContextPatchApiResponse =
  /** status 200 Successful Response */ ContextResponse
export type SignContextApiV1SignContextPatchApiArg = {
  contextRequest: ContextRequest
}
export type GetTemplatesApiV1TemplatesGetApiResponse =
  /** status 200 Successful Response */ PaginatedTemplateReadPublic
export type GetTemplatesApiV1TemplatesGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: next_revision_id, organisation_id, created_at, name, classification, visibility, id, deleted_at, base_id. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: organisation_id, created_at, deleted_at, name, id. */
  sorting?: string | null
}
export type CreateTemplateApiV1TemplatesPostApiResponse =
  /** status 200 Successful Response */ TemplateReadPublic
export type CreateTemplateApiV1TemplatesPostApiArg = {
  templateCreatePublic: TemplateCreatePublic
}
export type DeleteTemplateApiV1TemplatesTemplateIdDeleteApiResponse =
  /** status 204 Successful Response */ void
export type DeleteTemplateApiV1TemplatesTemplateIdDeleteApiArg = {
  /** The ID of the template to update */
  templateId: number
}
export type GetTemplateApiV1TemplatesTemplateIdGetApiResponse =
  /** status 200 Successful Response */ TemplateReadPublic
export type GetTemplateApiV1TemplatesTemplateIdGetApiArg = {
  /** The ID of the template to get */
  templateId: number
}
export type UpdateTemplateApiV1TemplatesTemplateIdPostApiResponse =
  /** status 204 Successful Response */ void
export type UpdateTemplateApiV1TemplatesTemplateIdPostApiArg = {
  /** The ID of the template to update */
  templateId: number
  templateUpdatePublic: TemplateUpdatePublic
}
export type GetTemplateHistoryApiV1TemplatesTemplateIdHistoryGetApiResponse =
  /** status 200 Successful Response */ PaginatedTemplateReadPublicHistory
export type GetTemplateHistoryApiV1TemplatesTemplateIdHistoryGetApiArg = {
  /** The ID of the template to get */
  templateId: number
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: next_revision_id, organisation_id, created_at, name, classification, visibility, id, deleted_at, base_id. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: organisation_id, created_at, deleted_at, name, id. */
  sorting?: string | null
}
export type CreateUsersApiV1UsersPostApiResponse =
  /** status 201 Successful Response */ CreateUserResponse
export type CreateUsersApiV1UsersPostApiArg = {
  createUserRequest: CreateUserRequest
}
export type GetRolesApiV1UsersRolesGetApiResponse =
  /** status 200 Successful Response */ RolesResponse
export type GetRolesApiV1UsersRolesGetApiArg = void
export type SearchUsersApiV1UsersSearchGetApiResponse =
  /** status 200 Successful Response */ UsersPaginatedListRead
export type SearchUsersApiV1UsersSearchGetApiArg = {
  /** The page number. */
  page?: number | null
  /** The number of items per page. */
  perPage?: number | null
  /** This parameter expects a Lucene query string for filtering results. For more information on the syntax, please refer to the [Lucene Query Parser documentation](https://lucene.apache.org/core/2_9_4/queryparsersyntax.html). Note: fuzzy and proximity searches, boosting terms and field grouping are not supported. Wildcard matches using * is supported.You can filter by the following fields: name, email. */
  query?: string | null
  /** This parameter expects a sort query string for sorting results.  This should be a comma-separated list of {field_name}:{direction} pairs. The direction can be either '-1' or '1' for descending and ascending respectively. For example, 'name:1,created_at:-1'. You can sort by the following fields: name, email. */
  sorting?: string | null
}
export type DeleteUserApiV1UsersUserIdDeleteApiResponse =
  /** status 204 Successful Response */ void
export type DeleteUserApiV1UsersUserIdDeleteApiArg = {
  /** The id of the user */
  userId: string
}
export type SearchUserApiV1UsersUserIdGetApiResponse =
  /** status 200 Successful Response */ UserModel
export type SearchUserApiV1UsersUserIdGetApiArg = {
  /** The id of the user */
  userId: string
}
export type UpdateUserApiV1UsersUserIdPatchApiResponse =
  /** status 200 Successful Response */ UserModel
export type UpdateUserApiV1UsersUserIdPatchApiArg = {
  /** The id of the user */
  userId: string
  updateUserRequest: UpdateUserRequest
}
export type GetBlockUserApiV1UsersUserIdBlockGetApiResponse =
  /** status 200 Successful Response */ BlockUserModel
export type GetBlockUserApiV1UsersUserIdBlockGetApiArg = {
  /** The id of the user */
  userId: string
}
export type UpdateBlockUserApiV1UsersUserIdBlockPutApiResponse =
  /** status 204 Successful Response */ void
export type UpdateBlockUserApiV1UsersUserIdBlockPutApiArg = {
  /** The id of the user */
  userId: string
  blockUserModel: BlockUserModel
}
export type RemoveBfpUserApiV1UsersUserIdBlockBfpDeleteApiResponse =
  /** status 204 Successful Response */ void
export type RemoveBfpUserApiV1UsersUserIdBlockBfpDeleteApiArg = {
  /** The id of the user */
  userId: string
}
export type GetBfpUserApiV1UsersUserIdBlockBfpGetApiResponse =
  /** status 200 Successful Response */ BruteForceProtectionBlockModel[]
export type GetBfpUserApiV1UsersUserIdBlockBfpGetApiArg = {
  /** The id of the user */
  userId: string
}
export type ResetUserApiV1UsersUserIdResetPutApiResponse =
  /** status 204 Successful Response */ void
export type ResetUserApiV1UsersUserIdResetPutApiArg = {
  /** The id of the user */
  userId: string
}
export type GetUserRolesApiV1UsersUserIdRolesGetApiResponse =
  /** status 200 Successful Response */ RoleModel[]
export type GetUserRolesApiV1UsersUserIdRolesGetApiArg = {
  /** The id of the user */
  userId: string
}
export type RemoveRoleApiV1UsersUserIdRolesRoleDeleteApiResponse =
  /** status 204 Successful Response */ void
export type RemoveRoleApiV1UsersUserIdRolesRoleDeleteApiArg = {
  /** The id of the user */
  userId: string
  /** The role to remove from the user */
  role: string
}
export type AddRoleApiV1UsersUserIdRolesRolePatchApiResponse =
  /** status 204 Successful Response */ void
export type AddRoleApiV1UsersUserIdRolesRolePatchApiArg = {
  /** The id of the user */
  userId: string
  /** The role to add to the user */
  role: string
}
export type VersionApiV1VersionGetApiResponse =
  /** status 200 Successful Response */ VersionResponse
export type VersionApiV1VersionGetApiArg = void
export type CatchAllApiV1FullPathOptionsApiResponse =
  /** status 200 Successful Response */ any
export type CatchAllApiV1FullPathOptionsApiArg = {
  /** The full path being caught */
  fullPath: string
}
export type AddressReadDto = {
  id: number
  organisation_id: number
  street: string
  city: string
  state: string
  country: string
  postal_code: string
}
export type Error = {
  /** Error Translation Key */
  error_key?: string | null
  /** English Error Message */
  default_message?: string | null
  /** Additional information */
  details?: string | null
}
export type AddressCreateDto = {
  organisation_id: number
  street: string
  city: string
  state: string
  country: string
  postal_code: string
}
export type AddressUpdateDto = {
  street: string | null
  city: string | null
  state: string | null
  country: string | null
  postal_code: string | null
}
export type DeviceAttributes = {
  organisation_id: number
  active: boolean
  available_in_funnel: boolean
}
export type DeviceRead = {
  thing_name: string
  attributes: DeviceAttributes
}
export type DevicesPaginatedListRead = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: DeviceRead[]
}
export type DeviceEnrolmentResponse = {
  thing_name: string
  certificate: string
  broker_endpoint: string
  private_key: string
  organisation_name: string
  organisation_id: number
}
export type DeviceEnrolmentRequest = {
  thing_name: string
}
export type DlpPrinterStatus = {
  firmware_version?: string | null
  manufacturer?: string | null
  model?: string | null
  name?: string | null
  serial_number?: string | null
  state?: string | null
  paused_by_user?: boolean | null
  current_layer?: number | null
  current_layer_height?: number | null
  layer_count?: number | null
  elapsed?: number | null
  remaining?: number | null
  current_build_name?: string | null
  queued_build_names?: any | null
  queued_build_times?: any | null
  queued_builds_count?: number | null
  next_build_name?: string | null
  connected?: boolean | null
}
export type DeviceStatusRead = {
  thing_name: string
  attributes: DeviceAttributes
  status: DlpPrinterStatus | null
}
export type DeviceStatusPaginatedListRead = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: DeviceStatusRead[]
}
export type IntegrationReadPublic = {
  client_id: string
  name: string
  description: string
  logo_uri: string
  homepage: string
}
export type PaginatedIntegrationReadPublic = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: IntegrationReadPublic[]
}
export type LinkReadPublic = {
  id: number
  client_id: string
  linked_at: string
  linked_by: string
  last_used: string | null
}
export type PaginatedIntegrationLinkReadPublic = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: LinkReadPublic[]
}
export type ConnectIntegrationResponse = {
  authorize_url: string
}
export type TrayType =
  | (0 | 1 | 2 | 3 | 4 | 5)
  | (0 | 1 | 2 | 3 | 4 | 5)
  | (0 | 1 | 2 | 3 | 4 | 5)
  | (0 | 1 | 2 | 3 | 4 | 5)
  | (0 | 1 | 2 | 3 | 4 | 5)
  | (0 | 1 | 2 | 3 | 4 | 5)
export type JobStatus =
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11)
export type JobReadPublic = {
  id: number
  organisation_id: number
  material_id: number
  tray_type: TrayType
  layer_thickness: number
  status: JobStatus
  execution_log_id: number
  printer_serial: string
}
export type PaginatedJobReadPublic = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: JobReadPublic[]
}
export type JobDownloadResponse = {
  presigned_url: string
}
export type MaterialVisibility = (0 | 1 | 2) | (0 | 1 | 2) | (0 | 1 | 2)
export type MaterialReadPublic = {
  id: number
  name: string
  description: string
  organisation_id: number | null
  visibility: MaterialVisibility
  latest: boolean
}
export type PaginatedMaterialReadPublic = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: MaterialReadPublic[]
}
export type MaterialReadPublicHistory = {
  id: number
  name: string
  description: string
  organisation_id: number | null
  visibility: MaterialVisibility
  revision_number: number
}
export type PaginatedMaterialReadPublicHistory = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: MaterialReadPublicHistory[]
}
export type MaterialIni = {
  presigned_url: string
}
export type OrganisationIndustry =
  | 'Other'
  | 'Audiology'
  | 'Dental'
  | 'Jewelry'
  | 'Industrial'
  | 'Medical'
  | 'Creative'
  | 'Microfluidics'
export type OrganisationReadDto = {
  id: number
  name: string
  industry: OrganisationIndustry
}
export type OrganisationPaginatedListRead = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: OrganisationReadDto[]
}
export type OrganisationCreateRequestDto = {
  name: string
  industry: OrganisationIndustry
}
export type OrganisationUpdateDto = {
  name?: string | null
  industry?: OrganisationIndustry | null
}
export type UserReadDto = {
  user_id?: string | null
  email?: string | null
  email_verified?: boolean | null
  username?: string | null
  phone_number?: string | null
  phone_verified?: boolean | null
  created_at?: string | object | null
  updated_at?: string | object | null
  identities?: any | null
  user_metadata?: object | null
  app_metadata?: object | null
  picture?: string | null
  name?: string | null
  nickname?: string | null
  blocked?: boolean | null
  given_name?: string | null
  family_name?: string | null
}
export type UsersPaginatedListRead = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: UserReadDto[]
}
export type PartClassification =
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15)
export type PartStatus =
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
  | (0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14)
export type PartReadPublic = {
  id: number
  name: string
  notes: string | null
  source: string | null
  template_id: number | null
  classification: PartClassification
  status: PartStatus
  submitted_by: string
  organisation_id: number
  submitter_organisation_id: number
  created_at: string
  updated_at: string
  priority: number
  execution_log_id: number
  job_id: number | null
}
export type PaginatedPartReadPublic = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: PartReadPublic[]
}
export type PartCreatePublicResponse = {
  id: number
  name: string
  notes: string | null
  source: string | null
  template_id: number | null
  classification: PartClassification
  status: PartStatus
  submitted_by: string
  organisation_id: number
  submitter_organisation_id: number
  created_at: string
  updated_at: string
  priority: number
  execution_log_id: number
  job_id: number | null
  presigned_url: string
}
export type PartCreateRequest = {
  name: string
  notes?: string | null
  source?: string | null
  classification?: PartClassification | null
}
export type PartUpdateRequest = {
  classification?: PartClassification | null
}
export type PartRetryUpload = {
  presigned_url: string
}
export type PartDownload = {
  presigned_url: string
}
export type UserContext = {
  key: string
  email: string
  email_verified: boolean
  permissions: string[]
}
export type OrganisationContext = {
  key: string
  name: string
  industry: string
}
export type DeviceContext = {
  key: string
  name: string
  version: string
  os: string
  platform: string
  locales: string[]
}
export type Context = {
  kind?: 'multi'
  user: UserContext
  organisation: OrganisationContext
  device: DeviceContext
}
export type ContextResponse = {
  context: Context
  hash: string
}
export type ContextRequest = {
  device: DeviceContext
  id_token: string
}
export type TemplateVisibility = (0 | 1) | (0 | 1)
export type TemplateReadPublic = {
  id: number
  name: string
  material_id: number
  tray_type: TrayType
  layer_thickness_mm: number
  classification: PartClassification
  organisation_id: number | null
  visibility: TemplateVisibility
  created_by: string
  created_at: string
  latest: boolean
}
export type PaginatedTemplateReadPublic = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: TemplateReadPublic[]
}
export type TemplateCreatePublic = {
  name: string
  material_id: number
  tray_type: TrayType
  layer_thickness_mm: number
  classification: PartClassification
}
export type TemplateUpdatePublic = {
  name?: string | null
  material_id?: number | null
  tray_type?: TrayType | null
  layer_thickness_mm?: number | null
  classification?: PartClassification | null
}
export type TemplateReadPublicHistory = {
  id: number
  name: string
  material_id: number
  tray_type: TrayType
  layer_thickness_mm: number
  classification: PartClassification
  organisation_id: number | null
  visibility: TemplateVisibility
  created_by: string
  created_at: string
  revision_number: number
}
export type PaginatedTemplateReadPublicHistory = {
  page?: number | null
  page_size?: number | null
  count?: number | null
  total_count?: number | null
  content?: TemplateReadPublicHistory[]
}
export type CreateUserResponse = {
  user_id: string
}
export type CreateUserRequest = {
  email: string
  roles: string[]
  name?: string | null
}
export type RoleModel = {
  id: string
  name: string
}
export type RolesResponse = {
  roles: RoleModel[]
}
export type UserModel = {
  user_id?: string | null
  email?: string | null
  email_verified?: boolean | null
  username?: string | null
  phone_number?: string | null
  phone_verified?: boolean | null
  created_at?: string | object | null
  updated_at?: string | object | null
  identities?: any | null
  user_metadata?: object | null
  app_metadata?: object | null
  picture?: string | null
  name?: string | null
  nickname?: string | null
  multifactor?: any | null
  last_ip?: string | null
  last_login?: string | object | null
  logins_count?: number | null
  blocked?: boolean | null
  given_name?: string | null
  family_name?: string | null
}
export type UpdateUserRequest = {
  email?: string | null
  family_name?: string | null
  given_name?: string | null
  name?: string | null
  nickname?: string | null
  password?: string | null
}
export type BlockUserModel = {
  blocked?: boolean
}
export type BruteForceProtectionBlockModel = {
  identifier: string
  ip?: string | null
  connection?: string | null
}
export type VersionResponse = {
  version: string
}
export const {
  useCreateAddressApiV1AddressesPostMutation,
  useDeleteAddressApiV1AddressesAddressIdDeleteMutation,
  useGetAddressApiV1AddressesAddressIdGetQuery,
  useUpdateAddressApiV1AddressesAddressIdPutMutation,
  useGetDevicesApiV1DevicesGetQuery,
  useEnrolDeviceApiV1DevicesEnrolPostMutation,
  useGetDevicesWithStatusApiV1DevicesStatusGetQuery,
  useRemoveDeviceApiV1DevicesSerialNumberDeleteMutation,
  useGetDeviceApiV1DevicesSerialNumberGetQuery,
  useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery,
  useGetIntegrationsApiV1IntegrationsGetQuery,
  useGetAllIntegrationLinksApiV1IntegrationsLinksGetQuery,
  useGetIntegrationApiV1IntegrationsIntegrationIdGetQuery,
  useDisconnectIntegrationApiV1IntegrationsIntegrationIdLinkDeleteMutation,
  useGetIntegrationLinkApiV1IntegrationsIntegrationIdLinkGetQuery,
  useConnectIntegrationApiV1IntegrationsIntegrationIdLinkPutMutation,
  useGetJobsApiV1JobsGetQuery,
  useGetJobApiV1JobsJobIdGetQuery,
  useAcceptJobApiV1JobsJobIdAcceptPutMutation,
  useMoveJobOfflineApiV1JobsJobIdOfflinePutMutation,
  useDownloadJobApiV1JobsJobIdUploadGetQuery,
  useGetMaterialsApiV1MaterialsGetQuery,
  useGetMaterialApiV1MaterialsMaterialIdGetQuery,
  useGetMaterialHistoryApiV1MaterialsMaterialIdHistoryGetQuery,
  useGetMaterialIniApiV1MaterialsMaterialIdIniGetQuery,
  useGetOrganisationsApiV1OrganisationsGetQuery,
  useCreateOrganisationApiV1OrganisationsPostMutation,
  useGetOrganisationApiV1OrganisationsOrgIdGetQuery,
  useUpdateOrganisationApiV1OrganisationsOrgIdPutMutation,
  useGetOrganisationUsersApiV1OrganisationsOrgIdUsersGetQuery,
  useGetPartsApiV1PartsGetQuery,
  useCreatePartApiV1PartsPostMutation,
  useGetPartApiV1PartsPartIdGetQuery,
  useUpdatePartApiV1PartsPartIdPutMutation,
  useUndoAcceptPartApiV1PartsPartIdAcceptDeleteMutation,
  useAcceptPartApiV1PartsPartIdAcceptPutMutation,
  useConfirmPartApiV1PartsPartIdConfirmPutMutation,
  useUndoRejectPartApiV1PartsPartIdRejectDeleteMutation,
  useRejectPartApiV1PartsPartIdRejectPutMutation,
  useRetryUploadPartApiV1PartsPartIdUploadPutMutation,
  useCancelPartApiV1PartsPartIdUploadedDeleteMutation,
  useDownloadPartApiV1PartsPartIdUploadedGetQuery,
  useMarkPartUploadedApiV1PartsPartIdUploadedPutMutation,
  useSignContextApiV1SignContextPatchMutation,
  useGetTemplatesApiV1TemplatesGetQuery,
  useCreateTemplateApiV1TemplatesPostMutation,
  useDeleteTemplateApiV1TemplatesTemplateIdDeleteMutation,
  useGetTemplateApiV1TemplatesTemplateIdGetQuery,
  useUpdateTemplateApiV1TemplatesTemplateIdPostMutation,
  useGetTemplateHistoryApiV1TemplatesTemplateIdHistoryGetQuery,
  useCreateUsersApiV1UsersPostMutation,
  useGetRolesApiV1UsersRolesGetQuery,
  useSearchUsersApiV1UsersSearchGetQuery,
  useDeleteUserApiV1UsersUserIdDeleteMutation,
  useSearchUserApiV1UsersUserIdGetQuery,
  useUpdateUserApiV1UsersUserIdPatchMutation,
  useGetBlockUserApiV1UsersUserIdBlockGetQuery,
  useUpdateBlockUserApiV1UsersUserIdBlockPutMutation,
  useRemoveBfpUserApiV1UsersUserIdBlockBfpDeleteMutation,
  useGetBfpUserApiV1UsersUserIdBlockBfpGetQuery,
  useResetUserApiV1UsersUserIdResetPutMutation,
  useGetUserRolesApiV1UsersUserIdRolesGetQuery,
  useRemoveRoleApiV1UsersUserIdRolesRoleDeleteMutation,
  useAddRoleApiV1UsersUserIdRolesRolePatchMutation,
  useVersionApiV1VersionGetQuery,
  useCatchAllApiV1FullPathOptionsMutation,
} = injectedRtkApi
