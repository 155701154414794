import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface RightDrawerState {
  data: {
    [index: string]: DrawerData
  }
}

interface DrawerData {
  open?: boolean
}

const initialState: RightDrawerState = { data: {} } satisfies RightDrawerState
const rightDrawerSlice = createSlice({
  name: 'rightDrawers',
  initialState,
  reducers: {
    setDrawerOpen(
      state,
      action: PayloadAction<{ name: string; value: boolean }>,
    ) {
      if (state.data[action.payload.name] === undefined)
        state.data[action.payload.name] = { open: false }
      state.data[action.payload.name].open = action.payload.value
    },
  },
  selectors: {
    selectDrawerOpen: (state, drawer: string) =>
      state.data[drawer]?.open === true,
  },
})

export const { setDrawerOpen } = rightDrawerSlice.actions
export const { selectDrawerOpen } = rightDrawerSlice.selectors
export default rightDrawerSlice.reducer
