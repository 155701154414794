import { useAuth0 } from '@auth0/auth0-react'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useGetOrganisationApiV1OrganisationsOrgIdGetQuery } from '../store/clientApi'

const useOrg = () => {
  const { getAccessTokenSilently } = useAuth0()

  const [orgId, setOrgId] = useState<number | undefined>()

  useEffect(() => {
    const getOrg = async () => {
      const accessToken = await getAccessTokenSilently()

      const decoded = jwtDecode<
        JwtPayload & {
          permissions: string[]
          ['https://api.asiga.com/metadata']: { organisation: number }
        }
      >(accessToken)

      setOrgId(decoded?.['https://api.asiga.com/metadata']?.organisation)
    }

    getOrg()
  }, [getAccessTokenSilently])

  const {
    data: org,
    error: orgError,
    isLoading: orgIsLoading,
  } = useGetOrganisationApiV1OrganisationsOrgIdGetQuery(
    { orgId: orgId ?? -1 },
    {
      skip: orgId === undefined,
    },
  )

  return {
    orgId,
    org,
    error: orgId ? orgError : undefined,
    isLoading: orgId ? orgIsLoading : true,
  }
}

export { useOrg }
