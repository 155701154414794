import {
  CircularProgress,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Box } from '@mui/material'
import { t } from 'i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState, InboxBody, InboxInspector } from '../../components'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { DragAndDropUpload } from '../../components/DragAndDropUpload/DragAndDropUpload'
import { AllSvg, PrintedSvg, QueueListSvg } from '../../components/Icon/Icon'
import { PART_INSPECTOR_STATE } from '../../components/InboxInspector/InboxInspector'
import { PARTS_TABLE_STATE_NAME } from '../../components/PartsTable/PartsTable'
import { Tab, Tabs } from '../../components/Tabs/Tabs'
import { UnderConstruction } from '../../components/UnderConstruction/UnderConstruction'
import { partStatus } from '../../constants/partStatus'
import { clientApi, useGetPartsApiV1PartsGetQuery } from '../../store/clientApi'
import {
  selectDrawerOpen,
  setDrawerOpen,
} from '../../store/slices/rightDrawerSlice'
import {
  invalidate,
  selectNumChecked,
  selectQueryArgs,
  selectQueryIsSearching,
  selectRtkData,
  setPage,
  setRtkArgs,
  setSortField,
  setSortOrder,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import classes from './parts.module.css'

interface PartsProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

const PartData = ({ tab }: { tab: string }) => {
  const theme = useTheme()
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))

  const newNumSelected = useSelector((state: RootState) =>
    selectNumChecked(state, PARTS_TABLE_STATE_NAME),
  )
  const [numSelected, setNumSelected] = useState(0)

  useEffect(() => {
    if (newNumSelected > numSelected && newNumSelected === 1 && breakpointLg) {
      store.dispatch(setDrawerOpen({ name: PART_INSPECTOR_STATE, value: true }))
    }
    if (numSelected !== newNumSelected) {
      setNumSelected(newNumSelected)
    }
  }, [newNumSelected, numSelected, breakpointLg])

  const queryArgs = useSelector((state: RootState) =>
    selectQueryArgs(state, PARTS_TABLE_STATE_NAME),
  )

  const filterQueryParts = (tab: string, searchValue: string) => {
    /** Filter parts based on current tab and user query */
    const statusToFilter = []
    if (tab === 'AllParts') {
      // both print-ready and sent status parts
      statusToFilter.push(partStatus['accepted'])
      statusToFilter.push(partStatus['processing'])
      statusToFilter.push(partStatus['awaitingPlacement'])
      statusToFilter.push(partStatus['placing'])
      statusToFilter.push(partStatus['placed'])
      statusToFilter.push(partStatus['printing'])
      statusToFilter.push(partStatus['failed'])
      statusToFilter.push(partStatus['printed'])
      statusToFilter.push(partStatus['offline'])
    } else if (tab === 'PrintReady') {
      statusToFilter.push(partStatus['accepted'])
      statusToFilter.push(partStatus['processing'])
      statusToFilter.push(partStatus['awaitingPlacement'])
      statusToFilter.push(partStatus['placing'])
    } else if (tab === 'Sent') {
      statusToFilter.push(partStatus['placed'])
      statusToFilter.push(partStatus['printing'])
      statusToFilter.push(partStatus['failed'])
      statusToFilter.push(partStatus['printed'])
      statusToFilter.push(partStatus['offline'])
    }
    // Format as: "status: X OR status: Y OR status: Z ..."
    const statusQuery = 'status:' + statusToFilter.join(` OR status:`)

    // Append user query (name search) if present
    if (searchValue !== '') {
      return `((name:*${searchValue}*) AND (${statusQuery}))`
    }
    return statusQuery
  }

  const rtkArgs = useMemo(
    () => ({
      page: queryArgs.page !== 0 ? queryArgs.page - 1 : 0, // pages are zero indexed
      perPage: queryArgs.perPage,
      query: filterQueryParts(tab, queryArgs.searchValue),
      sorting:
        queryArgs.sortField !== ''
          ? `${queryArgs.sortField}:${queryArgs.sortOrder === 'asc' ? '1' : '-1'}`
          : undefined,
    }),
    [queryArgs, tab],
  )

  useEffect(() => {
    store.dispatch(setRtkArgs({ name: PARTS_TABLE_STATE_NAME, value: rtkArgs }))
  }, [rtkArgs])

  useGetPartsApiV1PartsGetQuery(rtkArgs, {})

  return <>{null}</>
}

const PartContentInner = ({ tab }: { tab: string | false }) => {
  const hasContent = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      PARTS_TABLE_STATE_NAME,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )
    const isSearching = selectQueryIsSearching(state, PARTS_TABLE_STATE_NAME)
    const totalCount = query?.data?.total_count ?? 0
    const count = query?.data?.content?.length ?? 0
    return (
      count > 0 ||
      totalCount > 0 ||
      isSearching ||
      (query?.isLoading && query?.data === undefined)
    )
  })

  const getEmptyState = () => {
    switch (tab) {
      case 'AllParts':
        return (
          <EmptyState
            image={'/images/no-parts-allparts-tab.svg'}
            title={t('parts:emptyPartsMessages.allParts.title')}
            message={t('parts:emptyPartsMessages.allParts.message')}
          />
        )
      case 'PrintReady':
        return (
          <EmptyState
            image={'/images/noPartsToReview.svg'}
            title={t('parts:emptyPartsMessages.printReady.title')}
            message={t('parts:emptyPartsMessages.printReady.message')}
          />
        )
      case 'Sent':
        return (
          <EmptyState
            image={'/images/noPartsToReview.svg'}
            title={t('parts:emptyPartsMessages.sent.title')}
            message={t('parts:emptyPartsMessages.sent.message')}
          />
        )
      default:
        return <EmptyState />
    }
  }

  return hasContent ? (
    <InboxBody table={PARTS_TABLE_STATE_NAME} actionBarType="parts" />
  ) : (
    getEmptyState()
  )
}

const PartContent = ({ tab }: { tab: string | false }) => {
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      PARTS_TABLE_STATE_NAME,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )

    return query?.isLoading && query?.isUninitialized
  })

  return isLoading ? (
    <>
      <LinearProgress />
    </>
  ) : (
    <PartContentInner tab={tab} />
  )
}

const Parts: FC<PartsProps> = (props) => {
  const theme = useTheme()
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))
  const { t } = useTranslation('parts')
  const { releasePartsList } = useFlags()
  const [isReleaseFlagTimedout, setIsReleaseFlagTimedout] =
    useState<boolean>(false)

  // States
  const [tab, setTab] = useState<string | false>('AllParts')
  const inspectorOpen = useSelector((state: RootState) =>
    selectDrawerOpen(state, PART_INSPECTOR_STATE),
  )

  useEffect(() => {
    store.dispatch(setPage({ name: PARTS_TABLE_STATE_NAME, value: 1 }))
    store.dispatch(setSortField({ name: PARTS_TABLE_STATE_NAME, value: 'id' }))
    store.dispatch(
      setSortOrder({ name: PARTS_TABLE_STATE_NAME, value: 'desc' }),
    )
  }, [tab])

  const invalidateTable = () =>
    store.dispatch(invalidate(PARTS_TABLE_STATE_NAME))

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if releasePartsList is undefined
    if (!releasePartsList) {
      timeoutId = setTimeout(() => {
        setIsReleaseFlagTimedout(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if releasePartsList becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [releasePartsList])

  const handleOnChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
    invalidateTable()
  }

  return (
    <>
      <PartData tab={tab === false ? '' : tab} />
      <Dashboard
        primaryLinks={props.primaryLinks}
        footerLinks={props.footerLinks}
      >
        {releasePartsList === true ? (
          <>
            <Box
              component="div"
              sx={{
                height: '100%',
                display: 'flex',
                gap: '1em',
                position: 'relative',
              }}
            >
              <DragAndDropUpload
                sx={{
                  display: !breakpointLg && inspectorOpen ? 'none' : undefined,
                }}
              >
                <Box
                  component="div"
                  className={classes.mainContainer}
                  sx={{
                    position: 'relative',
                    maxWidth: {
                      sm: 'calc(100vw - 8px)',
                      md: 'calc(100vw - 80px - 8px)',
                      lg: 'calc(100vw - 100px - 8px)',
                    },
                    maxHeight: '100%',
                    minHeight: '100%',
                    bgcolor: (theme) => theme.surface.low,
                  }}
                >
                  <Typography variant="h1">{t('title.parts')}</Typography>
                  <Tabs
                    tab={tab}
                    defaultTab="AllParts"
                    setTab={(tab) => setTab(tab)}
                    onChange={handleOnChangeTab}
                  >
                    <Tab
                      value={'AllParts'}
                      label={t('label.allParts')}
                      icon={<AllSvg color="inherit" />}
                      id={'allparts-tab-button'}
                    />
                    <Tab
                      value={'PrintReady'}
                      label={t('label.printReady')}
                      icon={<QueueListSvg color="inherit" />}
                      id={'printready-tab-button'}
                    />
                    <Tab
                      value={'Sent'}
                      label={t('label.sent')}
                      icon={<PrintedSvg color="inherit" />}
                      id={'sent-tab-button'}
                    />
                  </Tabs>
                  <PartContent tab={tab} />
                </Box>
              </DragAndDropUpload>
              <InboxInspector
                table={PARTS_TABLE_STATE_NAME}
                drawer={PART_INSPECTOR_STATE}
                currentTab={tab}
              />
            </Box>
          </>
        ) : releasePartsList === undefined && !isReleaseFlagTimedout ? (
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <UnderConstruction />
        )}
      </Dashboard>
    </>
  )
}

export { Parts }
