import { TabList as MuiTabList } from '@mui/lab'
import { TabListProps } from '@mui/lab/TabList'
import {
  Box,
  Divider,
  Icon,
  Tab as MuiTab,
  Tabs as MuiTabs,
  TabProps,
  TabsProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const outerTabs = (
  inner: () => React.ReactNode,
  buttons: () => React.ReactNode,
) => (
  <>
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
      }}
    >
      {inner()}
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '6px',
        }}
      >
        {buttons()}
      </Box>
    </Box>
    <Divider sx={{ marginBottom: '1em' }} flexItem />
  </>
)

export const Tab = ({
  ...props
}: TabProps & { icon: string | React.ReactElement }) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <MuiTab
      {...props}
      icon={
        <Icon
          component="div"
          sx={{
            '& > svg': { fill: 'currentColor' },
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {props.icon}
        </Icon>
      }
      sx={{
        textTransform: 'unset',
        '& span': {
          marginRight: ['2px!important', '2px!important', '8px!important'],
        },
        padding: ['2px', '4px', '12px'],
        ...props.sx,
      }}
      {...(mdDown || props.label == null
        ? { iconPosition: undefined, label: undefined }
        : {
            iconPosition: 'start', // eslint-disable-line i18next/no-literal-string
            label: props.label,
          })}
    />
  )
}

export const Tabs = ({
  tab,
  setTab,
  defaultTab,
  children,
  buttons,
  ...props
}: {
  tab: string | false
  setTab: (tab: string | false) => void
  defaultTab: string
  children: React.ReactNode
  buttons?: React.ReactNode
} & TabsProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (!tab) setTab(defaultTab)
  }, [tab]) // eslint-disable-line react-hooks/exhaustive-deps

  return outerTabs(
    () => (
      <MuiTabs
        aria-label={t('label.tabs')}
        {...props}
        value={tab}
        sx={{
          width: '100%',
          height: smDown ? '3em' : '3.8em',
          ...props.sx,
        }}
        onChange={(event: React.SyntheticEvent, newValue: string) =>
          setTab(newValue)
        }
      >
        {children}
      </MuiTabs>
    ),
    () => buttons,
  )
}

export const TabList = ({
  buttons,
  ...props
}: TabListProps & { buttons?: React.ReactNode }) => {
  return outerTabs(
    () => <MuiTabList {...props} />,
    () => buttons,
  )
}
