import React, { useEffect } from 'react'
import { useGetDevicesWithStatusApiV1DevicesStatusGetQuery } from '../../store/clientApi'
import { setRtkArgs } from '../../store/slices/tableSlice'
import { store } from '../../store/store'
import { TIMELINE_TABLE_STATE_NAME } from './Timeline'

export const TimelineData = () => {
  useEffect(() => {
    store.dispatch(
      setRtkArgs({
        name: TIMELINE_TABLE_STATE_NAME,
        value: { sorting: 'name:1' },
      }),
    )
  }, [])

  useGetDevicesWithStatusApiV1DevicesStatusGetQuery({ sorting: 'name:1' }, {})

  return <></>
}
