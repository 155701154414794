import {
  Avatar,
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { ensureError, localizeError } from '../../helpers'
import { useOrg } from '../../hooks/org'
import {
  clientApi,
  IntegrationReadPublic,
  LinkReadPublic,
  PaginatedIntegrationLinkReadPublic,
  useDisconnectIntegrationApiV1IntegrationsIntegrationIdLinkDeleteMutation,
  useGetAllIntegrationLinksApiV1IntegrationsLinksGetQuery,
  useGetIntegrationsApiV1IntegrationsGetQuery,
} from '../../store/clientApi'
import {
  invalidate,
  selectCheckedIds,
  selectNumChecked,
  selectQueryArgs,
  selectRtkData,
  setPerPage,
  setRtkArgs,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { ActionBar } from '../ActionBar/ActionBar'
import { Button } from '../Button/Button'
import { DialogBox } from '../DialogBox'
import { UnlinkSvg } from '../Icon/Icon'
import { ReduxPerPage } from '../PerPageSelector/PerPageSelector'
import { Column, DynamicTable } from '../Table/Table'

const APP_TABLE_STATE_NAME = 'app_table_state'
const EMPTY_INTEGRATION_ARRAY: IntegrationReadPublic[] = []

const invalidateTable = () => store.dispatch(invalidate(APP_TABLE_STATE_NAME))

const NoLinks = () => {
  const { t } = useTranslation('apps')

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'center',
        alignContent: 'center',
        overflow: 'auto',
        flexGrow: 1,
      }}
    >
      <Box component="div" sx={{ flexGrow: 1 }}></Box>
      <img
        alt={t('alt.noApps')}
        style={{ maxWidth: '240px' }}
        src="/images/no-links.svg"
      />
      <Typography textAlign={'center'} margin={'1em 0'} marginTop={'2.5em'}>
        {t('message.noApps')}
      </Typography>
      <Typography textAlign={'center'}>{t('message.noAppsInfo')}</Typography>

      <Button
        color="primary"
        href={'#Apps'}
        sx={{
          mt: '1.5em',
        }}
      >
        {t('button.apps')}
      </Button>
      <Box component="div" sx={{ flexGrow: 1 }}></Box>
    </Box>
  )
}

const List = () => {
  const { t } = useTranslation('apps')
  const UNAVAILABLE = t('common:loading.unavailable')

  const NAME = t('title.name')
  const LINKED = t('title.linked')

  const columns: Column[] = [{ name: NAME }, { name: LINKED }]

  const selectLinkIds = useMemo(() => {
    const emptyArray: LinkReadPublic[] = []

    return createSelector(
      [
        (res?: PaginatedIntegrationLinkReadPublic) =>
          res?.content ?? emptyArray,
      ],
      (content) => content.map((link) => link.id.toString()),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const data = useSelector((state: RootState) =>
    selectLinkIds(
      selectRtkData(
        state,
        APP_TABLE_STATE_NAME,
        clientApi.endpoints.getAllIntegrationLinksApiV1IntegrationsLinksGet
          .select,
      )?.data,
    ),
  )
  const totalCount = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      APP_TABLE_STATE_NAME,
      clientApi.endpoints.getAllIntegrationLinksApiV1IntegrationsLinksGet
        .select,
    )
    return query?.data?.total_count ?? query?.data?.content?.length
  })
  const error = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        APP_TABLE_STATE_NAME,
        clientApi.endpoints.getAllIntegrationLinksApiV1IntegrationsLinksGet
          .select,
      )?.error,
  )
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      APP_TABLE_STATE_NAME,
      clientApi.endpoints.getAllIntegrationLinksApiV1IntegrationsLinksGet
        .select,
    )

    return query?.isLoading && query?.isUninitialized
  })

  const EntityCell = ({
    linkId,
    column,
    index,
  }: {
    linkId: string
    column: Column
    index: number
  }) => {
    const { i18n } = useTranslation()

    const link = useSelector((state: RootState) =>
      selectRtkData(
        state,
        APP_TABLE_STATE_NAME,
        clientApi.endpoints.getAllIntegrationLinksApiV1IntegrationsLinksGet
          .select,
      )?.data?.content?.find((link) => link.id.toString() === linkId),
    )

    const { data, isLoading } = useGetIntegrationsApiV1IntegrationsGetQuery(
      {},
      {
        selectFromResult: ({ data, error, isLoading }) => ({
          data: (data?.content ?? EMPTY_INTEGRATION_ARRAY).find(
            (integration) => integration.client_id === link?.client_id,
          ),
          error,
          isLoading,
        }),
        skip: link === undefined,
      },
    )

    const dateFormatter = Intl.DateTimeFormat([...i18n.languages], {
      dateStyle: 'short',
    })
    return (
      <Box component="div">
        {column.name === NAME && (
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            {isLoading || link === undefined ? (
              <CircularProgress size="0.8em" />
            ) : (
              <>
                <Avatar
                  alt={data?.name ?? UNAVAILABLE}
                  variant="rounded"
                  src={data?.logo_uri}
                >
                  {UNAVAILABLE[0]}
                </Avatar>
                {data?.name ?? UNAVAILABLE}
              </>
            )}
          </Box>
        )}
        {column.name === LINKED &&
          (isLoading || link === undefined ? (
            <CircularProgress size="0.8em" />
          ) : (
            dateFormatter.format(new Date(link.linked_at))
          ))}
      </Box>
    )
  }

  return (
    <>
      {error ? (
        <Box component="div" sx={{ marginTop: '1em' }}>
          {localizeError(t, ensureError(error))}
        </Box>
      ) : isLoading || data === undefined ? (
        <Box component="div" sx={{ marginTop: '1em' }}>
          <LinearProgress />
        </Box>
      ) : (
        <DynamicTable
          stateName={APP_TABLE_STATE_NAME}
          columns={columns}
          checkable={true}
          totalNumEntities={totalCount ?? 0}
          tableData={data}
          renderEntry={(link, linkIndex) =>
            columns.map((column, index) => (
              <EntityCell
                key={index}
                column={column}
                linkId={link}
                index={linkIndex}
              />
            ))
          }
          mapId={(app) => app}
        />
      )}
    </>
  )
}

const AppActionBar = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('apps')
  const checkedIds = useSelector((state: RootState) =>
    selectCheckedIds(state, APP_TABLE_STATE_NAME),
  )

  const isSelected = (checkedIds?.length ?? 0) > 0

  const selectApps = useMemo(() => {
    const emptyArray: LinkReadPublic[] = []

    return createSelector(
      [
        (res?: PaginatedIntegrationLinkReadPublic) =>
          res?.content ?? emptyArray,
      ],
      (content) =>
        checkedIds
          ? (checkedIds
              .map((index) =>
                content.find((app) => app.id.toString() === index),
              )
              .filter((app) => app !== undefined) as any)
          : emptyArray,
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [checkedIds])

  const selectedApps = useSelector((state: RootState) =>
    selectApps(
      selectRtkData(
        state,
        APP_TABLE_STATE_NAME,
        clientApi.endpoints.getAllIntegrationLinksApiV1IntegrationsLinksGet
          .select,
      )?.data,
    ),
  )

  const [unlink] =
    useDisconnectIntegrationApiV1IntegrationsIntegrationIdLinkDeleteMutation()

  const unlinkSelected = async () => {
    invalidateTable()

    for (const app of selectedApps) {
      try {
        await unlink({ integrationId: app.client_id }).unwrap()
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    }
  }
  const [openConfirmUnlink, setOpenConfirmUnlink] = useState(false)

  const { data: integration } = useGetIntegrationsApiV1IntegrationsGetQuery(
    {},
    {
      selectFromResult: ({ data, error, isLoading }) => ({
        data: (data?.content ?? EMPTY_INTEGRATION_ARRAY).find(
          (integration) =>
            integration.client_id === selectedApps?.[0]?.client_id,
        ),
        error,
        isLoading,
      }),
      skip: selectedApps.length > 1,
    },
  )
  const { org } = useOrg()

  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignContent: 'center',
          width: '100%',
          gap: '6px',
        }}
      >
        {isSelected ? (
          <ActionBar
            selector={(state: RootState) =>
              selectNumChecked(state, APP_TABLE_STATE_NAME)
            }
            handleCloseActionBar={() => {
              invalidateTable()
            }}
          >
            <Button
              color="error"
              startIcon={<UnlinkSvg color="inherit" />}
              size={'small'}
              onClick={() => {
                setOpenConfirmUnlink(true)
              }}
            >
              {t('common:button.unlink')}
            </Button>
          </ActionBar>
        ) : (
          <>
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {children}
            </Box>
          </>
        )}
      </Box>
      <DialogBox
        title={t('title.unlink' + (integration !== undefined ? '_name' : ''), {
          count: selectedApps.length === 0 ? undefined : selectedApps.length,
          name: integration?.name,
        })}
        message={
          <>
            {t(
              [
                'message.unlink' +
                  (org && integration
                    ? '_nameOrg'
                    : org
                      ? '_org'
                      : integration
                        ? '_name'
                        : ''),
                'message.unlink',
              ],
              {
                org: org?.name,
                count:
                  selectedApps.length === 0 ? undefined : selectedApps.length,
                name: integration?.name,
              },
            )}
            <br />
            <br />
            {t('message.unlinkParts' + (org && '_org'), {
              org: org?.name,
            })}
          </>
        }
        args={undefined}
        open={openConfirmUnlink}
        setOpen={setOpenConfirmUnlink}
        onClose={(approve) => {
          if (approve) {
            unlinkSelected()
          }
        }}
        confirmColor="error"
        confirmText={t('button.unlink')}
        declineColor="secondary"
        declineText={t('button.cancel')}
        sx={{ '.MuiPaper-root': { width: '100%', maxWidth: '700px' } }}
      />
    </>
  )
}

const AppListData = () => {
  const queryArgs = useSelector((state: RootState) =>
    selectQueryArgs(state, APP_TABLE_STATE_NAME),
  )

  const rtkArgs = useMemo(
    () => ({
      page: queryArgs.page !== 0 ? queryArgs.page - 1 : 0,
      perPage: queryArgs.perPage,
      query: undefined,
    }),
    [queryArgs],
  )

  useEffect(() => {
    store.dispatch(setRtkArgs({ name: APP_TABLE_STATE_NAME, value: rtkArgs }))
  }, [rtkArgs])

  useGetAllIntegrationLinksApiV1IntegrationsLinksGetQuery(rtkArgs, {})

  return <>{null}</>
}

const AppList = () => {
  const noApps = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      APP_TABLE_STATE_NAME,
      clientApi.endpoints.getAllIntegrationLinksApiV1IntegrationsLinksGet
        .select,
    )

    const count = query?.data?.content?.length ?? 0
    const error = query?.error
    const isLoading = query?.isLoading
    return !error && !isLoading && count === 0
  })

  return (
    <>
      <AppListData />{' '}
      {noApps ? (
        <NoLinks />
      ) : (
        <>
          <AppActionBar>
            <ReduxPerPage
              selector={(state: RootState) =>
                state.tables.data[APP_TABLE_STATE_NAME]?.queryArgs?.perPage
              }
              dispatch={(state) =>
                store.dispatch(
                  setPerPage({ name: APP_TABLE_STATE_NAME, value: state }),
                )
              }
            />
          </AppActionBar>
          <List />
        </>
      )}
    </>
  )
}

export { AppList }
