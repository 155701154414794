import { Card, CardContent, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Logo } from '../components'

const OnboardingDone: FC = () => {
  const { t } = useTranslation('onboarding')
  return (
    <Card id="done">
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box component="div" sx={{ marginBottom: '1.5em', marginTop: '1.5em' }}>
          <Logo />
        </Box>
        <Typography variant="subtitle1" textAlign={'center'}>
          {t('message.welcomeRedirect')}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default OnboardingDone
