import { Box } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { StrictMode } from 'react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { App } from './App'
import { Button } from './components/Button/Button'
import { RefreshSvg } from './components/Icon/Icon'
import { store } from './store/store'
import theme from './theme'

const sentryDSN = import.meta.env.VITE_SENTRY_DSN
const sentryEnvironment = import.meta.env.VITE_SENTRY_ENVIRONMENT
const sentryProjectName =
  import.meta.env.VITE_SENTRY_PROJECT_NAME ?? 'funnel-web-portal'
const sentryRelease = import.meta.env.VITE_SENTRY_RELEASE_VERSION
  ? `${sentryProjectName}@${import.meta.env.VITE_SENTRY_RELEASE_VERSION}`
  : undefined

let pollingCounter = 0
const MAX_DEVICE_POLLING_TO_CAPTURE = 10
const UNWRAPPED_FETCH = fetch

Sentry.init({
  dsn: sentryDSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: true,
      maskAllText: true,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  tracePropagationTargets: [import.meta.env.VITE_API_BASE_URL],
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  //Environment
  release: sentryRelease,
  environment: sentryEnvironment,
  debug: sentryEnvironment === 'local',
  enabled: import.meta.env.VITE_ENABLE_SENTRY === 'true',
  // Filter
  beforeBreadcrumb: (breadcrumb) => {
    if (
      breadcrumb.category === 'redux.action' &&
      (breadcrumb?.data?.meta?.arg?.endpointName ===
        'getDevicesWithStatusApiV1DevicesStatusGet' ||
        breadcrumb?.data?.type ===
          'api/internalSubscriptions/subscriptionsUpdated')
    ) {
      if (pollingCounter < MAX_DEVICE_POLLING_TO_CAPTURE) {
        pollingCounter++
        return breadcrumb
      }
      return null
    } else if (
      breadcrumb.category === 'fetch' &&
      (breadcrumb?.data?.url ?? '').includes(
        'https://api.dev.asiga.com/api/v1/devices/status',
      )
    ) {
      if (pollingCounter < MAX_DEVICE_POLLING_TO_CAPTURE) {
        pollingCounter++
        return breadcrumb
      }
      return null
    } else if (breadcrumb.category === 'navigation') {
      pollingCounter = 0
    }
    return breadcrumb
  },
})

const renderDom = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
  })

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  )
  root.render(
    <StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LDProvider>
            <Sentry.ErrorBoundary
              fallback={({ error, componentStack, resetError }) => {
                return (
                  <Box
                    component="div"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <Button
                      startIcon={<RefreshSvg color="inherit" />}
                      onClick={() => resetError()}
                    >
                      Click here to reset!
                    </Button>
                  </Box>
                )
              }}
              showDialog
            >
              <App />
            </Sentry.ErrorBoundary>
          </LDProvider>
        </ThemeProvider>
      </Provider>
    </StrictMode>,
  )
}

renderDom()

export { pollingCounter, MAX_DEVICE_POLLING_TO_CAPTURE, UNWRAPPED_FETCH }
