import { Typography } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { partClassification } from '../../constants/partClassification'
import { ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  PaginatedPartReadPublic,
  PartReadPublic,
  useAcceptPartApiV1PartsPartIdAcceptPutMutation,
  useConfirmPartApiV1PartsPartIdConfirmPutMutation,
  useDownloadPartApiV1PartsPartIdUploadedGetMutation,
  useRejectPartApiV1PartsPartIdRejectPutMutation,
  useUndoAcceptPartApiV1PartsPartIdAcceptDeleteMutation,
  useUndoRejectPartApiV1PartsPartIdRejectDeleteMutation,
  useUpdatePartApiV1PartsPartIdPutMutation,
} from '../../store/clientApi'
import { setIsDownloading } from '../../store/slices/downloadingPartsSlice'

import {
  invalidate,
  selectCheckedIds,
  selectRtkData,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import {
  getPartFromIndexedDB,
  partObjectIndexedDB,
  savePartToIndexedDB,
} from '../../utils/indexeddb'
import { zipAndDownloadFiles } from '../../utils/zipAndDownloadFiles'
import { Button } from '../Button/Button'
import { DialogBox } from '../DialogBox'
import { DropdownButton } from '../DropdownButton/DropdownButton'
import {
  CloseSvg,
  DoneSvg,
  DownloadSvg,
  ReleaseSvg,
  ToReviewSvg,
} from '../Icon/Icon'
import { PART_TABLE_STATE_NAMES } from '../PartsTable/PartsTable'
import {
  isAcceptDisabled,
  isConfirmDisabled,
  isDownloadDisabled,
  isPartTypeDisabled,
  isRejectDisabled,
  isUndoAcceptDisabled,
  isUndoRejectDisabled,
} from './checkDisabledActionButtons'

export const ConfirmPartButton = ({
  parts,
  isInspectorPanel,
  invalidateTable,
}: {
  parts: PartReadPublic[]
  isInspectorPanel: boolean
  invalidateTable: () => void
}) => {
  const { t } = useTranslation('inbox')
  const [confirmPartAPI] = useConfirmPartApiV1PartsPartIdConfirmPutMutation()

  const handleOnConfirmParts = async () => {
    for (const part of parts) {
      // Try confirm each part
      try {
        await confirmPartAPI({ partId: part.id }).unwrap()
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    }
    invalidateTable()
  }
  return (
    <Button
      onClick={handleOnConfirmParts}
      disabled={isConfirmDisabled(parts)}
      startIcon={<ReleaseSvg color="inherit" />}
      short={isInspectorPanel}
    >
      {t('button.confirm')}
    </Button>
  )
}

export const RejectPartButton = ({
  parts,
  isInspectorPanel,
  invalidateTable,
}: {
  parts: PartReadPublic[]
  isInspectorPanel: boolean
  invalidateTable: () => void
}) => {
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const { t } = useTranslation('inbox')
  const [rejectpartAPI] = useRejectPartApiV1PartsPartIdRejectPutMutation()

  const handleOpenRejectModal = (): void => {
    setRejectModalOpen(true)
  }

  const handleOnRejectParts = async () => {
    const loadingId = toast.loading(t('common:label.rejectingParts'))
    let rejected = 0
    setRejectModalOpen(false)

    for (const part of parts) {
      // Try reject each part
      try {
        await rejectpartAPI({ partId: part.id }).unwrap()
        rejected++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    }
    invalidateTable()
    toast.dismiss(loadingId)

    // Show alert that rejecting is complete
    if (rejected > 0)
      toast.success(t('common:label.rejectedSuccess', { count: rejected }))
  }

  const getRejectPartModalHeaderText = (): string => {
    /** Returns a reject part modal heading string depending on the number
     *  of selected parts.
     *  One: 'Reject <part name>?
     *  Other: 'Reject <count> selected parts?
     */
    const selectedCount = parts.length

    if (selectedCount === 1) {
      return t('label.rejectModalTitle', {
        count: selectedCount,
        partName: parts[0].name,
      })
    }

    return t('common:label.rejectModalTitle', { count: selectedCount })
  }

  return (
    <>
      <Button
        disabled={isRejectDisabled(parts)}
        color="error"
        onClick={handleOpenRejectModal}
        startIcon={<CloseSvg color="inherit" />}
        short={isInspectorPanel}
      >
        {t('button.reject')}
      </Button>
      <DialogBox
        title={getRejectPartModalHeaderText()}
        args={undefined}
        message={<Typography>{t('common:label.rejectModal')}</Typography>}
        open={rejectModalOpen}
        confirmColor="error"
        confirmText={t('common:button.reject')}
        declineText={t('common:button.cancel')}
        setOpen={setRejectModalOpen}
        onClose={(approve) => {
          if (approve) handleOnRejectParts()
        }}
        sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
      />
    </>
  )
}

export const AcceptPartButton = ({
  parts,
  isInspectorPanel,
  invalidateTable,
}: {
  parts: PartReadPublic[]
  isInspectorPanel: boolean
  invalidateTable: () => void
}) => {
  const { t } = useTranslation('inbox')
  const [acceptPartAPI] = useAcceptPartApiV1PartsPartIdAcceptPutMutation()

  const handleOnAcceptParts = async () => {
    for (const part of parts) {
      // Try accept each part
      try {
        await acceptPartAPI({ partId: part.id }).unwrap()
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    }
    invalidateTable()
  }
  return (
    <Button
      onClick={handleOnAcceptParts}
      disabled={isAcceptDisabled(parts)}
      startIcon={<DoneSvg color="inherit" />}
      short={isInspectorPanel}
    >
      {t('button.accept')}
    </Button>
  )
}

export const DownloadPartButton = ({
  parts,
  isInspectorPanel,
  invalidateTable,
}: {
  parts: PartReadPublic[]
  isInspectorPanel: boolean
  invalidateTable: () => void
}) => {
  const { t } = useTranslation('inbox')
  const [downloadPartAPI] = useDownloadPartApiV1PartsPartIdUploadedGetMutation()

  const handleDownloadParts = async () => {
    /** Downloads selected parts as a zip (to handle multiple files) */
    const loadingId = toast.loading(t('label.preparingDownload'))
    const files = []

    // Used to prevent background download URL showing a navigation warning
    // if there are uploads currently in progress - ProgressBottomDrawer
    store.dispatch(setIsDownloading(true))

    for (const part of parts) {
      let data: ArrayBuffer | null = null

      try {
        // Check IndexedDB cache first
        const cachedData = await getPartFromIndexedDB(
          part.id.toString(),
          part.status,
        )
        if (cachedData) {
          data = cachedData

          if (parts.length === 1) {
            // Download the part directly
            const downloadBlob = new Blob([data], { type: 'uint8array' })
            const downloadURL = URL.createObjectURL(downloadBlob)
            // Create a link and trigger the download
            const link = document.createElement('a')
            link.href = downloadURL
            link.download = part.name
            document.body.appendChild(link)
            link.click()
            continue
          }
        } else {
          // If not cached, download from API
          const downloadResponse = await downloadPartAPI({ partId: part.id })

          if ('data' in downloadResponse) {
            // Only make the network request if necessary
            if (parts.length > 1) {
              // For zipping
              const downloadData = await axios.get(
                downloadResponse.data.presigned_url,
                {
                  responseType: 'arraybuffer', // Ensure binary data is received
                },
              )
              data = downloadData.data
              // Save to IndexedDB cache
              if (data !== null) {
                await savePartToIndexedDB(part.id.toString(), {
                  partStatus: part.status,
                  data: data,
                  timestamp: Date.now(),
                } as partObjectIndexedDB)
              }
            } else {
              // For direct download
              const downloadUrl = downloadResponse.data.presigned_url
              const downloadLink = document.createElement('a')
              downloadLink.href = downloadUrl
              downloadLink.download = part.name
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
              continue // Skip to the next part
            }
          }
        }

        if (data && parts.length > 1) {
          // Add to array for zipping files
          files.push({
            name: part.name,
            data: data,
          })
        }
      } catch (err) {
        const error = ensureError(err)
        toast.error(`${localizeError(t, error)}: ${part.name}`)
      }
    }

    if (parts.length > 1) {
      await zipAndDownloadFiles(files)
    }

    invalidateTable()
    store.dispatch(setIsDownloading(false))
    toast.dismiss(loadingId)
  }

  return (
    <>
      <Button
        disabled={isDownloadDisabled(parts)}
        onClick={handleDownloadParts}
        startIcon={<DownloadSvg color="inherit" />}
        short={isInspectorPanel}
      >
        {t('button.download')}
      </Button>
    </>
  )
}

export const UndoRejectPartButton = ({
  parts,
  isInspectorPanel,
  invalidateTable,
}: {
  parts: PartReadPublic[]
  isInspectorPanel: boolean
  invalidateTable: () => void
}) => {
  const { t } = useTranslation('inbox')
  const [undoRejectPartAPI] =
    useUndoRejectPartApiV1PartsPartIdRejectDeleteMutation()

  const handleUndoRejectPart = async () => {
    for (const part of parts) {
      // Try undo reject each part
      try {
        await undoRejectPartAPI({ partId: part.id }).unwrap()
      } catch (err) {
        const error = ensureError(err)
        toast.error(`${localizeError(t, error)}: ${part.name}`)
      }
    }
    invalidateTable()
  }
  return (
    <>
      <Button
        disabled={isUndoRejectDisabled(parts)}
        onClick={handleUndoRejectPart}
        startIcon={<ToReviewSvg color="inherit" />}
        short={isInspectorPanel}
      >
        {t('button.moveToReview')}
      </Button>
    </>
  )
}

export const UndoAcceptPartButton = ({
  parts,
  isInspectorPanel,
  invalidateTable,
}: {
  parts: PartReadPublic[]
  isInspectorPanel: boolean
  invalidateTable: () => void
}) => {
  const { t } = useTranslation('inbox')
  const [undoAcceptPartAPI] =
    useUndoAcceptPartApiV1PartsPartIdAcceptDeleteMutation()

  const handleUndoAcceptParts = async () => {
    for (const part of parts) {
      // Try undo accept each part
      try {
        await undoAcceptPartAPI({ partId: part.id }).unwrap()
      } catch (err) {
        const error = ensureError(err)
        toast.error(`${localizeError(t, error)}: ${part.name}`)
      }
    }
    invalidateTable()
  }

  return (
    <>
      <Button
        onClick={handleUndoAcceptParts}
        disabled={isUndoAcceptDisabled(parts)}
        startIcon={<ToReviewSvg color="inherit" />}
        short={isInspectorPanel}
      >
        {t('button.moveToReview')}
      </Button>
    </>
  )
}

export const SetPartTypePartButton = ({
  parts,
  isInspectorPanel,
  invalidateTable,
}: {
  parts: PartReadPublic[]
  isInspectorPanel: boolean
  invalidateTable: () => void
}) => {
  const { t } = useTranslation('inbox')
  const partTypesOptions = Object.keys(partClassification).filter((key) =>
    isNaN(Number(key as keyof typeof partClassification)),
  ) as (keyof typeof partClassification)[]

  const [updatePartTypeAPI] = useUpdatePartApiV1PartsPartIdPutMutation()

  const handleOnSetPartType = async (value: string) => {
    for (const part of parts) {
      // Try classify each part
      if (
        part.classification ===
        partClassification[value as keyof typeof partClassification]
      ) {
        // Ensure we don't update part if it is already that classification
        continue
      }

      try {
        await updatePartTypeAPI({
          partId: part.id,
          partUpdateRequest: {
            classification:
              partClassification[value as keyof typeof partClassification],
          },
        }).unwrap()
      } catch (err) {
        const error = ensureError(err)
        toast.error(`${localizeError(t, error)}: ${part.name}`)
      }
    }
    invalidateTable()
  }
  return (
    <>
      <DropdownButton
        label={t('button.partType')}
        onChange={(value) => handleOnSetPartType(value)}
        options={partTypesOptions}
        disabled={isPartTypeDisabled(parts)}
      />
    </>
  )
}

export type PartButtonGroupType =
  | 'accepted'
  | 'confirm'
  | 'parts'
  | 'rejected'
  | 'review'

export const PartButtonGroup = ({
  table,
  isInspectorPanel,
  type,
}: {
  isInspectorPanel: boolean
  table: PART_TABLE_STATE_NAMES
  type: PartButtonGroupType
}) => {
  const selected = useSelector((state: RootState) =>
    selectCheckedIds(state, table),
  )

  const selectSelectedParts = useMemo(() => {
    const emptyArray: PartReadPublic[] = []

    return createSelector(
      [(res?: PaginatedPartReadPublic) => res?.content ?? emptyArray],
      (content) =>
        content.filter((part) => selected.includes(part.id.toString())),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [selected])

  const parts = useSelector((state: RootState) =>
    selectSelectedParts(
      selectRtkData(
        state,
        table,
        clientApi.endpoints.getPartsApiV1PartsGet.select,
      )?.data,
    ),
  )

  const invalidateTable = () => store.dispatch(invalidate(table))

  switch (type) {
    case 'accepted':
      return (
        <>
          <RejectPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
          <UndoAcceptPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
        </>
      )
    case 'confirm':
      return (
        <>
          <RejectPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
          {!isInspectorPanel && (
            <>
              <DownloadPartButton
                parts={parts}
                isInspectorPanel={isInspectorPanel}
                invalidateTable={invalidateTable}
              />
              <SetPartTypePartButton
                parts={parts}
                isInspectorPanel={isInspectorPanel}
                invalidateTable={invalidateTable}
              />
            </>
          )}
          <ConfirmPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
        </>
      )
    case 'parts':
      return (
        <>
          <RejectPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
          <DownloadPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
        </>
      )
    case 'rejected':
      return (
        <>
          <UndoRejectPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
          <AcceptPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
        </>
      )
    case 'review':
      return (
        <>
          <RejectPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
          {!isInspectorPanel && (
            <DownloadPartButton
              parts={parts}
              isInspectorPanel={isInspectorPanel}
              invalidateTable={invalidateTable}
            />
          )}
          <AcceptPartButton
            parts={parts}
            isInspectorPanel={isInspectorPanel}
            invalidateTable={invalidateTable}
          />
        </>
      )
  }
}
