import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface LogoProps {
  width?: string
}

const Logo = ({ width }: LogoProps) => {
  const { t } = useTranslation()
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      title={t('common:alt.logo')}
    >
      <Box
        component="img"
        sx={{
          width: width ?? '350px',
        }}
        alt={t('common:alt.logo')}
        src="/images/logo-1.svg"
      />
    </Box>
  )
}

export default Logo
