import {
  Box,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { ensureError, localizeError } from '../../helpers'
import { useOrg } from '../../hooks/org'
import {
  clientApi,
  REFETCH_TIMEOUT,
  RoleModel,
  useCreateUsersApiV1UsersPostMutation,
  useGetRolesApiV1UsersRolesGetQuery,
} from '../../store/clientApi'
import { patchUsers, store } from '../../store/store'
import { Button } from '../Button/Button'
import { ExpandedSvg } from '../Icon/Icon'
import { Menu } from '../Menu/Menu'

interface UserCreateProps {
  onCreate: () => void
}

const UserCreate: FC<UserCreateProps> = ({ onCreate }) => {
  const { t } = useTranslation('users')

  const { org } = useOrg()

  const [email, setEmail] = useState('')
  const [role, setRole] = useState<RoleModel | undefined>()
  const [emailError, setEmailError] = useState(false)

  const [roleWidth, setRoleWidth] = useState(0)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const {
    data: roles,
    error: rolesError,
    isLoading: rolesIsLoading,
  } = useGetRolesApiV1UsersRolesGetQuery()

  const [createUsersApi] = useCreateUsersApiV1UsersPostMutation()

  // For initial release only admin role is allowed
  if (!rolesError && !rolesIsLoading && roles && role === undefined)
    setRole(roles.roles.filter((r) => r.name === 'admin')[0])

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  const handleSubmit = () => {
    setEmailError(false)
    const emails = email.split(',').map((email) => email.trim())
    const emailsInvalid =
      emails.length === 0 ||
      emails.filter((email) => !validateEmail(email)).length !== 0

    if (emailsInvalid) {
      setEmailError(true)
    }

    if (!emailsInvalid && role !== undefined) createUser()
  }

  const createUser = async () => {
    try {
      onCreate()
      for (const user of email.split(',').map((email) => email.trim())) {
        await createUsersApi({
          createUserRequest: {
            email: user,
            roles: [role?.id ?? ''],
          },
        }).unwrap()
        patchUsers([{ name: user, email: user }])
      }

      setEmail('')
    } catch (err) {
      const error = ensureError(err)
      toast.error(localizeError(t, error))
    }
    setTimeout(
      () => store.dispatch(clientApi.util.invalidateTags(['users'])),
      REFETCH_TIMEOUT,
    ) // sometimes the auth0 database is delayed in updating data so we need to refetch after a delay to ensure data is up to date
  }

  return (
    <>
      {rolesError ? (
        <>{localizeError(t, ensureError(rolesError))}</>
      ) : rolesIsLoading ? (
        <LinearProgress />
      ) : roles ? (
        <>
          <Typography
            sx={{
              marginBottom: '0.6em',
              color: (theme: Theme) => theme.palette.primary.dark,
            }}
          >
            {t('message.addUsers', {
              ...(org?.name && { context: 'org', org: org.name }),
            })}
          </Typography>
          <FormControl
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'flex-start',
                marginBottom: '0.7em',
                gap: '0.5em',
                '& input': {
                  width: `calc(100% - ${roleWidth}px)`,
                },
                fontSize: {
                  xs: '11px',
                  sm: '1em',
                },
              }}
            >
              <Box component="div" sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  required
                  id="invite-email"
                  type="email"
                  placeholder={t('label.enterEmails')}
                  value={email}
                  error={emailError}
                  onChange={(e) => setEmail(e.target.value)}
                  size="small"
                  fullWidth
                  multiline
                  sx={{
                    fontSize: 'inherit',
                    '& textarea': {
                      width: `calc(100% - ${roleWidth}px)`,
                      fontSize: 'inherit',
                    },
                  }}
                ></TextField>
                <Box
                  component="div"
                  sx={{
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    height: '100%',
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    cursor: 'default',
                  }}
                  ref={(ref?: HTMLDivElement) => {
                    if (ref) setRoleWidth(ref.clientWidth)
                    ref?.addEventListener('resize', () => {
                      setRoleWidth(ref.clientWidth)
                    })
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 'inherit',
                    }}
                  >
                    {t(`role.${role?.name}`, {
                      defaultValue: role?.name ?? '',
                    })}
                  </Typography>
                  <IconButton sx={{ height: '40px' }} onClick={handleMenuClick}>
                    <ExpandedSvg color="inherit" />
                  </IconButton>
                </Box>
              </Box>
              <Button
                id="invite-add"
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  padding: '7px 16px',
                }}
              >
                {t('common:button.invite')}
              </Button>
            </Box>
            <Menu
              innerProps={{
                anchorEl: anchorEl,
                open: openMenu,
                onClose: handleMenuClose,
                onClick: handleMenuClose,
              }}
              hideArrow
            >
              {
                // For initial release only admin role is allowed
                roles.roles
                  .filter((r) => r.name === 'admin')
                  .map((role) => {
                    /*
                    role keys
                    t('role.accountant')
                    t('role.admin')
                    t('role.designer')
                    t('role.owner')
                    t('role.technician')
                    */
                    return (
                      <MenuItem
                        key={role.id}
                        value={role.id}
                        onClick={() => setRole(role)}
                      >
                        {t(`role.${role.name}`, { defaultValue: role.name })}
                      </MenuItem>
                    )
                  })
              }
            </Menu>
          </FormControl>
        </>
      ) : null}
    </>
  )
}

export { UserCreate }
