import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment-timezone'
import React, { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Logo } from '../components'
import { Button } from '../components/Button/Button'
import { Countries } from '../helpers'
import { UserData } from '../pages/OnBoarding'
import { OrganisationIndustry } from '../store/clientApi'

interface Props {
  userData: UserData
  setUserData: React.Dispatch<React.SetStateAction<any>>
  setStage: React.Dispatch<React.SetStateAction<any>>
  IndustryType: string[]
}

const OnboardingStep1: FC<Props> = ({
  userData,
  setUserData,
  setStage,
  IndustryType,
}) => {
  const { t } = useTranslation('onboarding')
  const [timezone, setTimezone] = useState(
    userData.timezone ?? moment.tz.guess(),
  )
  const [timezoneError, setTimezoneError] = useState('')
  const [timezoneDisabled, setTimezoneDisabled] = useState(false)
  const [country, setCountry] = useState(userData.country ?? '')
  const [countryError, setCountryError] = useState('')
  const [company, setCompany] = useState(userData.company ?? '')
  const [companyError, setCompanyError] = useState('')
  const [industry, setIndustry] = useState<OrganisationIndustry | ''>(
    userData.industry ?? '',
  )
  const [industryError, setIndustryError] = useState('')

  const next = () => {
    if (timezone === '') setTimezoneError('Field is required')
    else setTimezoneError('')

    if (country === '') setCountryError('Field is required')
    else setCountryError('')

    if (company === '') setCompanyError('Field is required')
    else setCompanyError('')

    if (industry === '') setIndustryError('Field is required')
    else setIndustryError('')

    if (
      timezone !== '' &&
      country !== '' &&
      company !== '' &&
      industry !== ''
    ) {
      const newData: UserData = {
        ...userData,
        timezone: timezone,
        country: country,
        company: company,
        industry: industry,
      }
      setUserData(newData)
      setStage('step2')
    }
  }

  const handleOnChangeCountry = (countryValue: string) => {
    if (countryValue === '') setCountryError('Field is required')
    else setCountryError('')

    setCountry(countryValue)
  }

  const handleOnChangeCompany = (companyValue: string) => {
    if (companyValue === '') setCompanyError('Field is required')
    else setCompanyError('')

    setCompany(companyValue)
  }

  const handleOnChangeIndustry = (industryValue: OrganisationIndustry | '') => {
    if (industryValue === '') setIndustryError('Field is required')
    else setIndustryError('')
    setIndustry(industryValue as OrganisationIndustry)
  }

  return (
    <Card id="step1">
      <CardContent sx={{ maxWidth: '450px' }}>
        <Box component="div" sx={{ marginBottom: '1.5em', marginTop: '1.5em' }}>
          <Logo />
        </Box>
        <Typography variant="h1" fontSize={'1em'} textAlign={'center'}>
          {t('message.signUp')}
        </Typography>
        <Divider sx={{ marginBottom: '0.8em', marginTop: '0.8em' }} />
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '0.8em',
          }}
          noValidate
          autoComplete="off"
        >
          <FormControl fullWidth>
            <InputLabel id="country-label" required error={!!countryError}>
              {t('label.country')}
            </InputLabel>
            <Select
              labelId="country-label"
              id="country-select"
              label={t('label.country')}
              value={country}
              error={!!countryError}
              onChange={(e) => handleOnChangeCountry(e.target.value)}
              required
            >
              {Countries.raw.map((item) => (
                <MenuItem key={item.name} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="company-name"
            label={t('label.companyName')}
            variant="outlined"
            error={!!companyError}
            value={company}
            onChange={(e) => handleOnChangeCompany(e.target.value)}
            required
          />
          <FormControl fullWidth>
            <InputLabel
              id="industry-type-label"
              required
              error={!!industryError}
            >
              {t('label.industry')}
            </InputLabel>
            <Select
              labelId="industry-type-label"
              id="industry-select"
              label={t('label.industry')}
              error={!!industryError}
              value={industry}
              inputProps={{
                id: 'industry-type-select',
              }}
              onChange={(e) =>
                handleOnChangeIndustry(e.target.value as OrganisationIndustry)
              }
              required
            >
              {IndustryType.map((item) => (
                <MenuItem id={`select-${item}`} key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {timezoneDisabled && (
            <FormControl fullWidth>
              <InputLabel id="timezone-label" required>
                {t('label.timezone')}
              </InputLabel>
              <Select
                labelId="timezone-label"
                id="timezone-select"
                label={t('label.timezone')}
                error={!!timezoneError}
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
              >
                {moment.tz.names().map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControlLabel
            value={timezoneDisabled}
            onChange={() => setTimezoneDisabled(!timezoneDisabled)}
            control={
              <Checkbox
                id="automatic-timezone-checkbox"
                defaultChecked
                name="auto-timezone"
              />
            }
            label={t('label.setTimezone')}
          />
          <Button
            type="submit"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              next()
            }}
          >
            {t('common:button.continue')}
          </Button>
        </Box>
        <Typography
          variant="subtitle1"
          sx={{ marginTop: '0.8em' }}
          textAlign={'center'}
        >
          {t('message.noCreditCard')}
        </Typography>
        <Box component="div" sx={{ marginTop: '0.8em' }} textAlign={'center'}>
          <Trans i18nKey="message.termsAndConditions">
            By continuing, you agree to Asiga's{' '}
            <a
              href="https://myaccount.asiga.com/media/main/files/terms_and_conditions.pdf"
              target="_blank"
              rel="noreferrer"
            >
              terms
            </a>
            , and acknowledge you have read our{' '}
            <a
              href="https://myaccount.asiga.com/company/faq/Privacy%20Policy/"
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>
            .{' '}
          </Trans>
        </Box>
      </CardContent>
    </Card>
  )
}

export default OnboardingStep1
