import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Paper,
  Theme,
  Typography,
} from '@mui/material'
import React, { PropsWithChildren, useState } from 'react'
import { Color } from '../../helpers'
import { CloseSvg, ErrorOutlineSvg, MoreMenuSvg } from '../Icon/Icon'
import { Menu } from '../Menu/Menu'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { StatusBanner } from '../StatusBanner/StatusBanner'
import { StyledTooltip } from '../StyledTooltip/StyledTooltip'

interface BaseCardProps {
  content: React.ReactNode
  footer: React.ReactNode
}

interface InformationProps {
  title: string
  subtitle?: string
  info?: string
  message?: React.ReactNode
  titleFontSize?: string
  subtitleFontSize?: string
  titleNoWrap?: boolean
}

interface InfoCardProps extends InformationProps {
  offsetX?: string
  width?: string
  selected?: boolean
  message?: React.ReactNode
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  onClick?: React.MouseEventHandler<HTMLDivElement>
  height?: string
}

interface ProgressCardProps extends InfoCardProps {
  offsetX?: string
  width?: string
  value: number
  message?: string
  inprogress?: boolean
  color?: Color
  icon?: React.ReactNode
  height?: string
}

interface IconCardProps extends InfoCardProps {
  icon: React.ReactNode
  width: string
  secondary?: string
}

const Information = ({
  title,
  subtitle,
  message,
  info,
  titleFontSize,
  subtitleFontSize,
  titleNoWrap,
}: InformationProps) => {
  return (
    <Box component="div">
      <Typography
        noWrap={titleNoWrap}
        variant="body1"
        sx={{
          fontWeight: '500',
          display: 'flex',
          alignItems: 'center',
          gap: '0.1em',
          ...(titleFontSize && {
            fontSize: titleFontSize,
          }),
        }}
      >
        {title}
        {info && (
          <StyledTooltip title={info} placement="right" arrow>
            <ErrorOutlineSvg color="inherit" />
          </StyledTooltip>
        )}
      </Typography>
      <Typography
        sx={{
          ...(subtitleFontSize && {
            fontSize: subtitleFontSize,
          }),
        }}
        variant="subtitle1"
        color={'gray'}
        noWrap
      >
        {subtitle}
      </Typography>
      {message}
    </Box>
  )
}

const BaseCard = ({
  offsetX,
  width,
  title,
  subtitle,
  message,
  info,
  selected,
  onClose,
  onClick,
  children,
  content,
  footer,
  height,
}: PropsWithChildren<InfoCardProps & BaseCardProps>) => {
  const [hover, setHover] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Card
      elevation={onClick !== undefined ? (hover ? 2 : 1) : 1}
      sx={{
        ...(selected && {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineColor: (theme: Theme) => theme.palette.primary.main,
        }),
        borderRadius: '0.5em',
        maxHeight: height ?? 'none',
        height: height ?? 'none',
        boxSizing: 'border-box',
        fontSize: ['12px', '12px', '14px', '16px'],
        bgColor: (theme: Theme) => theme.surface.normal,
        maxWidth: width ?? 'none',
        width: width ?? 'none',
        position: offsetX === undefined ? 'relative' : 'absolute',
        left: offsetX !== undefined ? offsetX : undefined,
        cursor: onClick !== undefined ? 'pointer' : 'auto',
      }}
      onClick={(e) => {
        if (onClick && !openMenu) onClick(e)
      }}
    >
      <CardContent
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        sx={{ padding: '1em', '&:last-child': { pb: '1em' } }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Information
            title={title}
            subtitle={subtitle}
            info={info}
            message={message}
            titleNoWrap={offsetX !== undefined}
          />
          <Box
            component="div"
            sx={{
              display: () => (hover ? 'flex' : 'none'),
              alignItems: 'center',
            }}
          >
            {children && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleMenuClick(e)
                }}
              >
                <MoreMenuSvg color="inherit" />
              </IconButton>
            )}
            {onClose && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  onClose(e)
                }}
              >
                <CloseSvg color="inherit" />
              </IconButton>
            )}
          </Box>

          <Menu
            innerProps={{
              anchorEl: anchorEl,
              open: openMenu,
              onClose: handleMenuClose,
              onClick: handleMenuClose,
            }}
          >
            {children || <MenuItem></MenuItem>}
          </Menu>
        </Box>
        {content}
      </CardContent>
      {footer}
    </Card>
  )
}

const ProgressCard = ({
  offsetX,
  width,
  value,
  message,
  color,
  inprogress,
  title,
  subtitle,
  info,
  selected,
  onClose,
  onClick,
  children,
  icon,
  height,
}: PropsWithChildren<ProgressCardProps>) => {
  return (
    <BaseCard
      offsetX={offsetX}
      width={width}
      title={title}
      subtitle={subtitle}
      selected={selected}
      onClose={onClose}
      onClick={onClick}
      info={info}
      content={
        <ProgressBar
          value={value}
          variant="determinate"
          color={color ?? 'info'}
        />
      }
      footer={
        <>
          {message && (
            <StatusBanner
              sx={{
                marginLeft: '0.5em',
                marginRight: '0.5em',
                borderBottomWidth: '0px',
              }}
              variant={color ?? 'info'}
              message={message}
              open={true}
              closable={false}
              inprogress={inprogress}
              icon={icon}
            />
          )}
        </>
      }
      height={height}
    >
      {children}
    </BaseCard>
  )
}

const InfoCard = ({
  offsetX,
  message,
  width,
  title,
  subtitle,
  info,
  selected,
  onClose,
  onClick,
  children,
  height,
}: PropsWithChildren<InfoCardProps>) => {
  return (
    <BaseCard
      offsetX={offsetX}
      width={width}
      title={title}
      subtitle={subtitle}
      message={message}
      selected={selected}
      info={info}
      onClose={onClose}
      onClick={onClick}
      content={<></>}
      footer={undefined}
      height={height}
    >
      {children}
    </BaseCard>
  )
}

const IconCard = ({
  title,
  subtitle,
  secondary,
  info,
  selected,
  width,
  icon,
  onClick,
}: IconCardProps) => {
  const [hover, setHover] = useState(false)
  return (
    <Box component="div" maxWidth={width} sx={{ margin: '1em' }}>
      <Avatar
        component={Paper}
        elevation={onClick !== undefined ? (hover ? 2 : 1) : 1}
        variant="rounded"
        sx={{
          ...(selected && {
            outlineStyle: 'solid',
            outlineWidth: '2px',
            outlineColor: (theme: Theme) => theme.palette.primary.main,
          }),
          borderRadius: '0.6em',
          width: width,
          height: width,
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={onClick}
      >
        {icon}
      </Avatar>
      <Box
        component="div"
        sx={{
          paddingLeft: '0.1em',
          paddingRight: '0.1em',
          marginTop: '0.5em',
        }}
      >
        <Information
          titleFontSize="1.2em"
          subtitleFontSize="0.9em"
          title={title}
          subtitle={subtitle}
          info={info}
        />
        {secondary && (
          <Typography
            sx={{ fontSize: '0.9em' }}
            variant="subtitle1"
            color={'gray'}
          >
            {secondary}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
export { InfoCard, ProgressCard, IconCard }
