import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface DevicesState {
  scroll: number
}

const initialState = { scroll: 0 } satisfies DevicesState
const devicesSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setScroll(state, action: PayloadAction<number>) {
      state.scroll = action.payload
    },
  },
})

export const { setScroll } = devicesSlice.actions
export default devicesSlice.reducer
