import JSZip from 'jszip'

export const zipAndDownloadFiles = async (
  files: { name: string; data: ArrayBuffer }[],
) => {
  // Create a zip file
  const zip = new JSZip()
  files.forEach((file) => {
    zip.file(file.name, file.data, { binary: true })
  })

  // Generate the zip file content
  const zipContent = await zip.generateAsync({ type: 'uint8array' })

  // Create a blob from the zip content
  const blob = new Blob([zipContent])

  // Create a temporary URL for the blob
  const downloadURL = URL.createObjectURL(blob)

  // Create a link and trigger the download
  const link = document.createElement('a')
  link.href = downloadURL
  link.download = 'Parts.zip'
  document.body.appendChild(link)
  link.click()

  // Clean up
  URL.revokeObjectURL(downloadURL)
  document.body.removeChild(link)
}
