import { CircularProgress, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { InfoCard } from '../../../components/Cards/Cards'
import {
  CompleteSvg,
  ErrorOutlineSvg,
  NotConnectedSvg,
  WarningSvg,
} from '../../../components/Icon/Icon'
import { deviceStateToColor } from '../../../helpers'
import { clientApi } from '../../../store/clientApi'
import {
  selectRtkData,
  selectTableChecked,
} from '../../../store/slices/tableSlice'
import { RootState } from '../../../store/store'
import { deviceMessage } from '../../../utils/getDeviceMessage'
import { TIMELINE_TABLE_STATE_NAME } from '../Timeline'

export const TimelinePrinter = ({
  thingName,
  onClick,
}: {
  thingName: string
  onClick?: () => void
}) => {
  const { t } = useTranslation('timeline')
  const UNAVAILABLE = t('common:loading.unavailable')
  const theme = useTheme()

  const isChecked = useSelector(
    (state: RootState) =>
      selectTableChecked(state, TIMELINE_TABLE_STATE_NAME)[thingName],
  )

  const currDevice = useSelector((state: RootState) =>
    selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )?.data?.content?.find((device) => device.thing_name === thingName),
  )

  const name = currDevice?.status?.name ?? UNAVAILABLE
  const model = currDevice?.status?.model ?? UNAVAILABLE
  const state = currDevice?.status?.state ?? ''
  const variant = deviceStateToColor(currDevice)
  const inProgress = ['Starting', 'Cancelling', 'Pausing', 'Printing'].includes(
    state,
  )

  return (
    <div>
      <InfoCard
        selected={isChecked}
        onClick={onClick}
        height={'98px'}
        title={name}
        subtitle={model}
        message={
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: `4px`,
            }}
          >
            {inProgress ? (
              <CircularProgress
                color={variant}
                size="1.15em"
                sx={{ marginLeft: '3px' }}
              />
            ) : !currDevice?.status?.connected ? (
              <NotConnectedSvg color="inherit" width="16px" height="16px" />
            ) : variant === 'info' ? (
              <ErrorOutlineSvg color={theme.palette[variant].main} />
            ) : variant === 'success' ? (
              <CompleteSvg color={theme.palette[variant].main} />
            ) : variant === 'warning' ? (
              <ErrorOutlineSvg color={theme.palette[variant].main} />
            ) : variant === 'error' ? (
              <WarningSvg color={theme.palette[variant].main} />
            ) : null}
            <Typography
              variant="subtitle1"
              color={'gray'}
              noWrap
              sx={{ margin: '-0.5em 0px' }}
            >
              {deviceMessage(currDevice)}
            </Typography>
          </div>
        }
      />
    </div>
  )
}
