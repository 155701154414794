import { partStatus } from '../../constants/partStatus'
import { PartReadPublic } from '../../store/clientApi'

export const isConfirmDisabled = (parts: PartReadPublic[]) => {
  /** Check if the selected parts are available to confirm
   *  i.e., is status partStatus['toConfirm']
   */

  return parts.some((part) => part.status !== partStatus.toConfirm)
}

export const isAcceptDisabled = (parts: PartReadPublic[]) => {
  /** Check if the selected parts are available to accept
   *  i.e., is status partStatus['toAccept', "rejected"]
   */

  return parts.some(
    (part) =>
      part.status !== partStatus.toAccept &&
      part.status !== partStatus.rejected,
  )
}

export const isRejectDisabled = (parts: PartReadPublic[]) => {
  /** Check if the selected parts are available to reject
   *  i.e., is status TO_CONFIRM, TO_ACCEPT, ACCEPTED, PROCESSING, or AWAITING_PLACEMENT
   */

  return parts.some(
    (part) =>
      part.status !== partStatus.toConfirm &&
      part.status !== partStatus.toAccept &&
      part.status !== partStatus.accepted &&
      part.status !== partStatus.processing &&
      part.status !== partStatus.awaitingPlacement,
  )
}

export const isDownloadDisabled = (parts: PartReadPublic[]) => {
  /** Check if the selected parts are available to download
   *  i.e., status != uploadPending
   */

  return parts.some((part) => part.status === partStatus.uploadPending)
}

export const isUndoAcceptDisabled = (parts: PartReadPublic[]) => {
  /** Check if the selected parts are available to move back to review
   *  i.e., status != accepted
   */

  return parts.some((part) => part.status !== partStatus.accepted)
}

export const isUndoRejectDisabled = (parts: PartReadPublic[]) => {
  /** Check if the selected parts are available to move back to review
   *  i.e., status != uploadPending
   */

  return parts.some((part) => part.status !== partStatus.rejected)
}

export const isPartTypeDisabled = (parts: PartReadPublic[]) => {
  /** Check if the selected parts are able to be re-classified
   *  i.e., status != uploadPending, uploaded or pre-processing
   */

  return parts.some(
    (part) =>
      part.status !== partStatus.toConfirm &&
      part.status !== partStatus.toAccept,
  )
}
