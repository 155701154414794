import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import {
  useGetUserRolesApiV1UsersUserIdRolesGetQuery,
  useSearchUserApiV1UsersUserIdGetQuery,
} from '../store/clientApi'

const useUser = () => {
  const { getIdTokenClaims } = useAuth0()

  const [userId, setUserId] = useState<string | undefined>()
  const [picture, setPicture] = useState<string | undefined>()

  useEffect(() => {
    const getOrg = async () => {
      const claims = await getIdTokenClaims()

      setUserId(claims?.sub)
      setPicture(claims?.picture)
    }

    getOrg()
  }, [getIdTokenClaims])

  const { data, error, isLoading } = useSearchUserApiV1UsersUserIdGetQuery(
    { userId: encodeURI(userId ?? '') },
    {
      skip: userId === undefined,
    },
  )

  const {
    data: roles,
    error: rolesError,
    isLoading: rolesIsLoading,
  } = useGetUserRolesApiV1UsersUserIdRolesGetQuery(
    { userId: encodeURI(userId ?? '') },
    {
      skip: userId === undefined,
    },
  )

  return {
    userId,
    picture,
    roles,
    data,
    error: userId ? error || rolesError : undefined,
    isLoading: userId ? isLoading || rolesIsLoading : true,
  }
}

export { useUser }
