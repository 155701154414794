import { Box } from '@mui/material'
import humanizeDuration from 'humanize-duration'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { deviceStateToColor } from '../../helpers'
import {
  DeviceStatusRead,
  useGetDevicesWithStatusApiV1DevicesStatusGetQuery,
} from '../../store/clientApi'
import { setDrawerOpen } from '../../store/slices/rightDrawerSlice'
import {
  selectTableChecked,
  setTableChecked,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { InfoCard } from '../Cards/Cards'
import {
  DetailsGrid,
  ExtendedDetails,
} from '../ExtendedDetails/ExtendedDetails'
import Spacer from '../Spacer/Spacer'
import { StatusBanner } from '../StatusBanner/StatusBanner'

export const DeviceViewer = ({
  detailsStateName = 'deviceDetailsState',
  tableStateName = 'devicesTableState',
}: {
  detailsStateName?: string
  tableStateName?: string
}) => {
  const getDevicesArgs = {
    sorting: 'name:1',
  }
  const { t, i18n } = useTranslation('devices')
  const UNAVAILABLE = t('common:loading.unavailable')

  // Get selected device
  const checked = useSelector((state: RootState) =>
    selectTableChecked(state, tableStateName),
  )

  const selectedDevice = Object.keys(checked).find(
    (thingName) => checked[thingName],
  )
    ? {
        thingName: Object.keys(checked).find((thingName) => checked[thingName]),
      }
    : undefined

  const { device } = useGetDevicesWithStatusApiV1DevicesStatusGetQuery(
    getDevicesArgs,
    {
      selectFromResult: ({ data }): { device?: DeviceStatusRead } => {
        const found = selectedDevice
          ? data?.content?.find(
              (device) => device.thing_name === selectedDevice.thingName,
            )
          : undefined
        return {
          device:
            found?.thing_name === selectedDevice?.thingName ? found : undefined,
        }
      },
    },
  )

  const elapsed = device?.status?.elapsed ?? 0
  const remaining = device?.status?.remaining ?? 0
  const printTime = elapsed + remaining
  const progress = printTime === 0 ? 0 : (elapsed / printTime) * 100

  const deviceMessage = (device?: DeviceStatusRead) => {
    const paused_by_user = device?.status?.paused_by_user ?? true
    const state = (device?.status?.state ?? '').toLowerCase()
    const remaining = device?.status?.remaining ?? 0
    const connected = device?.status?.connected ?? false

    if (!connected) {
      return t('state.offline')
    }

    if (['cancelling', 'canceled', 'pausing', 'paused'].includes(state)) {
      /*
        t('state.cancelling')
        t('state.canceled')
        t('state.pausing')
        t('state.paused')
        t('state.cancelling_error')
        t('state.canceled_error')
        t('state.pausing_error')
        t('state.paused_error')
        */
      return t('state.' + state, {
        context: !paused_by_user && 'error',
      })
    }

    if (state === 'printing') {
      return t('state.printing', {
        duration: humanizeDuration(remaining * 1000, {
          units: ['h', 'm'],
          round: true,
          language: i18n.language,
          fallbacks: [...i18n.languages],
        }),
      })
    }

    /*
      t('state.idle')
      t('state.staring')
      t('state.finished')
      t('state.userbusy')
      */
    return t(['state.' + state, 'common:loading.unavailable'])
  }

  const getStatus = (currDevice: DeviceStatusRead, value?: number) => {
    const state = currDevice.status?.state ?? ''
    const message = deviceMessage(currDevice)
    const color = deviceStateToColor(currDevice)

    if (['Starting', 'Cancelling', 'Pausing'].includes(state)) {
      return (
        <StatusBanner
          sx={{ paddingLeft: '1em', paddingRight: '1em' }}
          variant={color}
          message={message}
          open={true}
          closable={false}
          inprogress={true}
        />
      )
    }

    if (state === 'Printing') {
      return (
        <StatusBanner
          sx={{ paddingLeft: '1em', paddingRight: '1em' }}
          variant={color}
          message={message}
          open={true}
          closable={false}
          inprogress={true}
          value={value}
        />
      )
    }

    if (state === 'Paused' || state === 'Cancelled') {
      return (
        <StatusBanner
          sx={{ paddingLeft: '1em', paddingRight: '1em' }}
          variant={color}
          message={message}
          open={true}
          closable={false}
        />
      )
    }

    return null
  }

  const QueuedBuilds = () => {
    const queued_build_names = (device?.status?.queued_build_names ??
      []) as string[]
    const queued_build_times = (device?.status?.queued_build_times ??
      []) as number[]
    return (
      <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
        {queued_build_names.map((buildName, index) => {
          return (
            <Box component="div" key={`queued-${buildName}`}>
              <InfoCard
                title={buildName}
                subtitle={
                  queued_build_times[index] !== undefined
                    ? humanizeDuration(queued_build_times[index] * 1000, {
                        units: ['h', 'm'], // eslint-disable-line
                        round: true,
                        language: i18n.language,
                        fallbacks: [...i18n.languages],
                      })
                    : t('common:loading.unavailable')
                }
              />
              <Spacer height="1em" />
            </Box>
          )
        })}
      </Box>
    )
  }

  const CurrentBuild = ({
    device,
  }: {
    device: DeviceStatusRead | undefined
  }) => {
    const current_build_name = device?.status?.current_build_name ?? UNAVAILABLE
    const remaining = device?.status?.remaining ?? 0

    return (
      <InfoCard
        title={current_build_name}
        subtitle={t('state.printing', {
          duration: humanizeDuration(remaining * 1000, {
            units: ['h', 'm'],
            round: true,
            language: i18n.language,
            fallbacks: [...i18n.languages],
          }),
        })}
      />
    )
  }

  const formatNumber = (
    num: number | null | undefined,
    unit?: string,
    maximumFractionDigits = 2,
  ) => {
    if (num == null) return UNAVAILABLE

    const numberFormatter = new Intl.NumberFormat([...i18n.languages], {
      maximumFractionDigits: maximumFractionDigits,
      ...(unit && { style: 'unit', unit: unit }),
    })
    return numberFormatter.format(num)
  }

  const printStatusDetails = [
    {
      name: t('label.status.currentBuildName'),
      value: device?.status?.current_build_name ?? UNAVAILABLE,
    },
    {
      name: t('label.status.paused'),
      value: '' + (device?.status?.paused_by_user ?? UNAVAILABLE),
    },
    {
      name: t('label.status.currentLayer'),
      value: formatNumber(device?.status?.current_layer),
    },
    {
      name: t('label.status.currentLayerHeight'),
      value: formatNumber(
        device?.status?.current_layer_height,
        'millimeter',
        3,
      ),
    },
    {
      name: t('label.status.layers'),
      value: formatNumber(device?.status?.layer_count),
    },
    {
      name: t('label.status.elapsed'),
      value: device?.status?.elapsed
        ? humanizeDuration(device?.status?.elapsed * 1000, {
            units: ['h', 'm'],
            round: true,
            language: i18n.language,
            fallbacks: [...i18n.languages],
          })
        : UNAVAILABLE,
    },
    {
      name: t('label.status.remaining'),
      value: device?.status?.remaining
        ? humanizeDuration(device?.status?.remaining * 1000, {
            units: ['h', 'm'],
            round: true,
            language: i18n.language,
            fallbacks: [...i18n.languages],
          })
        : UNAVAILABLE,
    },
  ]

  const printerDetails = [
    {
      name: t('label.status.serialNumber'),
      value: device?.status?.serial_number ?? UNAVAILABLE,
    },
    {
      name: t('label.status.firmwareVersion'),
      value: device?.status?.firmware_version ?? UNAVAILABLE,
    },
    {
      name: t('label.status.model'),
      value: device?.status?.model ?? UNAVAILABLE,
    },
    {
      name: t('label.status.manufacturer'),
      value: device?.status?.manufacturer ?? UNAVAILABLE,
    },
  ]

  return (
    <ExtendedDetails
      loading={selectedDevice === undefined}
      close={() => {
        if (selectedDevice?.thingName) {
          store.dispatch(
            setTableChecked({
              name: tableStateName,
              checked: { [selectedDevice.thingName]: false },
            }),
          )
        }
        store.dispatch(setDrawerOpen({ name: detailsStateName, value: false }))
      }}
      title={device?.status?.name ?? UNAVAILABLE}
      subtitle={device?.thing_name ?? UNAVAILABLE}
      banner={device !== undefined ? getStatus(device, progress) : undefined}
      entries={{
        ...(device?.status?.current_build_name != null && {
          [t('details.currentBuild')]: {
            startOpen: true,
            content: <CurrentBuild device={device} />,
          },
        }),
        ...(device?.status?.queued_build_names != null &&
          device?.status?.queued_build_names.length > 0 && {
            [t('details.queuedBuild')]: {
              content: <QueuedBuilds />,
            },
          }),
        [t('details.printStatus')]: {
          startOpen: true,
          content: <DetailsGrid details={printStatusDetails} />,
        },
        [t('details.aboutPrinter')]: {
          startOpen: true,
          content: <DetailsGrid details={printerDetails} />,
        },
      }}
      footer={
        <Box
          component="div"
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        ></Box>
      }
    ></ExtendedDetails>
  )
}
