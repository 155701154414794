import { Box } from '@mui/material'
import moment from 'moment-timezone'
import React, { FC, useState } from 'react'
import { LOCAL_STORAGE_KEYS, setStorageValue } from '../../hooks/localstorage'
import {
  OnboardingDone,
  OnboardingStep1,
  OnboardingStep2,
} from '../../partials'
import {
  OrganisationIndustry,
  useCreateOrganisationApiV1OrganisationsPostMutation,
} from '../../store/clientApi'

type StageEnum = 'step1' | 'step2' | 'done'

const IndustryType = [
  'Audiology',
  'Dental',
  'Jewelry',
  'Industrial',
  'Medical',
  'Creative',
  'Microfluidics',
  'Other',
]

const token = new URL(window.location.href).searchParams.get('session_token')
const state = new URL(window.location.href).searchParams.get('state')

const OnBoarding: FC = () => {
  setStorageValue(LOCAL_STORAGE_KEYS.isNewUser, true)

  const [stage, setStage] = useState<StageEnum>('step1')
  const [userData, setUserData] = useState<UserData>({
    timezone: moment.tz.guess(),
    country: '',
    company: '',
    industry: '',
    fullName: '',
  })

  const [createOrg] = useCreateOrganisationApiV1OrganisationsPostMutation()

  const Stage = () => {
    switch (stage) {
      case 'step1':
        return (
          <OnboardingStep1
            userData={userData}
            setUserData={setUserData}
            setStage={setStage}
            IndustryType={IndustryType}
          />
        )
      case 'step2':
        return (
          <OnboardingStep2
            userData={userData}
            setUserData={setUserData}
            setStage={setStage}
            createOrg={createOrg}
            token={token}
            state={state}
          />
        )
      default:
        return <OnboardingDone />
    }
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: '90vh',
      }}
    >
      <Stage />
    </Box>
  )
}

export interface UserData {
  timezone: string
  country: string
  company: string
  industry: OrganisationIndustry | ''
  fullName: string
  phone?: string
  website?: string
}

export { OnBoarding }
