import {
  createListenerMiddleware,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import type { Action, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import toast from 'react-hot-toast'
import { ensureError, localizeError } from '../../helpers'
import i18n from '../../i18n'
import { isPartFileValid } from '../../utils/isPartFileValid'
import { clientApi, PartCreateRequest } from '../clientApi'
import { AppDispatch, RootState, store } from '../store'

enum UploadStatus {
  Pending = 0,
  UploadingToDB = 1,
  AwaitingS3 = 2,
  UploadingToS3 = 3,
  AwaitingUploaded = 4,
  MarkingAsUploaded = 5,
  Complete = 6,
}

interface PartUpload {
  uploadId: number
  partId?: number
  presignedURL?: string
  name: string
  progress: number
  status: UploadStatus
  error: boolean
  cancelled: boolean
}

interface PartUploadsState {
  [index: number]: PartUpload
}

const initialState: PartUploadsState = {}

// Store non-serialisable fields in a global constant
const uploads: {
  // Cancel token is AbortController or RTK Query abort function
  [index: number]: { file: File; cancelToken?: AbortController | (() => void) }
} = {}

const UPLOAD_BATCH_SIZE = 5 // browser impose a per-domain limit of 6-8 connections

// Listener to handle updating state
const uploadsListener = createListenerMiddleware()
const startUploadListening = uploadsListener.startListening.withTypes<
  RootState,
  AppDispatch
>()

// Type guard to check if error is AbortError
const isAbortError = (err: unknown): err is Error & { name: string } => {
  return err instanceof Error && err.name === 'AbortError'
}

let uploadIdTally = 0
const handleUploadFiles = (files: FileList | File[]) => {
  if (!files || files.length === 0) {
    return
  }
  // Validate files
  const validFiles = Array.from(files).filter((file) => isPartFileValid(file))
  // Add to files non-serialisable state
  for (let i = 0; i < validFiles.length; i++) {
    const uploadIndex = uploadIdTally++
    if (uploadIdTally === Number.MAX_VALUE) uploadIdTally = 0
    uploads[uploadIndex] = { file: validFiles[i] }

    // create part in store
    store.dispatch(
      addNewUploadPart({
        uploadId: uploadIndex,
        name: validFiles[i].name,
        progress: 0,
        status: UploadStatus.Pending,
        error: false,
        cancelled: false,
      }),
    )
  }
}

const handleRetryUpload = async (uploadId: number) => {
  const uploadToRetry = store.getState().uploads[uploadId]
  const uploadToRetryData = uploads[uploadId]

  store.dispatch(updateUploadPartError({ uploadId, error: false }))

  switch (uploadToRetry.status) {
    case UploadStatus.Pending:
    case UploadStatus.UploadingToDB:
      store.dispatch(addNewUploadPart(uploadToRetry))
      break
    case UploadStatus.AwaitingS3:
    case UploadStatus.UploadingToS3:
      if (uploadToRetry.partId) {
        // try to cancel
        if (uploadToRetryData.cancelToken) {
          try {
            if (typeof uploadToRetryData.cancelToken === 'function') {
              uploadToRetryData.cancelToken()
            } else {
              uploadToRetryData.cancelToken.abort()
            }
          } catch {
            return
          }
        }

        // get new presigned_url
        try {
          const retryUploadResponse = await store
            .dispatch(
              clientApi.endpoints.retryUploadPartApiV1PartsPartIdUploadPut.initiate(
                {
                  partId: uploadToRetry.partId,
                },
              ),
            )
            .unwrap()

          if (retryUploadResponse['presigned_url'] != null) {
            store.dispatch(
              uploadPartToDBSuccess({
                uploadId: uploadId,
                partId: uploadToRetry.partId,
                presignedURL: retryUploadResponse['presigned_url'],
              }),
            )
          }
        } catch (err) {
          if (!isAbortError) {
            const error = ensureError(err)
            toast.error(localizeError(i18n.t, error))
          }
          // Set Error in State
          store.dispatch(
            updateUploadPartError({
              uploadId: uploadId,
              error: true,
            }),
          )
        }
      }
      break
    case UploadStatus.AwaitingUploaded:
    case UploadStatus.MarkingAsUploaded:
      if (uploadToRetry.partId) {
        store.dispatch(
          uploadPartToS3Success({
            uploadId: uploadId,
            partId: uploadToRetry.partId,
          }),
        )
      }
      break
    default:
      return
  }
}

const handleCancelUpload = (uploadId: number) => {
  const upload = uploads[uploadId]

  if (upload.cancelToken) {
    // If there's a cancel token, invoke it to cancel the request
    if (typeof upload.cancelToken === 'function') {
      upload.cancelToken()
    } else {
      upload.cancelToken.abort()
    }
    // Remove the upload
    delete uploads[uploadId]
  }

  // Remove uploads from state
  store.dispatch(cancelUpload({ uploadId }))
}

const handleCancelUploads = () => {
  // stop and cancel all listeners
  uploadsListener.clearListeners()

  for (const uploadId in uploads) {
    const upload = uploads[uploadId]

    if (upload.cancelToken) {
      // If there's a cancel token, invoke it to cancel the request
      if (typeof upload.cancelToken === 'function') {
        upload.cancelToken()
      } else {
        upload.cancelToken.abort()
      }
      // Remove the upload
      delete uploads[uploadId]
    }
  }
  // Remove uploads from state
  store.dispatch(clearUploads())

  // restart listeners
  addListeners()
}

// Create redux slice
const partUploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    addNewUploadPart(state, action: PayloadAction<PartUpload>) {
      // add new part
      state[action.payload.uploadId] = action.payload
    },
    startDBUpload(state, action: PayloadAction<{ uploadId: number }>) {
      state[action.payload.uploadId].status = UploadStatus.UploadingToDB
    },
    updateUploadPartStatus(
      state,
      action: PayloadAction<{ uploadId: number; uploadStatus: UploadStatus }>,
    ) {
      // Update upload status
      const { uploadId, uploadStatus } = action.payload
      const upload = state[uploadId]
      if (upload) {
        state[uploadId].status = uploadStatus
      }
    },
    updateUploadProgress(
      state,
      action: PayloadAction<{ uploadId: number; progress: number }>,
    ) {
      const { uploadId, progress } = action.payload
      state[uploadId].progress = progress
    },
    updateUploadPartError(
      state,
      action: PayloadAction<{ uploadId: number; error: boolean }>,
    ) {
      // Set upload error
      const { uploadId, error } = action.payload
      const upload = state[uploadId]
      if (upload) {
        state[uploadId].error = error
      }
    },
    uploadPartToDBSuccess(
      state,
      action: PayloadAction<{
        uploadId: number
        partId: number
        presignedURL: string
      }>,
    ) {
      // uploaded to db successfully
      const { uploadId, partId, presignedURL } = action.payload
      const upload = state[uploadId]
      if (upload) {
        state[uploadId].partId = partId
        state[uploadId].status = UploadStatus.AwaitingS3
        state[uploadId].error = false
        state[uploadId].presignedURL = presignedURL
        state[uploadId].progress = 0
      }
    },
    uploadPartToS3Success(
      state,
      action: PayloadAction<{
        uploadId: number
        partId: number
      }>,
    ) {
      // uploaded to S3 successfully
      const { uploadId } = action.payload
      const upload = state[uploadId]
      if (upload) {
        state[uploadId].status = UploadStatus.AwaitingUploaded
      }
    },
    uploadMarkedUploadedSuccess(
      state,
      action: PayloadAction<{
        uploadId: number
        partId: number
      }>,
    ) {
      // successfully marked as uploaded - completed upload
      const { uploadId } = action.payload
      const upload = state[uploadId]
      if (upload) {
        state[uploadId].status = UploadStatus.Complete
      }
    },
    clearUploads(state, action: Action) {
      return {}
    },
    cancelUpload(
      state,
      action: PayloadAction<{
        uploadId: number
      }>,
    ) {
      delete state[action.payload.uploadId]
    },
  },
})

const addListeners = () => {
  // Listener for new uploads, create part in db

  startUploadListening({
    matcher: isAnyOf(
      partUploadsSlice.actions.addNewUploadPart,
      partUploadsSlice.actions.updateUploadPartStatus,
      partUploadsSlice.actions.updateUploadPartError,
      partUploadsSlice.actions.uploadMarkedUploadedSuccess,
      partUploadsSlice.actions.cancelUpload,
    ),
    effect: async (action, listenerApi) => {
      const uploads = listenerApi.getState().uploads

      // Return if the number of concurrent uploads have reached the batch limit
      if (
        Object.values(uploads).filter((item) => {
          const upload = item as PartUpload

          return (
            upload.error === false &&
            upload.cancelled === false &&
            (upload.status === UploadStatus.UploadingToS3 ||
              upload.status === UploadStatus.UploadingToDB)
          )
        }).length >= UPLOAD_BATCH_SIZE
      )
        return

      // Start next upload
      const nextUpload = Object.values(uploads)
        .sort((a, b) => compareUpload(a as PartUpload, b as PartUpload))
        .find(
          (upload) => (upload as PartUpload).status === UploadStatus.Pending,
        ) as PartUpload

      if (nextUpload)
        listenerApi.dispatch(
          startDBUpload({
            uploadId: nextUpload.uploadId,
          }),
        )
    },
  })

  startUploadListening({
    actionCreator: partUploadsSlice.actions.startDBUpload,
    effect: async (action, listenerApi) => {
      const newPart = listenerApi.getState().uploads[action.payload.uploadId]

      // upload part to db: create request args
      const partCreateRequestArgs: PartCreateRequest = {
        name: newPart.name,
        source: 'User upload',
        classification: 0,
      }

      // build request
      const partCreateRequest = listenerApi.dispatch(
        clientApi.endpoints.createPartApiV1PartsPost.initiate({
          partCreateRequest: partCreateRequestArgs,
        }),
      )
      // store abort controller
      uploads[newPart.uploadId].cancelToken = partCreateRequest.abort

      // execute request
      try {
        const partResponse = await partCreateRequest.unwrap()

        // update state with part id and new status
        listenerApi.dispatch(
          uploadPartToDBSuccess({
            uploadId: newPart.uploadId,
            partId: partResponse.id,
            presignedURL: partResponse.presigned_url,
          }),
        )
      } catch (err) {
        if (!isAbortError) {
          const error = ensureError(err)
          toast.error(localizeError(i18n.t, error))
        }
        // Set Error in State
        listenerApi.dispatch(
          updateUploadPartError({
            uploadId: newPart.uploadId,
            error: true,
          }),
        )
      }
    },
  })

  // Listener for new uploads, create part in db
  startUploadListening({
    actionCreator: partUploadsSlice.actions.uploadPartToDBSuccess,
    effect: async (action, listenerApi) => {
      // Part is now in DB, start upload to S3
      const newPart = action.payload
      const s3AbortController = new AbortController()
      uploads[newPart.uploadId].cancelToken = s3AbortController
      const file = uploads[newPart.uploadId].file

      const config: AxiosRequestConfig = {
        signal: s3AbortController.signal,
        headers: {
          'x-amz-server-side-encryption': 'aws:kms',
          'Content-Disposition': `attachment; filename="${file.name}"`,
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const progress = Math.min(
            (progressEvent.loaded / (progressEvent.total ?? file.size)) * 100,
            100,
          )
          listenerApi.dispatch(
            updateUploadProgress({ uploadId: newPart.uploadId, progress }),
          )
        },
      }
      if (newPart.presignedURL && newPart.partId) {
        listenerApi.dispatch(
          updateUploadPartStatus({
            uploadId: newPart.uploadId,
            uploadStatus: UploadStatus.UploadingToS3,
          }),
        )
        try {
          await axios.put(newPart.presignedURL, file, config)

          // Complete, update state status
          // update state with part id and new status
          listenerApi.dispatch(
            uploadPartToS3Success({
              uploadId: newPart.uploadId,
              partId: newPart.partId,
            }),
          )
        } catch (err) {
          if (!isAbortError) {
            const error = ensureError(err)
            toast.error(localizeError(i18n.t, error))
          }
          // Set Error in State
          listenerApi.dispatch(
            updateUploadPartError({
              uploadId: newPart.uploadId,
              error: true,
            }),
          )
        }
      }
    },
  })

  // Listener for S3 completion
  startUploadListening({
    actionCreator: partUploadsSlice.actions.uploadPartToS3Success,
    effect: async (action, listenerApi) => {
      const newPart = action.payload

      if (newPart.partId === undefined) {
        return
      }

      // Mark part as uploaded in db
      // Set state as 'MarkingAsUploaded'
      listenerApi.dispatch(
        updateUploadPartStatus({
          uploadId: newPart.uploadId,
          uploadStatus: UploadStatus.MarkingAsUploaded,
        }),
      )

      // build request
      const partMarkUploadedRequest = listenerApi.dispatch(
        clientApi.endpoints.markPartUploadedApiV1PartsPartIdUploadedPut.initiate(
          {
            partId: newPart.partId,
          },
        ),
      )
      // store abort controller
      uploads[newPart.uploadId].cancelToken = partMarkUploadedRequest.abort

      // execute request
      try {
        const partResponse = await partMarkUploadedRequest.unwrap()

        // update state with part id and new status
        listenerApi.dispatch(
          uploadMarkedUploadedSuccess({
            uploadId: newPart.uploadId,
            partId: partResponse.id,
          }),
        )
      } catch (err) {
        if (!isAbortError) {
          const error = ensureError(err)
          toast.error(localizeError(i18n.t, error))
        }
        // Set Error in State
        listenerApi.dispatch(
          updateUploadPartError({
            uploadId: newPart.uploadId,
            error: true,
          }),
        )
      }
    },
  })

  // Listener for upload completion (for clean-up)
  startUploadListening({
    actionCreator: partUploadsSlice.actions.uploadMarkedUploadedSuccess,
    effect: async (action, listenerApi) => {
      const newPart = action.payload

      // remove non-serialisable data from uploads
      delete uploads[newPart.uploadId]
    },
  })
}

addListeners()

// Upload compare function that sorts uploads by upload ID and places completed uploads last.
export const compareUpload = (a: PartUpload, b: PartUpload) => {
  if (a.status === UploadStatus.Complete && b.status !== UploadStatus.Complete)
    return 1
  if (a.status !== UploadStatus.Complete && b.status === UploadStatus.Complete)
    return -1
  return a.uploadId - b.uploadId
}

export const {
  addNewUploadPart,
  startDBUpload,
  updateUploadPartStatus,
  uploadPartToDBSuccess,
  updateUploadProgress,
  updateUploadPartError,
  uploadPartToS3Success,
  uploadMarkedUploadedSuccess,
  clearUploads,
  cancelUpload,
} = partUploadsSlice.actions

export { uploadsListener }
export {
  handleUploadFiles,
  handleRetryUpload,
  handleCancelUploads,
  handleCancelUpload,
  UploadStatus,
}
export type { PartUpload, PartUploadsState }
export default partUploadsSlice.reducer
