import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type UnderConstructionProps = Record<string, never>

const UnderConstruction: FC<UnderConstructionProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box component="div">
        <img
          alt={t('common:links.inbox')}
          style={{ width: '100%' }}
          src="/images/under-construction.svg"
        />

        <Typography variant="h4" textAlign={'center'} margin={'1em 0'}>
          {t('common:underConstruction.subtitle1')}
        </Typography>
        <Typography variant="h4" textAlign={'center'}>
          {t('common:underConstruction.subtitle2')}
        </Typography>
      </Box>
    </Box>
  )
}

export { UnderConstruction }
