import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import { Checkbox, CheckboxProps, Icon, styled } from '@mui/material'

const OutlinedChecked = (props: CheckboxProps) => {
  return (
    <Checkbox
      checkedIcon={
        <Icon
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CheckBoxOutlinedIcon />
        </Icon>
      }
      icon={
        <Icon
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CheckBoxOutlineBlankOutlinedIcon />
        </Icon>
      }
      indeterminateIcon={
        <Icon
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IndeterminateCheckBoxOutlinedIcon />
        </Icon>
      }
      {...props}
    />
  )
}

const StyledCheckbox = styled(OutlinedChecked)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.text.primary,
  },
  '&.Mui-checked > span svg': {
    color: theme.palette.text.primary,
  },
  '&.MuiCheckbox-indeterminate > span  svg': {
    color: theme.palette.text.primary,
  },
}))
export { StyledCheckbox as Checkbox }
