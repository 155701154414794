import { Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { JobStatus } from '../../store/clientApi'
import {
  CancelledSvg,
  CompleteSvg,
  ErrorOutlineSvg,
  OfflineReworkSvg,
  ToDoSvg,
  UnknownSvg,
} from '../Icon/Icon'

interface JobStatusIndicatorProps {
  jobStatus: JobStatus
  iconOnly?: boolean
}

const JobStatusIndicator = ({
  jobStatus,
  iconOnly,
}: JobStatusIndicatorProps) => {
  const { t } = useTranslation('jobs')
  const jobStatusToName = (status: JobStatus) => {
    switch (status) {
      case JobStatus.PENDING:
      case JobStatus.CREATED:
      case JobStatus.PROCESSING:
        return t('status.generating')
      case JobStatus.TO_ACCEPT:
        return t('status.awaitingApproval')
      case JobStatus.PREPARING_TO_SEND:
        return t('status.prepareToSend')
      case JobStatus.PRINT_PENDING:
        return t('status.printPending')
      case JobStatus.PRINTING:
        return t('status.printing')
      case JobStatus.PRINTED:
        return t('status.printed')
      case JobStatus.FAILED:
        return t('status.failed')
      case JobStatus.REJECTED:
      case JobStatus.CANCELLED:
        return t('status.cancelled')
      case JobStatus.OFFLINE:
        return t('status.offline')
    }
  }

  const jobStatusToIcon = (status: JobStatus) => {
    switch (status) {
      case JobStatus.PREPARING_TO_SEND:
      case JobStatus.PENDING:
      case JobStatus.CREATED:
      case JobStatus.PROCESSING:
        return (
          <CircularProgress
            size={'1.3em'}
            thickness={4.5}
            color="warning"
            sx={{
              padding: '0.1em',
              '&& > svg': { color: (theme) => theme.palette.warning.main },
            }}
          />
        )
      case JobStatus.TO_ACCEPT:
        return (
          <Box
            component="div"
            sx={{
              '& > svg': {
                color: (theme) => theme.palette.warning.main + '!important',
              },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <UnknownSvg width="1.3em" height="1.3em" />
          </Box>
        )
      case JobStatus.PRINT_PENDING:
        return (
          <Box
            component="div"
            sx={{
              '& > svg': {
                color: (theme) => theme.palette.success.main + '!important',
              },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ToDoSvg width="1.3em" height="1.3em" />
          </Box>
        )
      case JobStatus.PRINTING:
        return (
          <CircularProgress
            size={'1.3em'}
            thickness={4.5}
            color="success"
            sx={{
              padding: '0.1em',
              '&& > svg': { color: (theme) => theme.palette.success.main },
            }}
          />
        )
      case JobStatus.PRINTED:
        return (
          <Box
            component="div"
            sx={{
              '& > svg': {
                color: (theme) => theme.palette.success.main + '!important',
              },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CompleteSvg width="1.3em" height="1.3em" />
          </Box>
        )
      case JobStatus.FAILED:
        return (
          <Box
            component="div"
            sx={{
              '& > svg': {
                color: (theme) => theme.palette.error.main + '!important',
              },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ErrorOutlineSvg width="1.3em" height="1.3em" />
          </Box>
        )
      case JobStatus.REJECTED:
      case JobStatus.CANCELLED:
        return (
          <Box
            component="div"
            sx={{
              '& > svg': {
                color: (theme) => theme.palette.primary.main + '!important',
              },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CancelledSvg width="1.3em" height="1.3em" />
          </Box>
        )
      case JobStatus.OFFLINE:
        return (
          <Box
            component="div"
            sx={{
              '& > svg': {
                color: (theme) => theme.palette.primary.main + '!important',
              },
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <OfflineReworkSvg width="1.3em" height="1.3em" />
          </Box>
        )
    }
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '6px',
      }}
    >
      {jobStatusToIcon(jobStatus)}
      {!iconOnly && jobStatusToName(jobStatus)}
    </Box>
  )
}

export { JobStatusIndicator }
