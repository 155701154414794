/* eslint i18next/no-literal-string: 0 */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: `${import.meta.env.VITE_I18NEXT_BASE_URL}/{{lng}}/{{ns}}.json`,
    },
    detection: {
      order: ['querystring', 'cookie', 'navigator'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: 'en',
    ns: ['common', 'users', 'onboarding', 'inbox', 'jobs', 'apps', 'timeline'],
    defaultNS: 'common',
    fallbackNS: 'common',
    lowerCaseLng: true,
    nonExplicitSupportedLngs: true,
    ...(import.meta.env.VITE_ENABLE_I18NEXT_DEBUG && { debug: true }),
  })

export default i18n
