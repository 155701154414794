export enum partStatus {
  'uploadPending' = 0, // The part is waiting to be uploaded
  'uploaded' = 1, // The part has been uploaded
  'preProcessing' = 2, // The part is going through pre-processing
  'toConfirm' = 3, // Part awaiting designer confirmation (finished pre-processing)
  'toAccept' = 4, // Part confirmed, awaiting accept or reject
  'accepted' = 5, // Part accepted
  'rejected' = 6, // The part has been rejected
  'processing' = 7, // The part is being processed in the workflow
  'awaitingPlacement' = 8, // The part is awaiting placement into a build
  'placing' = 9, // The part is in the process of being placed into a build
  'placed' = 10, // The part has been placed into a build
  'printing' = 11, // The part is being printed
  'printed' = 12, // The part has been printed
  'failed' = 13, // The part has failed to print
  'offline' = 14, // Part moved offline with a job
}
