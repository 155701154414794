import {
  Box,
  Drawer,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import {
  selectDrawerOpen,
  setDrawerOpen,
} from '../../store/slices/rightDrawerSlice'
import { RootState, store } from '../../store/store'

interface RightDrawerProps {
  stateName: string
  width: string
  shift?: boolean
  sx?: SxProps<Theme>
  padding?: string
}

const RightDrawer = ({
  stateName,
  width,
  shift,
  sx,
  padding,
  children,
}: PropsWithChildren<RightDrawerProps>) => {
  const theme = useTheme()
  const sm_down = useMediaQuery(theme.breakpoints.down('sm'))
  const open = useSelector((state: RootState) =>
    selectDrawerOpen(state, stateName),
  )

  return sm_down ? (
    <Drawer
      anchor="right"
      open={open}
      onClose={() =>
        store.dispatch(setDrawerOpen({ name: stateName, value: false }))
      }
      sx={{
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          boxSizing: 'border-box',
          ...(shift && { marginTop: ['56px', 'unset'] }),
          width: ['100%', 'unset'],
          maxHeight: shift ? 'calc(100vh - 56px)' : '100vh',
        },
        ...(sx && sx),
        zIndex: (theme) => [theme.zIndex.drawer - 1, theme.zIndex.drawer + 1],
      }}
      elevation={2}
    >
      <Box
        component="div"
        sx={{
          padding: padding ?? '1em',
          width: ['100%'],
          height: shift ? 'calc(100vh - 56px)' : '100vh',
          maxHeight: shift ? 'calc(100vh - 56px)' : '100vh',
        }}
      >
        {children}
      </Box>
    </Drawer>
  ) : (
    <Box
      component="div"
      sx={{
        width: width,
        height: '100%',
        ...(sx && sx),
        bgcolor: (theme: Theme) => theme.surface.low,
        padding: '0',
        borderRadius: '1em',
        display: open ? 'box' : 'none',
      }}
    >
      {children}
    </Box>
  )
}

export type { RightDrawerProps }
export { RightDrawer }
