import { alpha, createTheme, Shadows } from '@mui/material/styles'
import './css/Montserrat.css'

declare module '@mui/material/styles' {
  interface Theme {
    surface: {
      lowest: string
      low: string
      normal: string
      high: string
      highest: string
      on: string
      onVariant: string
    }
    model: {
      model: string
      light: number
    }
    inverse: {
      surface: string
      contrastText: string
    }
    outline: {
      main: string
      focus: string
    }
    progress: {
      shadows: {
        fill: string
        empty: string
      }
    }
  }
  interface ThemeOptions {
    surface?: {
      lowest?: string
      low?: string
      normal?: string
      high?: string
      highest?: string
      on?: string
      onVariant?: string
    }
    model?: {
      model?: string
      light?: number
    }
    inverse?: {
      surface?: string
      contrastText?: string
    }
    outline?: {
      main?: string
      focus?: string
    }
    progress?: {
      shadows?: {
        fill?: string
        empty?: string
      }
    }
  }
}

const defaultTheme = createTheme()

// Custom theme taken from https://asiga.atlassian.net/wiki/spaces/UiUX/pages/90112055/Colour+Theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#202324', // primary
      contrastText: '#FFFFFF', // on-primary
    },
    secondary: {
      main: '#ECECEC', // secondary
      contrastText: '#202324', // on-secondary
    },
    info: {
      main: '#00AEEF', // asiga-blue
      light: alpha('#00AEEF', 0.08), // asiga-blue-container
    },
    success: {
      main: '#30D158', // green
      light: alpha('#30D158', 0.08), // green-fill
    },
    warning: {
      main: '#FFB800', // amber
      light: alpha('#FFB800', 0.08), //amber-container
    },
    error: {
      main: '#FD4A46', // error
      contrastText: '#FFFFFF', // on-error
      light: alpha('#FD4A46', 0.08), // error-container
    },
    background: {
      default: '#FFFFFF', // surface-container-lowest
      paper: '#F5F5F5', // surface-container
    },
    text: {
      primary: '#202324', // on-surface
      secondary: alpha('#202324', 0.6), // on-surface-variant
    },
    action: {
      focus: alpha('#F316B0', 0.4), // focus
    },
  },
  surface: {
    lowest: '#FFFFFF', // surface-container-lowest
    low: '#FAFAFA', // surface-container-low
    normal: '#F5F5F5', // surface-container
    high: '#DEDEDE', // surface-container-high
    highest: '#C2C2C2', // surface-container-highest
    on: '#202324', // on-surface
    onVariant: alpha('#202324', 0.6), // on-surface-variant
  },
  model: {
    model: '#FFAA00',
    light: 0xcccccc,
  },
  inverse: {
    surface: '#00344B', // inverse-surface
    contrastText: '#FBFCFF', // inverse-on-surface
  },
  outline: {
    main: '#C1C7CE', // outline-variant
  },
  progress: {
    shadows: {
      fill: `0px -4px 4px 0px inset ${alpha('#000000', 0.1)}, 0px 4px 4px 0px inset ${alpha('#FFFFFF', 0.1)}`, // progress bar fill
      empty: `0px -4px 4px 0px inset ${alpha('#FFFFFF', 0.1)}, 0px 4px 4px 0px inset ${alpha('#000000', 0.1)}`, // progress bar empty
    },
  },
  shadows: [
    'none',
    `0px 1px 2px 0px ${alpha('#000000', 0.3)}, 0px 1px 3px 1px ${alpha('#000000', 0.15)}`, // level 1 shadow
    `0px 1px 2px 0px ${alpha('#000000', 0.3)}, 0px 2px 6px 2px ${alpha('#000000', 0.15)}`, // level 2 shadow
    ...defaultTheme.shadows.slice(3),
  ] as Shadows,
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeightRegular: 400,
    fontWeightBold: 500,
    h1: {
      fontSize: '1.5em',
      fontWeight: 500,
      width: '100%',
    },
    h2: {
      fontSize: '1.3em',
      width: '100%',
    },
    h4: {
      fontSize: '1.1em',
      width: '100%',
      color: '#20232499',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          boxShadow: 'none',
        }),
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1200,
      xl: 1536,
    },
  },
})

export default theme
