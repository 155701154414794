import { Box, useTheme } from '@mui/material'
import { Color } from '../../helpers'

interface StatusIconProps {
  variant: Color
  message: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  icon?: React.ReactNode
}

const StatusIcon = ({ variant, message, onClick, icon }: StatusIconProps) => {
  const theme = useTheme()
  return (
    <Box
      component="div"
      onClick={onClick}
      sx={{
        color: theme.palette[variant].main,
        backgroundColor: theme.palette[variant].light,
        borderColor: theme.palette[variant].main,
        borderRadius: '1em',
        borderWidth: '2px',
        borderStyle: 'solid',
        textAlign: 'center',
        paddingTop: '0.3em',
        paddingBottom: '0.3em',
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
        width: 'fit-content',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        gap: '0.3em',
      }}
    >
      {icon}
      <div>{message}</div>
    </Box>
  )
}

export { StatusIcon }
