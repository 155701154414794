import { useTheme } from '@mui/material'
import { useBounds } from '@react-three/drei'
import { FC, useEffect, useState } from 'react'
import { BufferGeometry, DoubleSide } from 'three'
import * as THREE from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

interface Props {
  file?: ArrayBuffer
  visible?: boolean
}

export const Model: FC<Props> = ({ file }) => {
  const theme = useTheme()
  const [geometry, setGeometry] = useState<BufferGeometry | undefined>()
  const bounds = useBounds()

  useEffect(() => {
    bounds.refresh().clip().fit()
  }, [geometry, bounds])

  useEffect(() => {
    const loadSTLFile = async () => {
      if (file) {
        try {
          const stlLoader = new STLLoader()
          const geo = stlLoader.parse(file)
          setGeometry(geo)
        } catch (error) {
          setGeometry(undefined)
        }
      } else {
        setGeometry(undefined)
      }
    }

    loadSTLFile()
  }, [file])

  return (
    <>
      {geometry && (
        <mesh
          position={[0, 0, 0]}
          geometry={geometry}
          material={
            new THREE.MeshStandardMaterial({
              vertexColors: geometry?.hasAttribute('color') ? true : false,
              side: DoubleSide,
              roughness: 0.5,
              color: theme.model.model,
            })
          }
        />
      )}
    </>
  )
}
