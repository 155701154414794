import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  MAX_DEVICE_POLLING_TO_CAPTURE,
  pollingCounter,
  UNWRAPPED_FETCH,
} from '..'
import { sec } from './security'

const HS256AuthEndpoints = ['createOrganisationApiV1OrganisationsPost']

export const emptyApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL ?? '/',
    fetchFn: async (input, init) => {
      if (pollingCounter >= MAX_DEVICE_POLLING_TO_CAPTURE) {
        if (
          (typeof input === 'string' &&
            input.includes(
              'https://api.dev.asiga.com/api/v1/devices/status',
            )) ||
          ((input as any)?.url ?? '').includes(
            'https://api.dev.asiga.com/api/v1/devices/status',
          )
        ) {
          return await UNWRAPPED_FETCH(input, init)
        }
      }
      return await fetch(input, init)
    },
    prepareHeaders: async (headers, { endpoint }) => {
      let access_token = undefined
      if (HS256AuthEndpoints.includes(endpoint)) {
        access_token = new URL(window.location.href).searchParams.get(
          'session_token',
        )
      } else {
        access_token = await sec.getAccessTokenSilently()()
      }
      if (access_token) {
        headers.set('Authorization', `Bearer ${access_token}`)
      }

      return headers
    },
  }),
  endpoints: () => ({}),
})
