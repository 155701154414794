import { Box } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import React, { FC, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  clientApi,
  DeviceStatusPaginatedListRead,
  DeviceStatusRead,
} from '../../../store/clientApi'
import { selectRtkData } from '../../../store/slices/tableSlice'
import { RootState } from '../../../store/store'
import { TIMELINE_TABLE_STATE_NAME } from '../Timeline'
import { TimelineRow } from '../TimelineRow/TimelineRow'

interface TimelineRowsProps {
  tab: string | false
  hoursViewWidth: number
  timelineHours: number
}

export const TimelineRows: FC<TimelineRowsProps> = ({
  tab,
  hoursViewWidth,
  timelineHours,
}) => {
  const selectThingNamesFromDevices = useMemo(() => {
    const emptyArray: DeviceStatusRead[] = []
    return createSelector(
      [(res?: DeviceStatusPaginatedListRead) => res?.content ?? emptyArray],
      (content) =>
        content
          .filter((device) =>
            tab === 'attention' ? device.status?.state === 'error' : true,
          )
          .map((device) => device.thing_name),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [tab])

  const deviceThingNames = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return selectThingNamesFromDevices(query?.data)
  })
  return (
    <>
      {deviceThingNames.map((deviceName, index) => (
        <Box
          key={`${deviceName}-${index}`}
          component="div"
          sx={{
            flexGrow: '1',
            width: `${hoursViewWidth}px`,
            position: 'relative',
            height: '98px',
          }}
        >
          <TimelineRow
            deviceName={deviceName}
            timelineHours={timelineHours}
            timelineHoursWidth={hoursViewWidth}
          />
        </Box>
      ))}
    </>
  )
}
