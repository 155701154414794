import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface DownloadingPartsState {
  isDownloading: boolean
}

const initialState: DownloadingPartsState = {
  isDownloading: false,
} satisfies DownloadingPartsState
const downloadingPartsSlice = createSlice({
  name: 'downloadingParts',
  initialState,
  reducers: {
    setIsDownloading(state, action: PayloadAction<boolean>) {
      state.isDownloading = action.payload
    },
  },
})

export const { setIsDownloading } = downloadingPartsSlice.actions
export default downloadingPartsSlice.reducer
