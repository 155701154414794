import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface TimelineDurationState {
  hours: number
}

const initialState = { hours: 0 } satisfies TimelineDurationState
const timelineDurationSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setTimelineDuration(state, action: PayloadAction<number>) {
      state.hours = action.payload
    },
  },
})

export const { setTimelineDuration } = timelineDurationSlice.actions
export default timelineDurationSlice.reducer
