import { createSelector } from '@reduxjs/toolkit'
import React, { FC, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  clientApi,
  DeviceStatusPaginatedListRead,
  DeviceStatusRead,
} from '../../../store/clientApi'
import { setDrawerOpen } from '../../../store/slices/rightDrawerSlice'
import {
  selectRtkData,
  setTableChecked,
} from '../../../store/slices/tableSlice'
import { RootState, store } from '../../../store/store'
import {
  TIMELINE_DRAWER_STATE_NAME,
  TIMELINE_TABLE_STATE_NAME,
} from '../Timeline'
import { TimelinePrinter } from '../TimelinePrinter/TimelinePrinter'

interface TimelinePrintersProps {
  tab: string | false
}

export const TimelinePrinters: FC<TimelinePrintersProps> = ({ tab }) => {
  const selectThingNamesFromDevices = useMemo(() => {
    const emptyArray: DeviceStatusRead[] = []
    return createSelector(
      [(res?: DeviceStatusPaginatedListRead) => res?.content ?? emptyArray],
      (content) =>
        content
          .filter((device) =>
            tab === 'attention' ? device.status?.state === 'error' : true,
          )
          .map((device) => device.thing_name),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [tab])

  const deviceThingNames = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return selectThingNamesFromDevices(query?.data)
  })

  return (
    <>
      {deviceThingNames.map((thingName, index) => {
        return (
          <TimelinePrinter
            thingName={thingName}
            key={`build-${thingName}-${index}`}
            onClick={() => {
              store.dispatch(
                setDrawerOpen({
                  name: TIMELINE_DRAWER_STATE_NAME,
                  value: true,
                }),
              )
              store.dispatch(
                setTableChecked({
                  name: TIMELINE_TABLE_STATE_NAME,
                  checked: { [thingName]: true },
                }),
              )
            }}
          />
        )
      })}
    </>
  )
}
