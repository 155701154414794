import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { CloseSvg, CollapsedSvg, ExpandedSvg } from '../Icon/Icon'

interface DetailsGridProps {
  details: { name: string; value: string }[]
}

interface ExtendedDetailsProps {
  close?: () => void
  title: React.ReactNode
  subtitle?: string
  entries?: Entries
  banner?: React.ReactNode
  footer?: React.ReactNode
  loading?: boolean
}

type Entries = {
  [id: string]: {
    startOpen?: boolean
    content: React.ReactNode
    hideHeader?: boolean
  }
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<CollapsedSvg color="inherit" />}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
}))

const ExtendedDetails = ({
  close,
  title,
  subtitle,
  entries,
  banner,
  footer,
  loading,
}: ExtendedDetailsProps) => {
  const theme = useTheme()
  const Banner = () => <>{banner}</>
  const Footer = () => <>{footer}</>
  return loading === false ? (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
        marginBottom: '0.5em',
        height: '100%',
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1em',
          boxSizing: 'border-box',
          borderBottom: `1px solid ${theme.palette.divider}`,
          flexGrow: 0,
        }}
      >
        <Box component="div">
          <Typography fontSize={'1.1em'} variant="h1">
            {title}
          </Typography>
          {subtitle && (
            <Typography fontSize={'1.0em'} variant="subtitle1">
              {subtitle}
            </Typography>
          )}
        </Box>
        <IconButton onClick={close}>
          <CloseSvg color="inherit" />
        </IconButton>
      </Box>
      <Banner />
      <Box
        component="div"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        {entries &&
          Object.entries(entries).length !== 0 &&
          Object.entries(entries)
            .map<React.ReactNode>(([name, entry]) => {
              return (
                <Accordion
                  key={`section-${name}`}
                  defaultExpanded={entry.startOpen}
                  sx={{ bgcolor: theme.surface.low }}
                >
                  {!entry.hideHeader && (
                    <AccordionSummary
                      expandIcon={<ExpandedSvg color="inherit" />}
                      aria-controls="panel1-content"
                      id={`${name}-header`}
                    >
                      {name}
                    </AccordionSummary>
                  )}
                  <AccordionDetails>{entry.content}</AccordionDetails>
                </Accordion>
              )
            })
            .reduce((prev, curr, index) => [
              prev,
              <Divider key={`divider-${index}`} />,
              curr,
            ])}
      </Box>
      <Box
        component="div"
        sx={{
          flexGrow: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '1em',
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Footer />
      </Box>
    </Box>
  ) : (
    <LinearProgress />
  )
}

const DetailsGrid = ({ details }: DetailsGridProps) => {
  const rows = []
  for (let i = 0; i < details.length; i += 2) {
    rows.push(details.slice(i, i + 2))
  }

  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
      {rows.map((details, index) => {
        return (
          <Box
            component="div"
            key={`row-${index}`}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginBottom: '1em',
            }}
          >
            {details.map((detail, index) => {
              return (
                <Box
                  component="div"
                  key={`item-${index}`}
                  sx={{ width: '100%' }}
                >
                  <Typography fontSize={'0.9em'} variant="h1">
                    {detail.name}
                  </Typography>
                  <Typography fontSize={'0.8em'} variant="subtitle1">
                    {detail.value}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

export { ExtendedDetails, DetailsGrid }
