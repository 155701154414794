import { CircularProgress, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useGetTemplateApiV1TemplatesTemplateIdGetQuery } from '../../store/clientApi'

interface PartTemplateProps {
  templateId: number
}

export const PartTemplate: FC<PartTemplateProps> = ({ templateId }) => {
  const { data, isLoading } = useGetTemplateApiV1TemplatesTemplateIdGetQuery(
    {
      templateId: templateId,
    },
    {
      selectFromResult: ({ data, isLoading }) => ({
        isLoading,
        data,
      }),
    },
  )

  if (isLoading) {
    return <CircularProgress size={14} />
  }

  if (data?.name) {
    return <Typography>{data.name}</Typography>
  }

  return <Typography>-</Typography>
}
