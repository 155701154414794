import { Menu, MenuProps, useTheme } from '@mui/material'
import { ReactNode } from 'react'
interface ExtraProps {
  hideArrow?: boolean
  noPadding?: boolean
  borderRadius?: string | number
  innerProps: MenuProps
  children: ReactNode
}
const StyledMenu = (props: ExtraProps) => {
  const theme = useTheme()
  return (
    <Menu
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            boxShadow: (theme) => theme.shadows[1],
            color: (theme) => theme.surface.on,
            mt: 1.5,
            '& > ul': {
              paddingTop: {
                sm: '2px',
                md: '4px',
                lg: '8px',
              },
              paddingBottom: {
                sm: '2px',
                md: '4px',
                lg: '8px',
              },
            },
            '& > ul li:not(:first-of-type)': {
              marginTop: {
                sm: '0px',
                md: '2px',
                lg: '4px',
              },
            },
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            ...(!props.hideArrow && {
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.default',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            }),
            bgcolor: theme.palette.background.default,
            borderRadius: props.borderRadius ?? '1em',
            padding: props.noPadding ? '0px' : '0.5em',
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      {...props?.innerProps}
      children={props.children}
    />
  )
}

export { StyledMenu as Menu }
