export enum partClassification {
  'other' = 0,
  'hollowModel' = 1,
  'solidModel' = 2,
  'crownOrToothOrVeneer' = 3,
  'screwInTooth' = 4,
  'die' = 5,
  'flatGingiva' = 6,
  'pillowGingiva' = 7,
  'teethRow' = 8,
  'dentureBase' = 9,
  'denturePartial' = 10,
  'tryInDenture' = 11,
  'occlusalSplint' = 12,
  'surgicalGuide' = 13,
  'bridge' = 14,
  'partialFramework' = 15,
}
