import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { CloseSvg } from '../Icon/Icon'

interface InboxActionBarProps {
  handleCloseActionBar: () => void
  selector: (state: RootState) => number
  children?: React.ReactNode
}

const ActionBarInfo = ({
  selector,
}: {
  selector: (state: RootState) => number
}) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const breakpointLg = useMediaQuery(theme.breakpoints.up('lg'))
  const numChecked = useSelector(selector)

  return (
    <Typography>
      {breakpointLg &&
        t('label.itemSelected', {
          count: numChecked,
        })}
    </Typography>
  )
}

export const ActionBar: FC<InboxActionBarProps> = ({
  handleCloseActionBar,
  selector,
  children,
}) => {
  const theme = useTheme()

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
        height: '52px',
        padding: '6px',
        borderRadius: '26px',
        gap: '8px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <IconButton
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation()
          handleCloseActionBar()
        }}
      >
        <CloseSvg color="inherit" />
      </IconButton>
      <ActionBarInfo selector={selector} />
      <Divider orientation="vertical" flexItem />
      {children}
    </Box>
  )
}
