import { useEffect, useState } from 'react'

export const setStorageValue = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getStorageValue = <T>(key: string, defaultValue: T) => {
  const saved = localStorage.getItem(key)

  if (saved == null) {
    localStorage.setItem(key, JSON.stringify(defaultValue))
    return defaultValue
  }

  return JSON.parse(saved) as T
}

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return { value, setValue }
}

export const LOCAL_STORAGE_KEYS = {
  isNewUser: 'asigaIsNewUser',
}
