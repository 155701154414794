import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from '@mui/material'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import React, { FC, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Logo } from '../components'
import { ensureError, localizeError } from '../helpers'
import { UserData } from '../pages/OnBoarding'

interface Props {
  userData: UserData
  setUserData: React.Dispatch<React.SetStateAction<any>>
  setStage: React.Dispatch<React.SetStateAction<any>>
  createOrg: any
  token: string | null
  state: string | null
}

const OnboardingStep2: FC<Props> = ({
  userData,
  setUserData,
  setStage,
  createOrg,
  token,
  state,
}) => {
  const { t } = useTranslation('onboarding')
  const [fullName, setFullName] = useState(userData.fullName ?? '')
  const [fullNameError, setFullNameError] = useState('')
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const handleOnChangeFullName = (nameValue: string) => {
    if (nameValue === '') setFullNameError('Field is required')
    else setFullNameError('')

    setFullName(nameValue)
  }

  const next = async () => {
    setLoadingSubmit(true)
    if (fullName === '') setFullNameError('Field is required')
    else setFullNameError('')

    if (fullName !== '') {
      const newData: UserData = {
        ...userData,
        fullName: fullName,
      }
      setUserData(newData)

      try {
        await createOrg({
          organisationCreateRequestDto: {
            name: newData.company,
            industry: newData.industry,
          },
        }).unwrap()

        const decoded = jwtDecode<JwtPayload & { continue_uri: string }>(
          token ?? '',
        )

        setStage('done')
        setTimeout(() => {
          window.location.href = `${decoded.continue_uri}?state=${state}`
        }, 1000)
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
        setLoadingSubmit(false)
      }
    }
    setLoadingSubmit(false)
  }

  return (
    <Card id="step2">
      <CardContent sx={{ maxWidth: '450px' }}>
        <Box component="div" sx={{ marginBottom: '1.5em', marginTop: '1.5em' }}>
          <Logo />
        </Box>
        <Typography variant="h1" fontSize={'1em'} textAlign={'center'}>
          {t('message.completePersonalDetails')}
        </Typography>
        <Divider sx={{ marginBottom: '0.8em', marginTop: '0.8em' }} />
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '0.8em',
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="full-name"
            label={t('common:label.fullname')}
            variant="outlined"
            error={!!fullNameError}
            value={fullName}
            onChange={(e) => handleOnChangeFullName(e.target.value)}
            required
          />
          <Box
            component="div"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button onClick={() => setStage('step1')}>
              {t('common:button.back')}
            </Button>
            <LoadingButton
              loading={loadingSubmit}
              type="submit"
              variant="contained"
              onClick={(e) => {
                e.preventDefault()
                next()
              }}
            >
              {t('common:button.continue')}
            </LoadingButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default OnboardingStep2
