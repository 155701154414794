import React, {
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { TIMELINE_DRAWER_STATE_NAME } from '../../pages/Timeline'
import { selectDrawerOpen } from '../../store/slices/rightDrawerSlice'
import { RootState } from '../../store/store'

const VERTICAL_PADDING = 32

export const StickyScrollbar = forwardRef<
  {
    scrollViewportRef: RefObject<HTMLDivElement | null>
    timelineRef: RefObject<HTMLDivElement | null>
  },
  { viewHours: number }
>(({ viewHours }, ref) => {
  const scrollViewportRef = useRef<HTMLDivElement | null>(null)
  const timelineRef = useRef<HTMLDivElement | null>(null)

  //Initialise refs
  useImperativeHandle(ref, () => ({
    scrollViewportRef: scrollViewportRef,
    timelineRef: timelineRef,
  }))
  const scrollbarRef = useRef<HTMLDivElement | null>(null)
  const [viewportDiv, setViewportDiv] = useState<HTMLDivElement | undefined>(
    undefined,
  )
  const [stickyScrollWidth, setStickyScrollWidth] = useState<{
    width: number
    scrollWidth: number
  }>({ width: 0, scrollWidth: 0 })
  const [isRegularScrollbarVisible, setIsRegularScrollbarVisible] =
    useState<boolean>(false)
  const [containerScrollState, setContainerScrollState] = useState<
    | { scrollHeight: number; scrollTop: number; offsetHeight: number }
    | undefined
  >(undefined)

  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const open = useSelector((state: RootState) =>
    selectDrawerOpen(state, TIMELINE_DRAWER_STATE_NAME),
  )

  useEffect(() => {
    // For re-render on right drawer toggle & viewport hours changes
    forceUpdate()
  }, [open, viewHours])

  useEffect(() => {
    if (scrollViewportRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const target = entry.target as HTMLElement // Cast to HTMLElement
          setStickyScrollWidth({
            width: target.clientWidth,
            scrollWidth: target.scrollWidth,
          })
        }
      })
      observer.observe(scrollViewportRef.current)

      // Cleanup function
      return () => {
        observer.disconnect()
      }
    }
  }, [scrollViewportRef.current]) // eslint-disable-line

  useEffect(() => {
    /** Update sticky scrollbar offset when user scrolls default scrollbar */
    if (viewportDiv) {
      const onScroll = () => {
        if (scrollbarRef !== null && scrollbarRef.current !== null) {
          scrollbarRef.current.scrollLeft = viewportDiv.scrollLeft
        }
      }

      viewportDiv.addEventListener('scroll', onScroll)
      return () => viewportDiv.removeEventListener('scroll', onScroll)
    }
  }, [viewportDiv])

  const handleOnScrollbarChange = (e: React.UIEvent<HTMLDivElement>) => {
    /** When the sticky scrollbar is scrolled, updated the original scrollbar */
    const scrollLeft = e.currentTarget.scrollLeft
    if (viewportDiv === undefined) {
      const viewportContainer = document.getElementById(
        'scroll-viewport',
      ) as HTMLDivElement | null
      if (viewportContainer !== null) {
        viewportContainer.scrollLeft = scrollLeft
        setViewportDiv(viewportContainer)
      }
    } else {
      viewportDiv.scrollLeft = scrollLeft
    }
  }

  const handleVerticalScroll = (event: Event) => {
    /** Get vertical scroll offset used to check if sticky scrollbar is visible */
    if (!(event.target instanceof HTMLElement)) return
    const { scrollHeight, scrollTop, offsetHeight } =
      event.target as HTMLElement
    setContainerScrollState({ scrollHeight, scrollTop, offsetHeight })
  }

  useEffect(() => {
    /** Initialise scroll items */
    const scrollContainer = document.getElementById(
      'sticky-scroll-container',
    ) as HTMLDivElement | null
    if (scrollContainer !== null) {
      scrollContainer.addEventListener('scroll', handleVerticalScroll)
    }

    // Cleanup event listener on component unmount
    return () => {
      if (scrollContainer !== null) {
        scrollContainer.removeEventListener('scroll', handleVerticalScroll)
      }
    }
  }, [])

  useEffect(() => {
    /** Determine if sticky scrollbar is visible or hidden based on vertical scroll offset */
    if (containerScrollState === undefined) return

    if (
      containerScrollState.scrollTop +
        containerScrollState.offsetHeight +
        VERTICAL_PADDING <
        containerScrollState.scrollHeight &&
      isRegularScrollbarVisible
    ) {
      setIsRegularScrollbarVisible(false)
    } else if (
      containerScrollState.scrollTop +
        containerScrollState.offsetHeight +
        VERTICAL_PADDING >=
        containerScrollState.scrollHeight &&
      !isRegularScrollbarVisible
    ) {
      setIsRegularScrollbarVisible(true)
    }
  }, [containerScrollState, isRegularScrollbarVisible])

  return (
    <div
      className="fixed-scrollbar"
      ref={scrollbarRef}
      style={{
        display: 'block',
        visibility: isRegularScrollbarVisible ? 'hidden' : 'visible',
        overflowX: 'scroll',
        position: 'fixed',
        width: `${stickyScrollWidth.width}px`,
        bottom: 0,
      }}
      onScroll={handleOnScrollbarChange}
    >
      <div
        style={{
          width: `${stickyScrollWidth.scrollWidth}px`,
          height: '1px',
        }}
      ></div>
    </div>
  )
})
