import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DashboardLink } from '../../components/Dashboard/Dashboard'
import { clientApi } from '../../store/clientApi'
import { selectRtkData } from '../../store/slices/tableSlice'
import { RootState } from '../../store/store'
import { TIMELINE_TABLE_STATE_NAME } from './Timeline'
import { Timeline } from './Timeline'
import { TimelineData } from './TimelineData'

interface TimelineProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

export const TimelineContainer: FC<TimelineProps> = ({
  primaryLinks,
  footerLinks,
}) => {
  const { allowDevicesUi } = useFlags()
  const [tab, setTab] = useState<false | string>('all')
  const [featureFlagTimeout, setFeatureFlagTimeout] = useState<boolean>(false)

  // Feature Flag timeout as both error and loading are treated as undefined
  useEffect(() => {
    let timeoutId: any = null
    // Start timeout if allowDevicesUi is undefined
    if (!allowDevicesUi) {
      timeoutId = setTimeout(() => {
        setFeatureFlagTimeout(true)
      }, 3000) // 3 seconds
    }

    // Clear timeout on component unmount or if allowDevicesUi becomes defined
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [allowDevicesUi])

  const hasDevices = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return (query?.data?.content?.length ?? 0) > 0
  })

  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return query?.isLoading
  })

  const error = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )
    return query?.error
  })

  return (
    <>
      <TimelineData />
      <Timeline
        primaryLinks={primaryLinks}
        footerLinks={footerLinks}
        tab={tab}
        setTab={setTab}
        isLoading={isLoading}
        error={error}
        hasDevices={hasDevices}
        featureFlagTimeout={featureFlagTimeout}
        allowDevicesUi={allowDevicesUi}
      />
    </>
  )
}
