import { Box, Theme, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  handleCancelUpload,
  handleRetryUpload,
  UploadStatus,
} from '../../store/slices/partUploadsSlice'
import { RootState } from '../../store/store'
import { Button } from '../Button/Button'
import { ProgressBar } from '../ProgressBar/ProgressBar'

interface ProgressDrawerRowProps {
  item: number
}

const UploadProgress = ({ item }: { item: number }) => {
  const canDisplay = useSelector((state: RootState) => {
    const upload = state.uploads[item]
    return (
      upload.progress !== undefined &&
      upload.status !== UploadStatus.Complete &&
      !upload.cancelled
    )
  })
  const pending = useSelector(
    (state: RootState) => state.uploads[item].status === UploadStatus.Pending,
  )
  const progress = useSelector(
    (state: RootState) => state.uploads[item].progress,
  )
  const error = useSelector((state: RootState) => state.uploads[item].error)
  return canDisplay ? (
    <ProgressBar
      value={progress}
      variant={pending ? 'indeterminate' : 'determinate'}
      color={error ? 'error' : 'success'}
    />
  ) : (
    <>{null}</>
  )
}

export const ProgressDrawerRow: FC<ProgressDrawerRowProps> = ({ item }) => {
  const { t } = useTranslation('inbox')

  const name = useSelector((state: RootState) => state.uploads[item].name)
  const status = useSelector((state: RootState) => state.uploads[item].status)
  const cancelled = useSelector(
    (state: RootState) => state.uploads[item].cancelled,
  )
  const error = useSelector((state: RootState) => state.uploads[item].error)

  const actionButton = () => {
    if (status === UploadStatus.Complete || cancelled) {
      return <></>
    } else if (error === false) {
      return (
        <Button
          variant="text"
          size="small"
          className="actionButton"
          onClick={() => {
            handleCancelUpload(item)
          }}
        >
          {t('button.cancel')}
        </Button>
      )
    } else {
      return (
        <Button
          variant="text"
          size="small"
          className="actionButton"
          onClick={() => {
            handleRetryUpload(item)
          }}
        >
          {t('button.retry')}
        </Button>
      )
    }
  }

  return (
    <Box
      component="div"
      className="progressBottomDrawerRow"
      sx={{
        display: 'flex',
        width: '100%',
        padding: '12px',
        borderTop: (theme: Theme) => `1px solid ${theme.outline.main}`,
        borderBottom: (theme: Theme) => `1px solid ${theme.outline.main}`,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: (theme: Theme) => theme.surface.low,
        marginBottom: '-1px' /* prevent double border from previous item */,
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            maxWidth: '220px',
            maxLines: '1',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            ...(cancelled && { maxWidth: '100%' }),
          }}
          variant="body2"
        >
          {cancelled ? t('label.uploadCancelled', { partName: name }) : name}
        </Typography>
        {actionButton()}
      </Box>
      <UploadProgress item={item} />
    </Box>
  )
}
