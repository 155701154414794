import { CircularProgress, Typography, useTheme } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { partStatusToTranslatedKey } from '../../utils/partStatusToTranslatedKey'
import {
  CompleteSvg,
  ErrorOutlineSvg,
  OfflineReworkSvg,
  ToDoSvg,
  TodoSvg,
  UnknownSvg,
} from '../Icon/Icon'
import classes from './PartStatus.module.css'

interface PartStatusProps {
  status: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14
}

export const PartStatus: FC<PartStatusProps> = ({ status }) => {
  const { t } = useTranslation('inbox')
  const theme = useTheme()

  const statusIcons = {
    processing: <CircularProgress color="warning" size={14} />,
    pending: (
      <UnknownSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.warning.main}
      />
    ),
    printReady: (
      <TodoSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.success.main}
      />
    ),
    rejected: (
      <ErrorOutlineSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.error.main}
      />
    ),
    addedToJob: (
      <ToDoSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.success.main}
      />
    ),
    printed: (
      <CompleteSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.success.main}
      />
    ),
    reworkedOffline: (
      <OfflineReworkSvg
        width="1.3em"
        height="1.3em"
        color={theme.palette.primary.main}
      />
    ),
  }

  const getPartStatusIndicator = () => {
    switch (status) {
      case 0:
      case 1:
      case 2:
        return statusIcons.processing
      case 3:
      case 4:
        return statusIcons.pending
      case 5:
      case 7:
      case 8:
      case 9:
        return statusIcons.printReady
      case 10:
      case 11:
        return statusIcons.addedToJob
      case 12:
        return statusIcons.printed
      case 13:
      case 6:
        return statusIcons.rejected
      case 14:
        return statusIcons.reworkedOffline
      default:
        return statusIcons.pending
    }
  }

  return (
    <div className={classes.partStatusContainer}>
      <div className={classes.iconContainer}>{getPartStatusIndicator()}</div>
      <Typography>
        {t(
          /*
        t('processing')
        t('toConfirm')
        t('toAccept')
        t('printReady')
        t('rejected')
        t('addedToJob')
        t('offline')
        t('printed')
        */
          partStatusToTranslatedKey(status),
        )}
      </Typography>
    </div>
  )
}
