import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Namespace, TFunction } from 'i18next'
import { DeviceStatusRead } from './store/baseClientApi'

const createBooleanDataset = (map: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(map).map(([key, value]) => [
      `data-${key}`,
      value ? '' : undefined,
    ]),
  )
}

const isAbsoluteUrl = (uri: any) => {
  try {
    new URL(uri)
    return true
  } catch (error) {
    return false
  }
}

const formatMobile = (countryCode: string, number: string) => {
  const trimmedNumber = number.replace(/^0/, '')
  return trimmedNumber && `${countryCode}${trimmedNumber}`
}

class Countries {
  static raw: {
    name: string
    code: string
    bn?: {
      name: string
      field: string
    }
    flag: string
    areaCode: string
  }[] = [
    {
      name: 'Australia',
      code: 'AU',
      bn: {
        name: 'ABN',
        field: 'abn',
      },
      flag: '🇦🇺',
      areaCode: '61',
    },
    {
      name: 'India',
      code: 'IN',
      flag: '🇮🇳',
      areaCode: '91',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
      bn: {
        name: 'NZBN',
        field: 'business_registration_number',
      },
      flag: '🇳🇿',
      areaCode: '64',
    },
    {
      name: 'United Kingdom',
      code: 'UK',
      flag: '🇬🇧',
      areaCode: '44',
    },
    {
      name: 'United States',
      code: 'US',
      flag: '🇺🇸',
      areaCode: '1',
    },
    {
      name: 'Germany',
      code: 'DE',
      flag: '🇩🇪',
      areaCode: '49',
    },
  ]

  static findByCode(code: unknown) {
    return this.raw.find((item) => item.code === code)
  }
}

type LocalizedError = {
  error_key?: string
  status?: string
  details?: string
  default_message?: string
}

function ensureError(value: unknown): LocalizedError {
  if (typeof value !== 'object') {
    return {}
  }

  const response = Object.assign({}, value as { [id: string]: unknown })
  const data = Object.assign({}, response['data'] as { [id: string]: unknown })
  const error: LocalizedError = {}

  if (typeof response['status'] === 'number')
    error['status'] = response['status'].toString()

  if (typeof data['error_key'] === 'string')
    error['error_key'] = data['error_key']
  if (typeof data['details'] === 'string') error['details'] = data['details']
  if (typeof data['default_message'] === 'string')
    error['default_message'] = data['default_message']

  return error as LocalizedError
}

function localizeError<T extends Namespace, P>(
  t: TFunction<T, P>,
  error: LocalizedError,
) {
  const keys = []

  // error message priorities [from key, from status, (unspecified message | supplied default)]
  // when details are provided the context type will be set to details and details will be passed into i18n
  // if there is no translation with details context then it will fallback to no context

  let details = error.details

  if (error.error_key) keys.push(``)
  if (!error.default_message && error.status) keys.push(`error.${error.status}`)
  if (!error.default_message) keys.push(`error.unspecified`)
  else if (error.details)
    details = t(error.details, { defaultValue: error.details })

  /*
  Error Translations
  t('error.400')
  t('error.401')
  t('error.403')
  t('error.404')
  t('error.409')
  t('error.422')
  t('error.500')
  t('error.unexpected')
  t('error.notFound')
  t('error.forbidden')
  t('error.validation')
  t('error.badRequest')
  t('error.noAuthorized')
  t('error.conflict')
  t('error.value')
  t('error.activeWorkflowNotFound')
  t('error.addressNotFound')
  t('error.addressDeleteForbidden')
  t('error.addressCreateForbidden')
  t('error.addressUpdateForbidden')
  t('error.auth0BadRequest')
  t('error.auth0Forbidden')
  t('error.auth0Unauthorized')
  t('error.deviceForbidden')
  t('error.deviceNotFound')
  t('error.deviceStateConflict')
  t('error.jobNotFound')
  t('error.jobForbidden')
  t('error.jobNoSlicedBuildFile')
  t('error.notAllowedFieldFilter')
  t('error.notAllowedFieldSort')
  t('error.organisationNotFound')
  t('error.organisationUpdateForbidden')
  t('error.organisationViewForbidden')
  t('error.organisationViewUsersForbidden')
  t('error.partNotFound')
  t('error.partGetForbidden')
  t('error.partUpdateForbidden')
  t('error.partDeleteForbidden')
  t('error.partCreateForbidden')
  t('error.roleNotFound')
  t('error.sortField')
  t('error.sortOrder')
  t('error.unspecified')
  t('error.userBFPForbidden')
  t('error.userBlockForbidden')
  t('error.userConflict')
  t('error.userCreateForbidden')
  t('error.userDeleteForbidden')
  t('error.userGetBFPForbidden')
  t('error.userGetBlockForbidden')
  t('error.userGetForbidden')
  t('error.userNotFound')
  t('error.userNotInOrg')
  t('error.userResetForbidden')
  t('error.userSearchField')
  t('error.userSearchForbidden')
  t('error.userSearchPagination')
  t('error.userSearchSort')
  t('error.userRoleRemoveForbidden')
  t('error.userRoleAddForbidden')
  t('error.userUpdateForbidden')
  t('error.workflowNotFound')
  */
  return t(keys, {
    defaultValue: error.default_message,
    ...(error.details && { details, context: 'details' }),
  })
}

const ModifyUserManagementPermissions = [
  'create:members',
  'update:members',
  'delete:members',
]

const UserManagementPermissions = ['read:members']

const isSubSet = (arr: string[], target: string[]) =>
  target.every((v) => arr.includes(v))

const formatSeconds = (seconds: number) => {
  const days = Math.floor(seconds / (24 * 60 * 60))
  seconds -= days * 24 * 60 * 60
  const hours = Math.floor(seconds / (60 * 60))
  seconds -= hours * 60 * 60
  const minutes = Math.floor(seconds / 60)
  seconds -= minutes * 60
  seconds = Math.floor(seconds)
  return (
    (days > 0 ? days + ' day, ' : '') +
    hours +
    'h ' +
    minutes +
    'min ' +
    seconds +
    's'
  )
}

type Color = 'error' | 'info' | 'success' | 'warning' | 'primary' | 'secondary'

const deviceStateToColor = (device?: DeviceStatusRead): Color => {
  const state = device?.status?.state ?? ''
  const paused_by_user = device?.status?.paused_by_user ?? true

  if (!device?.status?.connected) return 'secondary'

  switch (state.toLocaleLowerCase()) {
    case 'idle':
      return 'info'
    case 'starting':
      return 'warning'
    case 'printing':
      return 'success'
    case 'cancelling':
      return paused_by_user ? 'warning' : 'error'
    case 'canceled':
      return paused_by_user ? 'warning' : 'error'
    case 'pausing':
      return paused_by_user ? 'warning' : 'error'
    case 'paused':
      return paused_by_user ? 'warning' : 'error'
    case 'finished':
      return 'success'
    case 'userbusy':
      return 'info'
    default:
      return 'error'
  }
}

const colorToIcon = (color: Color) => {
  // TODO update to new printer state icons
  switch (color) {
    case 'error':
      return <WarningAmberOutlinedIcon color={color} />
    case 'info':
      return <InfoOutlinedIcon color={color} />
    case 'success':
      return <CheckCircleOutlineOutlinedIcon color={color} />
    case 'warning':
      return <InfoOutlinedIcon color={color} />
    default:
      return <WarningAmberOutlinedIcon color={color} />
  }
}

const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    )

type Ordering = 'asc' | 'desc'
const perPageAmounts = [25, 5, 10, 50]

declare global {
  interface ObjectConstructor {
    entries<T extends object>(o: T): Array<[keyof T, T[keyof T]]>
  }
}

const retry = async (func: () => Promise<void>, times?: number) => {
  for (let i = 0; i < (times ?? 4); i++) {
    try {
      await func()
    } catch {
      continue
    }
    break
  }
}

export type { Color, Ordering }
export {
  perPageAmounts,
  snakeToCamel,
  Countries,
  createBooleanDataset,
  formatMobile,
  isAbsoluteUrl,
  ensureError,
  localizeError,
  ModifyUserManagementPermissions,
  UserManagementPermissions,
  isSubSet,
  deviceStateToColor,
  colorToIcon,
  formatSeconds,
  retry,
}
