import {
  Box,
  CircularProgress,
  LinearProgress,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EmptyState, StickyScrollbar } from '../../components'
import { Button } from '../../components/Button/Button'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { DeviceViewer } from '../../components/DeviceViewer/DeviceViewer'
import { RightDrawer } from '../../components/RightDrawer/RightDrawer'
import { UnderConstruction } from '../../components/UnderConstruction/UnderConstruction'
import { ensureError, localizeError } from '../../helpers'

import { selectDrawerOpen } from '../../store/slices/rightDrawerSlice'
import { RootState } from '../../store/store'
import { TimelineHeader } from './TimelineHeader/TimelineHeader'
import { TimelineHourMarker } from './TimelineHourMarker/TimelineHourMarker'
import { TimelinePrinters } from './TimelinePrinters/TimelinePrinters'
import { TimelineRows } from './TimelineRows/TimelineRows'

interface TimelineProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
  featureFlagTimeout: boolean
  tab: false | string
  setTab: Dispatch<SetStateAction<false | string>>
  hasDevices: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  isLoading: boolean | undefined
  allowDevicesUi: boolean | undefined
}

const enrolDeviceLink = `https://${import.meta.env.VITE_AUTH0_DOMAIN}/activate`
const PRINTER_PANEL_WIDTH = 300
const PRINTER_PANEL_PADDING = 16
const MAX_TIMELINE_HOURS = 92

const Timeline: FC<TimelineProps> = ({
  primaryLinks,
  footerLinks,
  featureFlagTimeout,
  tab,
  setTab,
  hasDevices,
  error,
  isLoading,
  allowDevicesUi,
}) => {
  // hooks
  const { t } = useTranslation('timeline')
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const stickyScrollbarRefs = useRef<{
    scrollViewportRef: React.RefObject<HTMLDivElement | null>
    timelineRef: React.RefObject<HTMLDivElement | null>
  }>(null)

  // states
  const [height, setHeight] = useState(0)
  const [timelineHours, setTimelineHours] = useState<number>(12)
  const [timelineWidth, setTimelineWidth] = useState(0)
  const [hoursViewWidth, setHoursViewWidth] = useState(0)
  const [timelineHeight, setTimelineHeight] = useState(0)

  const printersListRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        if (timelineHeight !== node.getBoundingClientRect().height) {
          setTimelineHeight(node.getBoundingClientRect().height)
        }
      }
    },
    [timelineHeight],
  )

  const drawerOpen = useSelector((state: RootState) =>
    selectDrawerOpen(state, TIMELINE_DRAWER_STATE_NAME),
  )

  // max queued print hours out of all printers
  const maxPrinterTimelineHours = useSelector(
    (state: RootState) => state.timelineDurationSlice.hours,
  )

  // calculate timeline start time (2hrs 20 before)
  const currentTime = new Date(Date.now()).setSeconds(0, 0) // update once per minute
  const flooredHourTime = new Date(currentTime - 1000 * 7200).setMinutes(0, 0, 0) // prettier-ignore
  const startTime = new Date(flooredHourTime - 1000 * 60 * 20) // Subtract 20 minutes (padding-left)

  const handleResize = () => {
    /** Check if the width of the timeline changes for hour sizing */
    if (stickyScrollbarRefs.current?.timelineRef.current) {
      setTimelineWidth(
        stickyScrollbarRefs.current?.timelineRef.current?.clientWidth,
      )
      setHoursViewWidth(
        stickyScrollbarRefs.current?.timelineRef.current?.clientWidth -
          PRINTER_PANEL_WIDTH -
          PRINTER_PANEL_PADDING * 3,
      )
    }
  }

  useEffect(() => {
    handleResize()
  }, [
    stickyScrollbarRefs.current?.scrollViewportRef,
    stickyScrollbarRefs.current?.timelineRef,
    drawerOpen,
    allowDevicesUi,
  ])

  useEffect(() => {
    handleResize() // Set initial width
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Timeline page width excluding left navbar
  const TimelineMaxWidths = (theme: Theme) => ({
    [theme.breakpoints.up('xs')]: {
      maxWidth: 'calc(100vw)',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(100vw - 80px)',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100vw - 100px)',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'calc(100vw - 120px)',
    },
  })

  return (
    <Dashboard primaryLinks={primaryLinks} footerLinks={footerLinks}>
      {allowDevicesUi ? (
        <Box
          component="div"
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
            maxWidth: '100%',
            overflow: 'hidden',
            gap: `${PRINTER_PANEL_PADDING}px`,
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'stretch',
              justifyContent: 'flex-start',
              width: '100%',
              height: '100%',
              maxHeight: '100%',
              backgroundColor: theme.surface.low,
              borderRadius: '1em',
              boxSizing: 'border-box',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'max-content',
                width: '100%',
                bgcolor: (theme: Theme) => theme.surface.low,
                padding: '1em 1em 0em 1em',
                boxSizing: 'border-box',
              }}
            >
              <TimelineHeader
                tab={tab}
                setTab={setTab}
                timelineHours={timelineHours}
                setTimelineHours={setTimelineHours}
              />
            </Box>
            {isLoading ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  padding: '1em',
                  borderRadius: '1em',
                  overflow: 'auto',
                }}
              >
                <LinearProgress />
              </Box>
            ) : error ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  padding: '1em',
                  borderRadius: '1em',
                  overflow: 'auto',
                }}
              >
                {localizeError(t, ensureError(error))}
              </Box>
            ) : hasDevices ? (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexGrow: '1',
                  width: '100%',
                  ...TimelineMaxWidths(theme),
                  height: '100%',
                  minHeight: 'max-content',
                  padding: `${PRINTER_PANEL_PADDING + 2}px ${PRINTER_PANEL_PADDING}px 0px ${PRINTER_PANEL_PADDING}px`,
                  gap: `${PRINTER_PANEL_PADDING}px`,
                  boxSizing: 'border-box',
                  overflowY: 'auto',
                }}
                id={'sticky-scroll-container'}
                ref={stickyScrollbarRefs?.current?.timelineRef}
              >
                <Box
                  component="div"
                  ref={printersListRef}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    gap: '0.5em',
                    width: `${PRINTER_PANEL_WIDTH}px`,
                    flexShrink: '0',
                    boxSizing: 'border-box',
                    height: 'max-content',
                    minHeight: '100%',
                    paddingTop: `${PRINTER_PANEL_PADDING}px`,
                  }}
                >
                  <TimelinePrinters tab={tab} />
                </Box>
                {/* Timeline */}
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    flexShrink: '1',
                    width: '100%',
                    height: `calc(${timelineHeight}px + 1.5em)`,
                    position: 'relative',
                    boxSizing: 'border-box',
                  }}
                  ref={(ref: HTMLDivElement) => {
                    if (ref) {
                      setHeight(ref.clientHeight ?? 0)
                      window.addEventListener('resize', () => {
                        if (ref.clientHeight !== height) {
                          setHeight(ref.clientHeight ?? 0)
                        }
                      })
                    }
                  }}
                >
                  {/* Hour Markers */}
                  <Box
                    component="div"
                    id={'scroll-viewport'}
                    ref={stickyScrollbarRefs?.current?.scrollViewportRef}
                    sx={{
                      display: 'flex',
                      flexGrow: '1',
                      height: `100%`,
                      minHeight: `100%`,
                      alignItems: 'stretch',
                      overflowX: 'auto',
                      overflowY: 'hidden',
                      flexShrink: '1',
                      width: `${hoursViewWidth}px`,
                      maxWidth: `${hoursViewWidth}px`,
                      position: 'relative',
                      paddingTop: '1.5em',
                      marginTop: '-1.5em',
                    }}
                  >
                    {/* 20 minutes worth of additional left padding */}
                    <Box
                      component="div"
                      sx={{
                        height: '100%',
                        minWidth:
                          timelineWidth > 0
                            ? `${hoursViewWidth / timelineHours / 3}px`
                            : '1px',
                      }}
                    />
                    {Array.from(
                      {
                        length: Math.min(
                          Math.max(
                            maxPrinterTimelineHours <= 0
                              ? timelineHours
                              : maxPrinterTimelineHours,
                            timelineHours,
                          ),
                          MAX_TIMELINE_HOURS,
                        ),
                      },
                      (_, i) => (
                        <TimelineHourMarker
                          key={i}
                          index={i}
                          startTime={startTime}
                          timelineWidth={timelineWidth}
                          timelineHours={timelineHours}
                          hoursViewWidth={hoursViewWidth}
                        />
                      ),
                    )}
                    {/* Timeline Content */}
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '0.5em',
                        paddingTop: `${PRINTER_PANEL_PADDING}px`,
                      }}
                    >
                      {/* Full width row for each printer */}
                      {hasDevices && (
                        <TimelineRows
                          tab={tab}
                          hoursViewWidth={hoursViewWidth}
                          timelineHours={timelineHours}
                        />
                      )}
                    </Box>

                    {/* Time Cursor */}
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        left: `${((hoursViewWidth / timelineHours) * (currentTime - startTime.valueOf())) / 60 / 60 / 1000}px`,
                        overflow: 'visible',
                        width: '4px',
                        height: '100%',
                        backgroundColor: '#000000',
                        border: '1px solid white',
                        boxSizing: 'border-box',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          width: '12px',
                          height: '12px',
                          borderRadius: '6px',
                          backgroundColor: '#000000',
                          border: '1px solid white',
                          position: 'absolute',
                          top: '-6px',
                          left: `-5px`,
                        }}
                      />
                    </Box>
                  </Box>
                  <StickyScrollbar
                    ref={stickyScrollbarRefs}
                    viewHours={timelineHours}
                  />
                </Box>
              </Box>
            ) : tab === 'attention' ? (
              <EmptyState
                image={'/images/no-attention-required.svg'}
                title={t('devices:message.allGood')}
                message={t('devices:message.noDevicesNeedAttention')}
              />
            ) : (
              <EmptyState
                image={'/images/no-devices.svg'}
                title={t('devices:message.devicesPageInfo')}
                message={t('devices:message.addDevices')}
                button={
                  <Button
                    color="primary"
                    href={enrolDeviceLink}
                    target="_blank"
                    sx={{
                      mt: '1.5em',
                    }}
                  >
                    {t('button.add')}
                  </Button>
                }
              />
            )}
          </Box>
          <RightDrawer
            stateName={TIMELINE_DRAWER_STATE_NAME}
            width={mdDown ? '100%' : '40vw'}
            shift
            sx={{
              [theme.breakpoints.up('md')]: {
                maxWidth: '460px',
              },
              height: `${height}px`,
              flexGrow: '1',
              flexShrink: '0',
              display: 'flex',
            }}
            padding="0px"
          >
            <DeviceViewer
              detailsStateName={TIMELINE_DRAWER_STATE_NAME}
              tableStateName={TIMELINE_TABLE_STATE_NAME}
            />
          </RightDrawer>
        </Box>
      ) : featureFlagTimeout || allowDevicesUi === false ? (
        <UnderConstruction />
      ) : (
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </Dashboard>
  )
}

export { Timeline }
export const TIMELINE_DRAWER_STATE_NAME = 'timelineDetailsState'
export const TIMELINE_TABLE_STATE_NAME = 'timelineTableState'
